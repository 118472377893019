import * as actionTypes from "./BrandsTypes";

const INTIAL_STATE = {
  data: null,
  isLoading: false,
  addData: null,
  isAddLoading: false,
  activebrandLoading: false,
  brandactiveData: null,
  brandByAdminData: [],
  bradbyadminLoading: false
};
const BrandsReducer = (state = INTIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.IS_LOADING:
      return {
        ...state,
        isLoading: true
      };

    //////////////////////////////////   //  Reducer
    case actionTypes.BRAND_DATA_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false
      };
    case actionTypes.BRAND_DATA_FALED:
      return {
        ...state,
        isLoading: false
      };


    //////////////////////////////////  // Add BRand
    case actionTypes.IS_ADD_LOADING:
      return {
        ...state,
        isAddLoading: true
      };

    case actionTypes.BRAND_ADD_DATA_SUCCESS:
      return {
        ...state,
        addData: payload,
        isAddLoading: false
      };
    case actionTypes.BRAND_ADD_DATA_FALED:
      return {
        ...state,
        isAddLoading: false
      };

    //////////////////////////////////    // BRand active
    case actionTypes.IS_BRAND_ACTIVE_LOADING:
      return {
        ...state,
        activebrandLoading: true
      };

    case actionTypes.BRAND_ACTIVE_DATA_SUCCESS:
      return {
        ...state,
        brandactiveData: payload,
        activebrandLoading: false
      };
    case actionTypes.BRAND_ACTIVE_DATA_FALED:
      return {
        ...state,
        activebrandLoading: false
      };
    //////////////////////////////////
    case actionTypes.IS_BRAND_BY_ADMINID_LOADING:
      return {
        ...state,
        bradbyadminLoading: true
      };

    // Login Reducer
    case actionTypes.BRAND_BY_ADMINID_DATA_SUCCESS:
      return {
        ...state,
        brandByAdminData: payload,
        bradbyadminLoading: false
      };
    case actionTypes.BRAND_BY_ADMINID_DATA_FALED:
      return {
        ...state,
        bradbyadminLoading: false
      };

    default:
      return state;
  }
};

export default BrandsReducer;
