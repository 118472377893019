export const ISLOADING_PROFILE = "ISLOADING_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";

export const ISLOADING_SOUND = "ISLOADING_SOUND";
export const UPDATE_SOUND_SUCCESS = "UPDATE_SOUND_SUCCESS";
export const UPDATE_SOUND_FAILED = "UPDATE_SOUND_FAILED";

export const IS_LOADING_CHANGE_PASSWORD = "IS_LOADING_CHANGE_PASSWORD";
export const IS_LOADING_CHANGE_PASSWORD_SUCCESS = "IS_LOADING_CHANGE_PASSWORD_SUCCESS";
export const IS_LOADING_CHANGE_PASSWORD_FAILED = "IS_LOADING_CHANGE_PASSWORD_FAILED";
