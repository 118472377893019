import React, { useState, useEffect } from "react";
import { Divider, Radio, Table, theme, Button } from "antd";

const Monitor = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Agent",
      dataIndex: "agent",
    },
    {
      title: "Time",
      dataIndex: "time",
    },
    {
      title: "Rating",
      dataIndex: "rating",
    },
    {
      title: "Messages",
      dataIndex: "messages",
    },
  ];
  const data = [
    {
      key: "1",
      name: "John Brown",
      agent: "John Doe",
      time: "Apr 13 12:10 AM",
      rating: 2,
      messages: "New York No. 1 Lake Park",
    },
    {
      key: "2",
      name: "John Brown",
      agent: "John Doe",
      time: "Apr 13 12:10 AM",
      rating: 2,
      messages: "New York No. 1 Lake Park",
    },

    {
      key: "3",
      name: "John Brown",
      agent: "John Doe",
      time: "Apr 13 12:10 AM",
      rating: 2,
      messages: "New York No. 1 Lake Park",
    },
  ];
  const [loading, setLoading] = useState(false);

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {

    },
  };
  const [selectionType, setSelectionType] = useState("checkbox");
  return (
    <>
      <div className="topBtns">
        <Button type="primary" loading={loading}>
          Add Brand
        </Button>
      </div>
      <div>
        <Table
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data}
          scroll={{
            y: 120,
          }}
        />
      </div>
    </>
  );
};

export default Monitor;
