import React from 'react';
import { PolarArea } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const PolarChart = ({ AnalyticsData }) => {
    const dataValues = AnalyticsData?.totalReferrers?.totalReferrers?.length > 0
        ? AnalyticsData.totalReferrers.totalReferrers.map(item => item.count)
        : [0];

    const labels = AnalyticsData?.totalReferrers?.totalReferrers?.map(item => item._id) || ['There is no Data Currently'];

    const generateRandomColor = () => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return `rgba(${r}, ${g}, ${b}, 1)`;
    };

    const backgroundColors = dataValues.map(() => generateRandomColor());
    const borderColors = backgroundColors.map(color => color.replace(/[^,]+(?=\))/, '1'));

    const data = {
        datasets: [
            {
                label: 'Total Chats',
                data: dataValues,
                backgroundColor: backgroundColors,
                borderColor: borderColors,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            r: {
                beginAtZero: true,
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const label = labels[context.dataIndex] || '';
                        const value = context.raw || 0;
                        return `${label}: Count: ${value}`;
                    }
                }
            }
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 410,
                width: '100%',
            }}
        >
            <div style={{ width: '80%', maxWidth: 800, height: '100%' }}>
                <PolarArea data={data} options={options} />
            </div>
        </div>
    );
};

export default PolarChart;
