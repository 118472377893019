import React, { useState, useEffect } from 'react';
import {
    Slider
} from "antd";
import {
    PlayCircleOutlined,
    PauseCircleOutlined,
    AudioMutedOutlined,
    AudioOutlined
} from "@ant-design/icons";

const SoundComponent = ({ soundUrl, setMuted, setVolume, muted, volume }) => {
    const [audio, setaudio] = useState(new Audio(soundUrl));
    const [isPlaying, setIsPlaying] = useState(false);
    useEffect(() => {
        setaudio(new Audio(soundUrl))
    }, [soundUrl]);

    useEffect(() => {
        audio.volume = volume / 100;
        audio.muted = muted;
        audio.load();
    }, [audio, volume, muted]);



    const playSound = () => {
        if (isPlaying) {
            audio.pause();
        } else {
            audio.play();
        }
        setIsPlaying(!isPlaying);
    };
    useEffect(() => {
        audio.addEventListener('ended', () => setIsPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setIsPlaying(false));
        };
    }, [isPlaying]);

    const handleVolumeChange = (value) => {
        setVolume(value);
    };

    const toggleMute = () => {
        setMuted(!muted);
    };

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "space-between", marginTop: 10 }}>
            {isPlaying ? <PauseCircleOutlined onClick={playSound} style={{ fontSize: 18 }} /> : <PlayCircleOutlined onClick={playSound} style={{ fontSize: 18 }} />}
            <Slider style={{ width: "85%" }} value={volume} onChange={handleVolumeChange} defaultValue={30} />
            {muted ? <AudioMutedOutlined onClick={toggleMute} style={{ fontSize: 16 }} /> : <AudioOutlined onClick={toggleMute} style={{ fontSize: 16 }} />}
        </div>

    );
};

export default SoundComponent;