import React, { useState, useEffect } from "react";
import {
  Divider,
  Radio,
  Table,
  theme,
  Button,
  Space,
  Dropdown,
  Tabs,
} from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import { useDispatch, useSelector } from "react-redux";
import { getTickets, updateTickets } from "../../redux/Tickets/TicketsActions";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";

const Tickets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ticketsData = useSelector((state) => state.TicketsReducer.data);
  const userData = useSelector((state) => state.Auth.userData);
  const usertoken = useSelector((state) => state.Auth.token);
  const [isShow, setisShow] = useState(false);
  const [selectedData, setselectedData] = useState(null);

  let filteredData = ticketsData?.map((j) => j?._id);

  //   useEffect(() => {
  //     let data = {
  //       ticketsIds: filteredData,
  //       userId: userData?._id,
  //     };
  //     dispatch(updateTickets(data, usertoken, afterSuccess));
  //   }, []);

  const afterSuccess = () => {
    // setTimeout(() => {
    let data = {
      Id: userData?._id,
    };
    dispatch(getTickets(data, usertoken));
    // }, 10000);
  };

  const rowClassName = (record) => {
    return record?.isRead === false ? "bold-row" : "";
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const columns = [
    {
      title: "Visitor",
      dataIndex: "unique_id",
      width: 150,
      render: (text) => <>{text}</>,
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 200,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 200,
    },
    {
      title: "Message",
      dataIndex: "messages",
      render: (item, data) => {
        return (
          <div className="badge_messages">
            <span>{data?.messages?.length}</span>
            <p>{data?.messages[data?.messages?.length - 1]?.message}</p>
          </div>
        );
      },
    },
  ];

  const onSearch = (value) => {};

  const handleCloseSidebar = () => {
    setisShow(false);
  };

  const handleRowClick = (record) => {
    setselectedData(record);
    upDateIsRead(record?._id, record?.isRead);
    setisShow(true);
  };

  const upDateIsRead = (id, isRead) => {
    if (!isRead) {
      let data = {
        ticketsIds: [id],
        userId: userData?._id,
      };
      dispatch(updateTickets(data, usertoken, afterSuccess));
    }
  };

  return (
    <div className="tickets_page_main">
      <div className="topBtns">
        <div className="shortcut">
          <Space direction="vertical">
            <Search
              placeholder="Search"
              onSearch={onSearch}
              style={{
                width: 200,
              }}
            />
          </Space>
        </div>
        <Button type="primary" onClick={() => navigate("/create-ticket")}>
          Create Ticket
        </Button>
      </div>
      <div>
        <Table
          columns={columns}
          rowClassName={rowClassName}
          dataSource={ticketsData?.map((item, index) => ({
            ...item,
            key: index,
          }))}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => handleRowClick(record),
            };
          }}
        />
      </div>
      <div className={`sidebarnew ${isShow ? "open" : "closed"}`}>
        <div className="sidebarnew_header">
          <h5>Tickets</h5>
          <a className="crossBtn" onClick={handleCloseSidebar}>
            <CloseOutlined />
          </a>
        </div>
        <div className="currentHistory">
          <div className="head">
            #[{selectedData?.unique_id}]
            <div className="headerEmail">
              <p className="heading">Email:</p>
              <p className="email">{selectedData?.email}</p>
            </div>
            <div className="headerEmail">
              <p className="heading">Name:</p>
              <p className="email">{selectedData?.name}</p>
            </div>
          </div>
          {selectedData?.messages?.length > 0 &&
            selectedData?.messages?.map((data, index) => {
              return (
                <div className="message_main" key={index}>
                  <div className="top">#[{selectedData?.unique_id}]</div>
                  <div className="bottom">
                    <p>{data?.message}</p>
                    <span>
                      {moment(data?.time).format("hh:mm A  DD/MM/YYYY")}
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Tickets;
