export const IS_LOADING = "IS_LOADING";
export const ADD_STATUS_SUCCESS = "ADD_STATUS_SUCCESS";
export const ADD_STATUS_FALED = "ADD_STATUS_FALED";

export const GET_STATUS_SUCCESS = "GET_STATUS_SUCCESS";
export const GET_STATUS_FALED = "GET_STATUS_FALED";


export const EDIT_STATUS_SUCCESS = "EDIT_STATUS_SUCCESS";
export const EDIT_STATUS_FALED = "EDIT_STATUS_FALED";

