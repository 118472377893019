import React from 'react'

export default function Typingdots() {
    return (
        <div className="typing">
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}
