import * as actionTypes from "./AllHistoryTypes";

const INTIAL_STATE = {
    dataH: [],
    isLoadingH: false,
    overAllData: [],
    isLoadingOverAll: false,


};
const ALLHistoryReducer = (state = INTIAL_STATE, action) => {
    const { payload } = action;
    switch (action.type) {
        case actionTypes.IS_LOADING_ALL_HISTORY:
            return {
                ...state,
                isLoadingH: true
            };
        case actionTypes.ALL_HISTORY_DATA_SUCCESS:
            return {
                ...state,
                dataH: payload,
                isLoadingH: false
            };
        case actionTypes.ALL_HISTORY_DATA_FAILED:
            return {
                ...state,
                isLoadingH: false
            };

        case actionTypes.IS_LOADING_OVERALL:
            return {
                ...state,
                isLoadingOverAll: true
            };
        case actionTypes.ALL_VISITOR_OVERALL_DATA_SUCCESS:
            return {
                ...state,
                overAllData: payload,
                isLoadingOverAll: false
            };
        case actionTypes.ALL_VISITOR_OVERALL_DATA_FAILED:
            return {
                ...state,
                isLoadingOverAll: false
            };
        default:
            return state;
    }
};

export default ALLHistoryReducer;
