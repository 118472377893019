import * as actionTypes from "./OfflineStatusTypes";

const INTIAL_STATE = {
  data: null,
  isLoading: false,
  allStatusData: [],
  editStatusData: []
};
const OfflineStatusReducer = (state = INTIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.IS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.ADD_STATUS_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false
      };
    case actionTypes.ADD_STATUS_FALED:
      return {
        ...state,
        isLoading: false
      };
    ///////////////////////////////
    case actionTypes.GET_STATUS_SUCCESS:
      return {
        ...state,
        allStatusData: payload,
        isLoading: false
      };
    case actionTypes.GET_STATUS_FALED:
      return {
        ...state,
        isLoading: false
      };

    ///////////////////////////////
    case actionTypes.EDIT_STATUS_SUCCESS:
      return {
        ...state,
        editStatusData: payload,
        isLoading: false
      };
    case actionTypes.EDIT_STATUS_FALED:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
};

export default OfflineStatusReducer;
