export const IS_LOADING = "IS_LOADING";
export const GET_TRIGGERS_CONDITIONS_SUCCESS = "GET_TRIGGERS_CONDITIONS_SUCCESS";
export const GET_TRIGGERS_CONDITIONS_FAILED = "GET_TRIGGERS_CONDITIONS_FAILED";
export const GET_TRIGGERS_ACTIONS_SUCCESS = "GET_TRIGGERS_ACTIONS_SUCCESS";
export const GET_TRIGGERS_ACTIONS_FAILED = "GET_TRIGGERS_ACTIONS_FAILED";
export const ADD_TRIGGERS_SUCCESS = "ADD_TRIGGERS_SUCCESS";
export const GET_TRIGGERS_SUCCESS = "GET_TRIGGERS_SUCCESS";
export const DELETE_TRIGGER_SUCCESS = "DELETE_TRIGGER_SUCCESS";
export const ADD_TRIGGERS_FAILED = "ADD_TRIGGERS_FAILED";
export const GET_TRIGGERS_FAILED = "GET_TRIGGERS_FAILED";
export const DELETE_TRIGGER_FAILED = "DELETE_TRIGGER_FAILED";
export const GET_TRIGGER_BY_ID_SUCCESS = "GET_TRIGGER_BY_ID_SUCCESS";
export const GET_TRIGGER_BY_ID_FAILED = "GET_TRIGGER_BY_ID_FAILED";
export const UPDATE_TRIGGERS_SUCCESS = "UPDATE_TRIGGERS_SUCCESS";
export const UPDATE_TRIGGERS_FAILED = "UPDATE_TRIGGERS_FAILED";