import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Select, Space, Input, Form, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Get_Brands_By_Id } from "../../redux/Brands/BrandsActions";
import { Add_Users } from "../../redux/Agents/AgentsActions";
import { getAllUser, getUserByAdminId } from "../../redux/Users/UsersActions";
import UserEditModal from "./UserEditModal";
import { getActiveDepartments } from "../../redux/Departments/DepartmentsActions";
import { useLocation } from "react-router-dom";

const Users = () => {
  const userData = useSelector((state) => state.Auth.userData);
  const allData = useSelector((state) => state.Brands.data);
  const allUsers = useSelector((state) => state.Users.allUsers);
  const usertoken = useSelector((state) => state.Auth.token);
  const departments = useSelector((state) => state.Departments.activeDepartment);
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editedUser, setEditedUser] = useState({});
  const [Error, setError] = useState("");

  const columns = [
    {
      title: "Sr #",
      dataIndex: "serial"
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Department",
      dataIndex: "department",
    },
    {
      title: "Role",
      dataIndex: "role",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        userData?.role === "superadmin" ?
          <Space size="middle">
            <a onClick={() => openEditModalHandler(record.id)}>Edit</a>
            | <a>Delete</a>
          </Space>
          :
          <Space size="middle">
            <a onClick={() => openEditModalHandler(record.id)}>Edit</a>
          </Space>
      ),
    },
  ];

  useEffect(() => {
    if (userData?.role === "superadmin") {
      const data = {
        id: userData?._id
      };
      dispatch(getAllUser(data, usertoken));
    } else {
      const data = {
        id: userData?._id
      };
      dispatch(getUserByAdminId(data, usertoken));
    }
    if (location?.state?.user_id) {
      openEditModalHandler(location?.state?.user_id);
    }
  }, []);

  useEffect(() => {
    if (location?.state?.user_id) {
      openEditModalHandler(location?.state?.user_id);
    }
  }, [location?.state?.user_id]);

  useEffect(() => {
    const data = {
      companyId: userData?.companyId,
    };
    dispatch(Get_Brands_By_Id(data, usertoken));
    dispatch(getActiveDepartments(data, usertoken));
  }, [open]);

  const allBrands = allData?.map((i) => {
    return {
      label: i?.brandname,
      value: i?._id,
    };
  });

  const allDepartments = departments?.map((i) => {
    return {
      label: i?.name,
      value: i?._id,
    };
  });

  const showModal = () => {
    setOpen(true);
  };

  const afterSuccess = () => {
    form.resetFields();
    setOpen(false);
    if (userData?.role === "superadmin") {
      const data = {
        id: userData?._id
      };
      dispatch(getAllUser(data, usertoken));
    } else {
      const data = {
        id: userData?._id
      };
      dispatch(getUserByAdminId(data, usertoken));
    }
  };

  const handleOk = (values) => {
    setLoading(true);
    setError("");
    const data = {
      name: values.name,
      role: values.Role,
      sudoName: values.sudo,
      email: values.email,
      password: values.password,
      addedby: userData?._id,
      assignBrands: values.Brand,
      department: values.Department,
      companyId: userData?.companyId
    };
    dispatch(Add_Users(data, usertoken, afterSuccess, setLoading, setError));
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const onChange = (pagination, filters, sorter, extra) => {
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [loading, setLoading] = useState(false);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const options = [
    {
      label: "sub admin",
      value: "subadmin",
    },
    {
      label: "Agent",
      value: "agent",
    },
  ];

  const options2 = [
    {
      label: "Admin",
      value: "admin",
    },
    {
      label: "Sub Admin",
      value: "subadmin",
    },
    {
      label: "Agent",
      value: "agent",
    },
  ];

  const data = [];
  for (let i = 0; i < allUsers?.length; i++) {
    data.push({
      key: i,
      id: allUsers[i]._id,
      serial: i + 1,
      name: allUsers[i]?.name,
      email: allUsers[i]?.email,
      role: allUsers[i]?.role,
      department: allUsers[i]?.depart?.name
    });
  }

  const openEditModalHandler = (id) => {
    const user = allUsers.find(i => i._id === id);
    setEditedUser(user);
    setOpenEditModal(true);
  }

  const closeEditModalHandler = (val) => {
    setEditedUser({});
    setOpenEditModal(val);
  }

  return (
    <>
      <UserEditModal user={editedUser} allBrands={allBrands} allDepartments={allDepartments} options={options} options2={options2} role={userData?.role} open={openEditModal} close={closeEditModalHandler} />
      <Modal
        open={open}
        // closable={true}
        title="Add User"
        footer={null}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          name="control-hooks"
          onFinish={handleOk}
          style={{
            maxWidth: 600,
          }}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter name!",
              },
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            name="sudo"
            rules={[
              {
                required: true,
                message: "Please enter sudo name!",
              },
            ]}
          >
            <Input placeholder="Sudo Name" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter email!",
              },
              {
                type: 'email',
                message: 'This email is not valid!',
              }
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter password!",
              },
            ]}
          >
            <Input type="password" placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="Role"
            rules={[
              {
                required: true,
                message: "Please select role!",
              },
            ]}
          >
            <Select
              placeholder="Select Role"
              style={{
                width: "100%",
              }}
              options={userData?.role === "superadmin" ? options2 : options}
            />
          </Form.Item>
          <Form.Item
            name="Brand"
            rules={userData?.role !== "superadmin" && [
              {
                required: true,
                message: "Please select brand(s)!",
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Select Brand(s)"
              options={allBrands}
            />
          </Form.Item>
          <Form.Item
            name="Department"
            rules={userData?.role !== "superadmin" && [
              {
                required: true,
                message: "Please select department!",
              },
            ]}
          >
            <Select
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Select Department"
              options={allDepartments}
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              style={{
                float: "right",
              }}
            >
              Submit
            </Button>

          </Form.Item>
          <p style={{ textAlign: "center", color: "red" }}>{Error}</p>
        </Form>
      </Modal>
      <div className="topbtn">
        {userData?.role != "agent" && <Button type="primary" onClick={showModal} loading={loading}>
          Add User
        </Button>}
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        onChange={onChange}
      />
    </>
  );
};

export default Users;
