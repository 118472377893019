export const IS_LOADING = "IS_LOADING";
export const PACKAGE_DATA_SUCCESS = "PACKAGE_DATA_SUCCESS";
export const PACKAGE_DATA_FALED = "PACKAGE_DATA_FALED";

export const IS_PACKAGE_CREATE_LOADING = "IS_PACKAGE_CREATE_LOADING";
export const PACKAGE_CREATE_SUCCESS = "PACKAGE_CREATE_SUCCESS";
export const PACKAGE_CREATE_FALED = "PACKAGE_CREATE_FALED";

export const IS_PACKAGE_BY_ID_LOADING = "IS_PACKAGE_BY_ID_LOADING";
export const PACKAGE_BY_ID_SUCCESS = "PACKAGE_BY_ID_SUCCESS";
export const PACKAGE_BY_ID_FALED = "PACKAGE_BY_ID_FALED";

export const IS_PACKAGE_UPDATE_LOADING = "IS_PACKAGE_UPDATE_LOADING";
export const PACKAGE_UPDATE_SUCCESS = "PACKAGE_UPDATE_SUCCESS";
export const PACKAGE_UPDATE_FALED = "PACKAGE_UPDATE_FALED";

export const PLAN_LOADING = "PLAN_LOADING";
export const PLAN_SUCCESS = "PLAN_SUCCESS";
export const PLAN_FALED = "PLAN_FALED";

export const UPDATE_PLAN_LOADING = "UPDATE_PLAN_LOADING";
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const UPDATE_PLAN_FALED = "UPDATE_PLAN_FALED";

export const CREATE_PLAN_LOADING = "CREATE_PLAN_LOADING";
export const CREATE_PLAN_SUCCESS = "CREATE_PLAN_SUCCESS";
export const CREATE_PLAN_FALED = "CREATE_PLAN_FALED";