import React from "react";
import { Form, Input, Button, Checkbox, Col, Row, Spin, message } from "antd";
import { UserOutlined, LoadingOutlined, ToolOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { ForgetPassword_Action, Login_Action } from "../../../redux/Auth/AuthActions";
import loginBack from "../../../assets/image/login-back.png";
import getStart from "../../../assets/image/getStart.png";
import logo from "../../../assets/image/main-logo.png";
import { useNavigate } from "react-router-dom";

const ForgetPassword = () => {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const isLoading = useSelector((state) => state.Auth.isLoadingPassword);

    const onFinish = (values) => {
        let data = {
            email: values?.email,
        };
        dispatch(ForgetPassword_Action(data, error, success));
    };

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 20,
                color: "#ffffff",
                margin: "0px 25px",
            }}
            spin
        />
    );

    const error = (err) => {
        messageApi.open({
            type: "error",
            content: err,
        });
    };

    const success = (err) => {
        messageApi.open({
            type: "success",
            content: err,
        });
    };
    return (
        <>
            {contextHolder}
            <Row
                type="flex"
                justify="center"
                align="middle"
                className="loginForm"
                style={{ minHeight: "100vh", backgroundImage: `url(${loginBack})` }}
            >
                <Col span={4} className="sideBlue">
                    <img src={getStart} />
                </Col>
                <Col span={6} className="formSide">
                    <img src={logo} />
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                        autoComplete="on"
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your email!",
                                },
                            ]}
                            label={
                                <span>
                                    <UserOutlined /> Email Address
                                </span>
                            }
                            className="loginLabel"
                        >
                            <Input placeholder="Email" />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                disabled={isLoading}
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                            >
                                {isLoading ? <Spin indicator={antIcon} /> : "Forget Password"}
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <a onClick={() => navigate("/login")} className="login-form-forgot" >
                                Sign In
                            </a>
                        </Form.Item>
                    </Form>
                    <p className="terms">
                        By clicking Log In. I confirm that I have read and agree to the
                        Userthemes <span> Terms of Services Privacy Policy </span> and to
                        receive email and updates.
                    </p>
                </Col>
            </Row>
        </>
    );
}

export default ForgetPassword;
