import * as actionTypes from "./HistoryTypes";

const INTIAL_STATE = {
    data: [],
    isLoadingH: false,
    isDLoading: false,
    detailsData: [],
    ismsgLoading: false,
    msgsData: []
};
const HistoryReducer = (state = INTIAL_STATE, action) => {
    const { payload } = action;
    switch (action.type) {
        case actionTypes.IS_LOADING:
            return {
                ...state,
                isLoadingH: true
            };
        case actionTypes.VISITOR_DETAILS_DATA_SUCCESS:
            return {
                ...state,
                data: payload,
                isLoadingH: false
            };
        case actionTypes.VISITOR_DETAILS_DATA_FAILED:
            return {
                ...state,
                isLoadingH: false
            };
        /////////////////////////////////////////////////////////////
        case actionTypes.IS_LOADING_DETAILS:
            return {
                ...state,
                isDLoading: true
            };
        case actionTypes.ALL_VISITOR_DETAILS_DATA_SUCCESS:
            return {
                ...state,
                detailsData: payload,
                isDLoading: false
            };
        case actionTypes.ALL_VISITOR_DETAILS_DATA_FAILED:
            return {
                ...state,
                isDLoading: false
            };
        /////////////////////////////////////////////////////////////
        case actionTypes.IS_LOADING_MESSAGES:
            return {
                ...state,
                ismsgLoading: true
            };
        case actionTypes.ALL_VISITOR_MESSAGES_DATA_SUCCESS:
            return {
                ...state,
                msgsData: payload,
                ismsgLoading: false
            };
        case actionTypes.ALL_VISITOR_MESSAGES_DATA_FAILED:
            return {
                ...state,
                ismsgLoading: false
            };
        default:
            return state;
    }
};

export default HistoryReducer;
