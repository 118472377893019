import { Breadcrumb, Button, Checkbox, Col, Form, Input, Row, message, InputNumber } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getPackageById, updatePackage } from '../../redux/Packages/PackagesActions';
import { useLocation } from 'react-router-dom';

const EditPackage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const usertoken = useSelector((state) => state.Auth.token);
    const [form] = Form.useForm();
    const [checked, setChecked] = useState(false);
    const [isActive, setisActive] = useState(false);
    useEffect(() => {
        setChecked(location?.state?.data?.status);
        setisActive(location?.state?.data?.isActive);
        form.setFieldsValue({
            packageTitle: location?.state?.data?.title,
            packageSku: location?.state?.data?.sku,
            packagePrice: location?.state?.data?.price,
            packageDuration: location?.state?.data?.duration,
            brandCount: location?.state?.data?.brandCount,
            userCount: location?.state?.data?.userCount,
            packageDescription: location?.state?.data?.description,
            subTitle: location?.state?.data?.subTitle,
            shortDesc: location?.state?.data?.shortDesc
        });
    }, [location]);

    const submitHandler = (values) => {
        values.packageStatus = checked;
        values.packageId = location?.state?.data?._id;

        dispatch(updatePackage(values, usertoken, navigateAfterSuccess));
    }

    const onCheckboxValueChange = (e) => {
        if (e.target.checked) {
            setChecked(true)
        } else {
            setChecked(false)
        }
    }
    const onCheckboxBestValue = (e) => {
        if (e.target.checked) {
            setisActive(true)
        } else {
            setisActive(false)
        }
    }

    const navigateAfterSuccess = () => {
        message.success('Successfully Updated');
        navigate('/packages', { state: { data: location?.state?.data?.planTypeId } });
    }

    return (
        <>
            <Breadcrumb className="breadCrumb" items={[{ title: "Plans & Packages" }, { title: "Packages" }, { title: "Edit Package" }]} />
            <Row className="addShortcut">
                <Col span={24}>
                    <div>
                        <Form
                            form={form}
                            name="control-hooks"
                            labelCol={{ span: 3 }}
                            layout="horizontal"
                            className="ticketForm"
                            onFinish={submitHandler}
                        >
                            <Form.Item
                                wrapperCol={{ span: 8 }}
                                label="Status"
                            >
                                <Checkbox name="packageStatus" id="packageStatus" checked={checked} onChange={onCheckboxValueChange}>
                                    Enable Package
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{ span: 8 }}
                                label="Best Value Pack"
                            >
                                <Checkbox name="packageStatus" id="packageStatus" checked={isActive} onChange={onCheckboxBestValue}>
                                    Enable Package
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name="packageTitle"
                                wrapperCol={{ span: 8 }}
                                label="Title"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter package title!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="subTitle"
                                wrapperCol={{ span: 8 }}
                                label="Sub Title"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter package sku!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="packagePrice"
                                wrapperCol={{ span: 8 }}
                                label="Price"
                                rules={[
                                    {
                                        required: true,
                                        type: 'number',
                                        message: "Please enter package price!",
                                    },
                                ]}
                            >
                                <InputNumber min={1} style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item
                                name="packageDuration"
                                wrapperCol={{ span: 8 }}
                                label="Duration (In Days)"
                                rules={[
                                    {
                                        required: true,
                                        type: 'number',
                                        message: "Please enter package duration!",
                                    },
                                ]}
                            >
                                <InputNumber min={1} style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item
                                name="brandCount"
                                wrapperCol={{ span: 8 }}
                                label="Brands (No. of brands)"
                                rules={[
                                    {
                                        required: true,
                                        type: 'number',
                                        message: "Please enter package brands count!",
                                    },
                                ]}
                            >
                                <InputNumber min={1} style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item
                                name="userCount"
                                wrapperCol={{ span: 8 }}
                                label="Users (No. of users)"
                                rules={[
                                    {
                                        required: true,
                                        type: 'number',
                                        message: "Please enter package users count!",
                                    },
                                ]}
                            >
                                <InputNumber min={1} style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item
                                name="shortDesc"
                                wrapperCol={{ span: 12 }}
                                label="Short Description"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter package short description!",
                                    },
                                ]}
                            >
                                <TextArea rows={3} />
                            </Form.Item>
                            <Form.Item
                                name="packageDescription"
                                wrapperCol={{ span: 12 }}
                                label="Description"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter package description!",
                                    },
                                ]}
                            >
                                <TextArea rows={3} />
                            </Form.Item>
                            <div className="topbtn">
                                <Button className="back-btn" onClick={() => navigate('/packages', { state: { data: location?.state?.data?.planTypeId } })}>Back</Button>
                                <Button type="primary" className="align-right" htmlType="submit">Update</Button>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row >
        </>
    );
}

export default EditPackage;
