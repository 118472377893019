import * as actionTypes from "./AnalyticsTypes";
import { Post } from "../../helpers/apicalls/apicalls";

export const Get_Analtyics = (data, token) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_LOADING_ANALTYICS });
    Post("/agents/user/dashboard-analytics", data, token)
      .then(function (response) {
        if (response.status) {
          dispatch({
            type: actionTypes.ANALTYICS_DATA_SUCCESS,
            payload: response?.data
          });
        } else {
          dispatch({ type: actionTypes.ANALTYICS_DATA_FAILED });
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.ANALTYICS_DATA_FAILED });
      });
  };
};
