export const IS_LOADING = "IS_LOADING";
export const DEPARTMENT_DATA_SUCCESS = "DEPARTMENT_DATA_SUCCESS";
export const DEPARTMENT_DATA_FALED = "DEPARTMENT_DATA_FALED";

export const IS_DEPARTMENT_REGISTER_LOADING = "IS_DEPARTMENT_REGISTER_LOADING";
export const DEPARTMENT_REGISTER_SUCCESS = "DEPARTMENT_REGISTER_SUCCESS";
export const DEPARTMENT_REGISTER_FALED = "DEPARTMENT_REGISTER_FALED";

export const IS_DEPARTMENT_UPDATE_LOADING = "IS_DEPARTMENT_UPDATE_LOADING";
export const DEPARTMENT_UPDATE_SUCCESS = "DEPARTMENT_UPDATE_SUCCESS";
export const DEPARTMENT_UPDATE_FALED = "DEPARTMENT_UPDATE_FALED";

export const IS_DEPARTMENT_STATUS_UPDATE_LOADING = "IS_DEPARTMENT_STATUS_UPDATE_LOADING";
export const DEPARTMENT_STATUS_UPDATE_SUCCESS = "DEPARTMENT_STATUS_UPDATE_SUCCESS";
export const DEPARTMENT_STATUS_UPDATE_FAILED = "DEPARTMENT_STATUS_UPDATE_FAILED";

export const IS_ACTIVE_DEPARTMENT_LOADING = "IS_ACTIVE_DEPARTMENT_LOADING";
export const ACTIVE_DEPARTMENT_SUCCESS = "ACTIVE_DEPARTMENT_SUCCESS";
export const ACTIVE_DEPARTMENT_FAILED = "ACTIVE_DEPARTMENT_FAILED";

export const IS_DEPARTMENT_USERS_LOADING = "IS_DEPARTMENT_USERS_LOADING";
export const DEPARTMENT_USERS_SUCCESS = "DEPARTMENT_USERS_SUCCESS";
export const DEPARTMENT_USERS_FAILED = "DEPARTMENT_USERS_FAILED";