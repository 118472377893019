import * as actionTypes from "./ShortcutsTypes";

const INTIAL_STATE = {
  isLoadingGet: false,
  shortcutLoading: false,
  isLoadingEdit: false,
  isLoadingDelete: false,
  shortcutData: null,
  allShortcuts: null,
  editShortCut: [],
  deleteShortCut: []
};
const ShortcutsReducer = (state = INTIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    //////////////////////////////////////////////////////////
    case actionTypes.SHORTCUT_IS_LOADING:
      return {
        ...state,
        shortcutLoading: true
      };

    case actionTypes.SHORTCUT_DATA_SUCCESS:
      return {
        ...state,
        shortcutData: payload,
        shortcutLoading: false
      };
    case actionTypes.SHORTCUT_DATA_FALED:
      return {
        ...state,
        shortcutLoading: false
      };
    ////////////////////////////////////////
    case actionTypes.IS_LOADING_GET_SHORTCUT:
      return {
        ...state,
        isLoadingGet: true
      };

    case actionTypes.GET_SHORTCUT_DATA_SUCCESS:
      return {
        ...state,
        allShortcuts: payload,
        isLoadingGet: false
      };

    case actionTypes.GET_SHORTCUT_DATA_FALED:
      return {
        ...state,
        isLoadingGet: false
      };
    ////////////////////////////////////////
    case actionTypes.IS_LOADING_EDIT_SHORTCUT:
      return {
        ...state,
        isLoadingEdit: true
      };

    case actionTypes.EDIT_SHORTCUT_DATA_SUCCESS:
      return {
        ...state,
        editShortCut: payload,
        isLoadingEdit: false
      };

    case actionTypes.EDIT_SHORTCUT_DATA_FALED:
      return {
        ...state,
        isLoadingEdit: false
      };

    ////////////////////////////////////////
    case actionTypes.IS_LOADING_DELETE_SHORTCUT:
      return {
        ...state,
        isLoadingDelete: true
      };

    case actionTypes.DELETE_SHORTCUT_DATA_SUCCESS:
      return {
        ...state,
        deleteShortCut: payload,
        isLoadingDelete: false
      };

    case actionTypes.DELETE_SHORTCUT_DATA_FALED:
      return {
        ...state,
        isLoadingDelete: false
      };

    default:
      return state;
  }
};

export default ShortcutsReducer;
