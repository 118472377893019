import * as actionTypes from "./VisitorChatsTypes";

const INTIAL_STATE = {
  visitorChatData: []
};
const VisitorChatsReducer = (state = INTIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.VISITORDATA:
      let all_visitorChats = [...state?.visitorChatData];
      const isDealExist = all_visitorChats?.findIndex(
        (arr) =>
          arr?.data?.Visitor?.unique_id ===
          payload?.array[0]?.data?.Visitor?.unique_id
      )
      if (isDealExist === -1)
        all_visitorChats = [...payload?.array];
      if (isDealExist !== -1) {
        switch (payload?.event) {
          case "UPDATE":
            all_visitorChats[isDealExist] = payload?.array[0];
            break;
          case "MINUS":
            all_visitorChats.splice(isDealExist, 1);
            break;
          default:
            break;
        }
      }
      return {
        ...state,
        visitorChatData: all_visitorChats
      };

    default:
      return state;
  }
};

export default VisitorChatsReducer;
