import React, { useState, useEffect } from "react";
import {
    Input,
    Space,
    Table,
    theme,
    Button,
    Switch,
    Modal,
    message,
} from "antd";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Get_All_Brands, Get_Brands_By_Id } from "../../redux/Brands/BrandsActions";
import { GetOfflineStatus } from "../../redux/OfflineStatus/OfflineStatusActions";
const { Search } = Input;

const OfflineStatus = () => {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.Auth.userData);
    const allStatusData = useSelector((state) => state.OfflineStatusReducer.allStatusData);
    const isLoading = useSelector((state) => state.OfflineStatusReducer.isLoading);
    const usertoken = useSelector((state) => state.Auth.token);
    const [allData, setAllData] = useState([]);

    useEffect(() => {
        let dataNew = {
            admin_id: userData?._id
        };
        const data = {
            companyId: userData?.companyId
        };
        if (userData?.role === "superadmin") {
            dispatch(Get_All_Brands(usertoken));
        } else {
            dispatch(Get_Brands_By_Id(data, usertoken));
        }
        dispatch(GetOfflineStatus(dataNew, usertoken));
    }, []);

    const navigate = useNavigate();
    function handleClick() {
        navigate("/offlineStatus/status", { state: "" });
    }
    const onSearch = (value) => {
        if (value !== null) {
            const filteredArray = allStatusData?.filter(item => item?.name?.includes(value));
            setAllData(filteredArray);
        }
        else {
            setAllData(allStatusData);
        }
    };

    useEffect(() => {
        if (allStatusData?.length > 0) {
            setAllData(allStatusData);
        }
    }, [allStatusData]);

    const onRow = (record, index) => {
        return {
            onClick: () => handleRowClick(record, index),
            // Add more event handlers as needed
        };
    };
    const handleRowClick = (record, index) => {
        navigate("/offlineStatus/status", { state: record });

        // Add your row click logic here
    };

    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            render: (text) => <a>{text}</a>,
        },
        {
            title: "Message",
            dataIndex: "message",
            render: (text) => <a>{text}</a>,
        },
        {
            title: "Brand",
            dataIndex: "brand",
            width: 350,
            render: (text) => <a>{text.name}</a>,
        },
        {
            title: "isActive",
            dataIndex: "isActive",
            width: 250,
            render: (isActive, record) => <Switch checked={isActive} disabled={true} />,
        },
    ];
    return (
        <>
            <div className="topBtns">
                <div className="shortcut">
                    <Space direction="vertical">
                        <Search
                            placeholder="Search"
                            onSearch={onSearch}
                            style={{
                                width: 200,
                            }}
                        />
                    </Space>
                </div>
                <div>
                    <Button
                        className="addShort"
                        type="primary"
                        onClick={handleClick}
                    >
                        Add Offline Status
                    </Button>

                </div>
            </div>
            <div>
                <Table
                    loading={isLoading}
                    columns={columns}
                    onRow={onRow}
                    dataSource={allData?.map((item, index) => ({ ...item, key: index }))}

                />
            </div>
        </>

    );
}

export default OfflineStatus;
