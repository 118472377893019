import React from "react";
import { Layout } from "antd";
const { Content, Footer } = Layout;
const Footers = () => {
  let Cyear = new Date().getFullYear();
  return (
    <Footer style={{
      overflow: 'auto',
      width: "100%",
      position: 'fixed',
      bottom: '-3px',
      right: 0
    }} className="footer">
      © {Cyear} Convo Bird. All Rights Reserved.
    </Footer>
  );
};

export default Footers;
