import { Button, Image, Input, Tooltip } from "antd";
import {
  CheckOutlined,
} from "@ant-design/icons";
import SendIcon from '@mui/icons-material/Send';
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ForumIcon from "@mui/icons-material/Forum";
import CloseIcon from "@mui/icons-material/Close";
import MyLogo from '../../assets/image/imgs.png'
import convochat from '../../assets/image/convochat.png'

const BrandDiv = ({
  selectedTab,
  bgcolor,
  headerLable,
  banner1,
  banner2,
  visitorBg,
  heightchat,
  borderChat,
  widthchat,
  isIcons,
  txtcolor,
  isLabled,
  chatLabel,
  selectedImage,
  titleLable,
  bgcolorBadge,
  defaultIcon,
  oldPicture,
  activekeyid
}) => {

  return (
    <>
      {selectedTab !== "" ? (
        <>
          {activekeyid == 1 && (
            <div
              className="chatbadgeCont"
              style={{ backgroundColor: `${bgcolorBadge}` }}
            >
              <div className="chatbadgeHead">
                <Image
                  src={MyLogo}
                  width={60}
                  height={11}
                  preview={false}
                  className="badgeImg"
                />
              </div>
              <div className="letHelp">
                <p style={{ color: txtcolor }}>{titleLable}</p>
                {selectedImage ? (
                  <Image
                    src={URL.createObjectURL(selectedImage)}
                    width={110}
                    preview={false}
                    className="badgeImg"
                  />
                ) : oldPicture !== null ? (< Image
                  src={oldPicture}
                  width={110}
                  preview={false}
                  className="badgeImg"
                />) : (
                  <ForumIcon
                    style={{
                      color: `${txtcolor}`,
                      fontSize: 50,
                      width: 70
                    }}
                  />
                )}
              </div>
              <div className="chatbadgeFooter">
                <Input placeholder="Type your message here..." />
                <Button type="primary" icon={<SendIcon style={{ fontSize: 18 }} />} />
              </div>
            </div>
          )}
          {activekeyid == 2 && (
            <div
              className="chatbadgeCont"
              style={{ backgroundColor: `${bgcolorBadge}` }}
            >
              <div className="chatbadgeHead">
                <Image
                  src={MyLogo}
                  width={60}
                  height={11}
                  preview={false}
                  className="badgeImg"
                />
              </div>
              <div className="letHelp">
                {selectedImage ? (
                  <Image
                    src={URL.createObjectURL(selectedImage)}
                    width={110}
                    preview={false}
                    className="badgeImg"
                  />
                ) : oldPicture !== null ? (< Image
                  src={oldPicture}
                  width={110}
                  preview={false}
                  className="badgeImg"
                />) : (
                  <ForumIcon
                    style={{
                      color: `${txtcolor}`,
                      fontSize: 50,
                      width: 70
                    }}
                  />
                )}
                <p style={{ color: txtcolor }}>{titleLable}</p>
              </div>
              <div className="chatbadgeFooter">
                <Input style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: "0.57143rem", borderBottomLeftRadius: "0.57143rem", }} placeholder="Type your message here..." />
                <Button type="primary" icon={<SendIcon style={{ fontSize: 18 }} />} />
              </div>
            </div>
          )}
          {activekeyid == 3 && (
            <div
              className="chatbadgeCont"
              style={{ backgroundColor: `${bgcolorBadge}` }}
            >
              <div className="chatbadgeHead">
                <Image
                  src={MyLogo}
                  width={60}
                  height={11}
                  preview={false}
                  className="badgeImg"
                />
              </div>
              <div className="letHelp">
                <p style={{ color: txtcolor }}>{titleLable}</p>
              </div>
              <div className="chatbadgeFooter">
                <Input placeholder="Type your message here..." />
                <Button type="primary" icon={<SendIcon style={{ fontSize: 18 }} />} />
              </div>
            </div>
          )}
          {activekeyid == 4 && (
            <div className="chatbadgeCont" style={{
              backgroundImage: `url(${selectedImage ? URL?.createObjectURL(selectedImage) : oldPicture})`, backgroundColor: bgcolorBadge,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center"
            }}>
              <div className="chatbadgeHead">
                <Image
                  src={MyLogo}
                  width={60}
                  height={11}
                  preview={false}
                  className="badgeImg"
                />
              </div>
              <div className="letHelp">
                {selectedImage == null && oldPicture == null && (
                  <ForumIcon
                    style={{
                      color: `${txtcolor}`,
                      fontSize: 50,
                      width: 70
                    }}
                  />
                )}
              </div>
              <div className="chatbadgeFooter">
                <Input placeholder="Type your message here..." />
                <Button type="primary" icon={<SendIcon style={{ fontSize: 18 }} />} />
              </div>
            </div>
          )}
          {activekeyid == 5 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div>
                <button
                  style={{
                    padding: 15,
                    backgroundColor: `${bgcolorBadge}`,
                    borderRadius: `50%`,
                    border: "none",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 65,
                    width: 65,
                  }}
                  type="button"
                >
                  <img style={{ height: "100%", width: "100%" }} src={convochat} />
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="chatboxView">
          <div
            className="chat-window2"
            id="chat-window2"
            style={{
              backgroundColor: `${bgcolor}`,
              width: 332,
              height: 434,
              borderRadius: 10,
              boxShadow:
                "rgb(0 0 0 / 10%) 0px 1px 6px, rgb(0 0 0 / 20%) 0px 2px 24px ",
              overflow: "hidden",
            }}
          >
            <div
              className="headers-chat"
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                padding: 14,
              }}
            >
              <Tooltip placement="top" title={"Header Label"}>
                {" "}
                <p style={{ margin: 0, color: "white" }}>{headerLable}</p>
              </Tooltip>
              <CloseIcon style={{ fontSize: 16, color: "#fff" }} />
            </div>
            <div
              className="liveHeader"
              style={{
                backgroundColor: "#fff",
                boxShadow: "inset 0 -2px 0 0 rgb(210 198 198 / 50%)",
                padding: 8,
              }}
            >
              <div
                className="head"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <SupportAgentIcon />
                <div style={{ marginLeft: 10 }}>
                  <Tooltip placement="top" title={"Banner Label 1"}>
                    <h4 style={{ fontSize: 14, fontWeight: 600, margin: 0 }}>
                      {banner1}
                    </h4>
                  </Tooltip>
                  <Tooltip placement="top" title={"Banner Label 2"}>
                    <p style={{ fontSize: 15, margin: 0 }}>{banner2}</p>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div
              className="message-box"
              id="messageBox"
              onclick="closeAll()"
              style={{
                height: "65%",
                width: "100%",
                overflow: "scroll",
                backgroundColor: "#fff",
                padding: 10,
                // marginBottom: 50,
                position: "relative",
              }}
            >
              <div
                className="msgHolder"
                style={{
                  paddingBottom: 26,
                  width: "100%",
                  marginTop: 10,
                  marginBottom: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  className="second-chat"
                  style={{
                    color: "#000",
                    padding: 10,
                    boxShadow:
                      "rgb(0 0 0 / 10%) 0px 1px 1px, rgb(0 0 0 / 20%) 0px 2px 5px",
                    wordWrap: "break-word",
                    borderRadius: 10,
                    display: "inline-block",
                    maxWidth: "100%",
                    fontSize: 14,
                    alignSelf: "flex-start",
                    minWidth: 110,
                    marginBottom: 7,
                    backgroundColor: `${visitorBg}`,
                  }}
                >
                  <p
                    style={{
                      fontSize: "14",
                      overflowwrap: "break-word",
                      margin: 0,
                    }}
                  >
                    Hi there i need some help
                  </p>
                  <div className="arrow"></div>
                  <div
                    className="chat-footer"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingTop: "10",
                      margin: 0,
                    }}
                  >
                    <CheckOutlined style={{ fontSize: 10 }} />
                    <h5 style={{ fontSize: 12, color: "#000", margin: 0 }}>
                      19:49
                    </h5>
                  </div>
                </div>
                <div
                  className="first-chat"
                  style={{
                    backgroundColor: `${bgcolor}`,
                    color: "#fff",
                    padding: 10,
                    boxShadow:
                      "rgb(0 0 0 / 10%) 0px 1px 1px, rgb(0 0 0 / 20%) 0px 2px 5px ",
                    wordWrap: "break-word",
                    borderRadius: 10,
                    display: "inline-block",
                    maxWidth: "100%",
                    fontSize: "14",
                    alignSelf: "flex-end ",
                    minWidth: 110,
                  }}
                >
                  <p
                    style={{
                      fontSize: "14",
                      overflowwrap: "break-word",
                      margin: 0,
                    }}
                  >
                    hello, how may i help you
                  </p>
                  <div className="arrow"></div>
                  <div
                    className="chat-footer"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingTop: "10",
                      margin: 0,
                    }}
                  >
                    <CheckOutlined style={{ fontSize: 10 }} />
                    <h5 style={{ fontSize: 12, color: "#fff", margin: 0 }}>
                      19:49
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="typing-box"
              className="typing-box"
              style={{
                display: "none",
                position: "absolute",
                bottom: "12%",
                left: "5%",
              }}
            >
              <p className="typing-txt" style={{ fontSize: 10 }}>
                typing...
              </p>
            </div>
            <div
              className="input-box"
              style={{
                // position: "absolute",
                fontSize: 12,
                bottom: 0,
                left: 0,
                right: 0,
                padding: 11,
                borderTop: "1px solid lightgray",
                backgroundColor: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "inherit",

              }}
            >
              <div className="write-reply" style={{ float: "left" }}>
                <input
                  className="inputText"
                  type="text"
                  id="textInput"
                  placeholder="Write a here..."
                  style={{ border: "none", outline: "none", fontSize: 14 }}
                />
              </div>
              <div
                className="send-button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <MoreHorizOutlinedIcon style={{ fontSize: 15 }} />
                <AttachFileOutlinedIcon style={{ fontSize: 15 }} />
                <button
                  type="submit"
                  className="send-message"
                  id="send"
                  onclick="userResponse()"
                  style={{ background: "transparent", border: "none" }}
                >
                  <SendIcon style={{ fontSize: 18 }} />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BrandDiv;
