import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect, useRef } from "react";
import { downloadBufferFile, downloadBufferFileWithType, fileChanger } from "../../helpers/fileChanger";

export const Downloader = ({ file, filename, }) => {
  const isArrayBuffer = (file) => {
    return file instanceof ArrayBuffer;
  };

  
  const isBlob = (file) => {
    return file instanceof Blob;
  };
  let url;
  const checkFileType = (file) => {
    if (isArrayBuffer(file?.body)) {

      let blob = downloadBufferFileWithType(file?.body, file?.filename, file?.filetype);
      url = URL.createObjectURL(blob);
    } else if (isBlob(file?.body)) {
      url = URL.createObjectURL(fileChanger(file?.body, file?.filetype));
    } else {
      if (file?.body?.type == "Buffer") {
        let blob = downloadBufferFile(file?.body, file?.filename, file?.filetype);
        url = URL.createObjectURL(blob);
      }
      else {
        url = URL.createObjectURL(fileChanger(file?.body, file?.filetype));
      }
    }
  };

  const link = useRef(null);
  checkFileType(file);
  const handleDownload = () => {
    link.current.click()
  };
  return (
    <>
      <Button onClick={handleDownload}><DownloadOutlined /></Button>
      <a style={{ display: "none" }} ref={link} href={url} download={filename}>
        Table export
      </a>
    </>
  );
};
