import React from "react";
import { Post } from "../../helpers/apicalls/apicalls";
import * as actionTypes from "./VisitorsJoinedTypes";

export const saveVisitorServed = (data, servedData) => {
    return (dispatch) => {
        let findData = servedData?.some((x) => x?.unique_id == data?.unique_id);
        if (findData) {
            // dispatch({
            //     type: actionTypes.VISITOR_FAILED,
            // });
        }
        else {
            let newServed = [...servedData];
            newServed.push(data);
            dispatch({
                type: actionTypes.VISITOR_SUCCESS,
                payload: newServed
            });
        }
    };
};

export const onLeaveServed = (data, servedData) => {
    return (dispatch) => {
        let findData = servedData?.filter((x) => x?.unique_id != data?.unique_id);
        dispatch({
            type: actionTypes.VISITOR_SUCCESS,
            payload: findData
        });
    };
};


