import React, { useState, useEffect } from "react";

export default function IncrementalTimer({ start_time }) {
  const [elapsedTime, setElapsedTime] = useState("");

  useEffect(() => {
    // Parse start_time to handle iOS or any valid time format
    const start = new Date(Date.parse(start_time)); // Ensuring valid date parsing
    const timeIncrementHandler = () => {
      const now = new Date();
      const distance = now - start;

      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;

      const hours = Math.floor(distance / _hour);
      const minutes = Math.floor((distance % _hour) / _minute);
      const seconds = Math.floor((distance % _minute) / _second);

      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");

      setElapsedTime(
        `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
      );
    };

    // Start the timer interval
    const timer = setInterval(timeIncrementHandler, 1000);

    // Cleanup function to clear the interval on unmount
    return () => clearInterval(timer);
  }, [start_time]); // Dependency added to ensure it reacts to changes in start_time

  return <p id="countup">{elapsedTime}</p>;
}
