export const IS_LOADING_GET_BANNED = "IS_LOADING_GET_BANNED";
export const GET_BANNED_DATA_SUCCESS = "GET_BANNED_DATA_SUCCESS";
export const GET_BANNED_DATA_FAILED = "GET_BANNED_DATA_FAILED";

export const IS_LOADING_UNBLOCK = "IS_LOADING_UNBLOCK";
export const UNBLOCK_DATA_SUCCESS = "UNBLOCK_DATA_SUCCESS";
export const UNBLOCK_DATA_FAILED = "UNBLOCK_DATA_FAILED";

export const IS_LOADING_BANNED_PROPERTIES = "IS_LOADING_BANNED_PROPERTIES";
export const BANNED_PROPERTIES_DATA_SUCCESS = "BANNED_PROPERTIES_DATA_SUCCESS";
export const BANNED_PROPERTIES_DATA_FAILED = "BANNED_PROPERTIES_DATA_FAILED";

export const IS_LOADING_GET_BANNED_PROPERTIES = "IS_LOADING_GET_BANNED_PROPERTIES";
export const GET_BANNED_PROPERTIES_DATA_SUCCESS = "GET_BANNED_PROPERTIES_DATA_SUCCESS";
export const GET_BANNED_PROPERTIES_DATA_FAILED = "GET_BANNED_PROPERTIES_DATA_FAILED";


export const IS_LOADING_DELETE_BANNED_PROPERTIES = "IS_LOADING_DELETE_BANNED_PROPERTIES";
export const DELETE_BANNED_PROPERTIES_DATA_SUCCESS = "DELETE_BANNED_PROPERTIES_DATA_SUCCESS";
export const DELETE_BANNED_PROPERTIES_DATA_FAILED = "DELETE_BANNED_PROPERTIES_DATA_FAILED";

