import React from 'react';
import {
    Form,
    Input,
    Col,
    Row,
    ColorPicker,
    Switch,
    Slider,
} from "antd";

const ButtonTab = ({ bgcolorBadge, setbgcolorBadge, handleChangeColors }) => {



    return (

        <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 400 }}
            autoComplete="off"
        >
            <Form.Item label="Background Color" name="bgcolor">
                <ColorPicker
                    format={"hex"}
                    defaultFormat={"hex"}
                    defaultValue={bgcolorBadge}
                    color={bgcolorBadge}
                    onChange={(value, hex) => handleChangeColors(value, hex)}
                    size="large"
                    showText
                />
            </Form.Item>

        </Form>

    );
}

export default ButtonTab;
