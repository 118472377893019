import * as actionTypes from "./ClientsTypes.js";

const INTIAL_STATE = {
    data: null,
    isLoading: false,
    isServedVisitor: false,
    isLeaveVisitor: false
};
const ClientReducer = (state = INTIAL_STATE, action) => {
    const { payload } = action;
    switch (action.type) {
        case actionTypes.IS_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.CLIENT_DATA_SUCCESS:
            return {
                ...state,
                data: payload,
                isLoading: false
            };
        case actionTypes.CLIENT_DATA_FAILED:
            return {
                ...state,
                isLoading: false
            };
        case actionTypes.CLIENT_DATA_STORE_SUCCESS:
            return {
                ...state,
                data: payload,
                isLoading: false
            };
        case actionTypes.CLIENT_DATA_STORE_FAILED:
            return {
                ...state,
                isLoading: false
            };

        case actionTypes.CLIENT_TOOGLER_SUCCESS:
            return {
                ...state,
                isServedVisitor: payload
            };

        case actionTypes.CLIENT_TOOGLER_LEAVE_SUCCESS:
            return {
                ...state,
                isLeaveVisitor: payload
            };

        default:
            return state;
    }
};

export default ClientReducer;
