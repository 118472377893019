import React, { useState, useEffect } from "react";
import { Input, Space, Table, theme, Button, message, Popconfirm } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Get_agents_by_brand } from "../../redux/Users/UsersActions";
import { Delete_Shortcut, Get_Shortcut } from "../../redux/Shortcuts/ShortcutsActions";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const { Search } = Input;

const Shortcuts = () => {
  const userData = useSelector((state) => state.Auth.userData);
  let filterBrands = userData?.assignBrands?.map((i) => i?.token);
  const AllShortcuts = useSelector((state) => state.Shortcuts.allShortcuts);
  const isLoadingGet = useSelector((state) => state.Shortcuts.isLoadingGet);
  const usertoken = useSelector((state) => state.Auth.token);
  const [allData, setAllData] = useState([]);
  const dispatch = useDispatch();
  
  useEffect(() => {
    let data = {
      brandToken: filterBrands,
      _id: userData?._id
    };
    dispatch(Get_Shortcut(data, usertoken));
  }, []);

  useEffect(() => {
    let data = {
      brandToken: filterBrands,
      user_id: userData?._id
    };
    dispatch(Get_agents_by_brand(data, usertoken));
  }, []);
  const {
    token: { colorBgContainer }
  } = theme.useToken();
  const columns = [
    {
      title: "Shortcut",
      dataIndex: "shortcut",
      render: (text) => <a>{text}</a>
    },
    {
      title: "Message",
      dataIndex: "message",

    },
    {
      title: "Available For",
      dataIndex: "available_for",
      render: (text) => text?.map((i, index) => <p key={index}>{i?.name}</p>)
    },
    {
      title: "Tags",
      dataIndex: "tags",

    },
    {
      title: "Action",
      render: (text) => <div style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer" }}>
        {text?.user_id == userData?._id &&
          <><div onClick={() =>
            navigate('/shortcuts/addshortcut', {
              state: text
            })} style={{ padding: "0px 5px 0px 5px" }}>
            <EditOutlined style={{ fontSize: 18 }} />
          </div>
            <div style={{ padding: "0px 5px 0px 5px" }}>
              <Popconfirm
                placement="topLeft"
                title="Delete the shortcut"
                description="Are you sure to delete this shortcut?"
                onConfirm={() => DeleteShortcut(text?._id)}
                okText="Yes"
                cancelText="No"
              >
                {/* <Button danger>Delete</Button> */}
                <DeleteOutlined style={{ fontSize: 17 }} />
              </Popconfirm>
            </div>
          </>
        }

      </div>

    }
  ];

  const DeleteShortcut = (id) => {
    let data = {
      user_id: userData?._id,
      _id: id
    };
    dispatch(Delete_Shortcut(data, usertoken, onSuccess));

  }
  const onSuccess = (msg) => {
    if (msg) {
      message.success(`${msg}`);
      let data = {
        brandToken: filterBrands,
        _id: userData?._id
      };
      dispatch(Get_Shortcut(data, usertoken));
    }
  };

  const navigate = useNavigate();
  function handleClick() {
    navigate("/shortcuts/addshortcut");
  }

  const onSearch = (value) => {
    if (value !== null) {
      const filteredArray = AllShortcuts?.filter(item => item?.shortcut?.includes(value));
      setAllData(filteredArray);
    }
    else {
      setAllData(AllShortcuts);
    }
  };

  useEffect(() => {
    if (AllShortcuts?.length > 0) {
      setAllData(AllShortcuts);
    }
    else {
      setAllData([]);
    }
  }, [AllShortcuts]);


  return (
    <>
      <div className="topBtns">
        <div className="shortcut">
          <Space direction="vertical">
            <Search
              placeholder="Search"
              onSearch={onSearch}
              style={{
                width: 200
              }}
            />
          </Space>
        </div>
        <div>
          <Button
            className="addShort"
            type="primary"
            onClick={handleClick}>
            Add Shortcut
          </Button>
        </div>
      </div>
      <div>
        <Table
          loading={isLoadingGet}
          columns={columns}
          dataSource={allData?.map((item, index) => ({ ...item, key: index }))}
        />
      </div>
    </>
  );
};

export default Shortcuts;
