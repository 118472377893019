import * as actionTypes from "./companyTypes";

const INTIAL_STATE = {
    isLoading: false,
    comapnies: []
};

const CompanyReducer = (state = INTIAL_STATE, action) => {
    const { payload } = action;
    switch (action.type) {
        case actionTypes.IS_LOADING:
            return {
                ...state,
                isLoading: true
            };

        default:
            return state;
    }
};

export default CompanyReducer;
