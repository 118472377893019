import * as actionTypes from "./BannedTypes";

const INTIAL_STATE = {
    banneddata: [],
    isLoadingbanned: false,
    unblockdata: [],
    isLoadingunblock: false,
    bannedPropertiesdata: [],
    isLoadingbannedProperties: false,
    getbannedPropertiesdata: [],
    isLoadinggetbannedProperties: false,

    deletebannedPropertiesdata: [],
    isLoadingDeletebannedProperties: false,
};
const BannedReducer = (state = INTIAL_STATE, action) => {
    const { payload } = action;
    switch (action.type) {
        case actionTypes.IS_LOADING_GET_BANNED:
            return {
                ...state,
                isLoadingbanned: true
            };
        case actionTypes.GET_BANNED_DATA_SUCCESS:
            return {
                ...state,
                banneddata: payload,
                isLoadingbanned: false
            };
        case actionTypes.GET_BANNED_DATA_FAILED:
            return {
                ...state,
                isLoadingbanned: false
            };
        ////////////////////////////////////////////////////
        case actionTypes.IS_LOADING_UNBLOCK:
            return {
                ...state,
                isLoadingunblock: true
            };
        case actionTypes.UNBLOCK_DATA_SUCCESS:
            return {
                ...state,
                unblockdata: payload,
                isLoadingunblock: false
            };
        case actionTypes.UNBLOCK_DATA_FAILED:
            return {
                ...state,
                isLoadingunblock: false
            };

        ////////////////////////////////////////////////////
        case actionTypes.IS_LOADING_BANNED_PROPERTIES:
            return {
                ...state,
                isLoadingbannedProperties: true
            };
        case actionTypes.BANNED_PROPERTIES_DATA_SUCCESS:
            return {
                ...state,
                bannedPropertiesdata: payload,
                isLoadingbannedProperties: false
            };
        case actionTypes.BANNED_PROPERTIES_DATA_FAILED:
            return {
                ...state,
                isLoadingbannedProperties: false
            };

        ////////////////////////////////////////////////////
        case actionTypes.IS_LOADING_GET_BANNED_PROPERTIES:
            return {
                ...state,
                isLoadinggetbannedProperties: true
            };
        case actionTypes.GET_BANNED_PROPERTIES_DATA_SUCCESS:
            return {
                ...state,
                getbannedPropertiesdata: payload,
                isLoadinggetbannedProperties: false
            };
        case actionTypes.GET_BANNED_PROPERTIES_DATA_FAILED:
            return {
                ...state,
                isLoadinggetbannedProperties: false
            };

        ////////////////////////////////////////////////////
        case actionTypes.IS_LOADING_DELETE_BANNED_PROPERTIES:
            return {
                ...state,
                isLoadingDeletebannedProperties: true
            };
        case actionTypes.DELETE_BANNED_PROPERTIES_DATA_SUCCESS:
            return {
                ...state,
                deletebannedPropertiesdata: payload,
                isLoadingDeletebannedProperties: false
            };
        case actionTypes.DELETE_BANNED_PROPERTIES_DATA_FAILED:
            return {
                ...state,
                isLoadingDeletebannedProperties: false
            };



        default:
            return state;
    }
};

export default BannedReducer;
