import { Post } from "../../helpers/apicalls/apicalls";
import * as actionTypes from "./AllHistoryTypes";

export const getVisitorAllHistory = (data, token) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.IS_LOADING_ALL_HISTORY,
    });
    Post("/agents/user/getHistory", data, token)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: actionTypes.ALL_HISTORY_DATA_SUCCESS,
            payload: res
          });
        }
        else {
          dispatch({
            type: actionTypes.ALL_HISTORY_DATA_FAILED
          });
        }
      })
      .catch((err) => {

        dispatch({
          type: actionTypes.ALL_HISTORY_DATA_FAILED
        });
      });
  };
};

export const getBySearch = (data, token) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.IS_LOADING_OVERALL,
    });
    Post("/agents/user/getBySearch", data, token)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: actionTypes.ALL_HISTORY_DATA_SUCCESS,
            payload: res
          });
        }
        else {
          dispatch({
            type: actionTypes.ALL_HISTORY_DATA_FAILED
          });
        }
      })
      .catch((err) => {

        dispatch({
          type: actionTypes.ALL_HISTORY_DATA_FAILED
        });
      });
  };
};

export const getOverAllHistory = (data, token) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.IS_LOADING_OVERALL,
    });
    Post("/agents/user/getAllHistory", data, token)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: actionTypes.ALL_VISITOR_OVERALL_DATA_SUCCESS,
            payload: res.data
          });
        }
        else {
          dispatch({
            type: actionTypes.ALL_VISITOR_OVERALL_DATA_FAILED
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ALL_VISITOR_OVERALL_DATA_FAILED
        });
      });
  };
};



