export const IS_LOADING_ALL_HISTORY = "IS_LOADING_ALL_HISTORY";
export const ALL_HISTORY_DATA_SUCCESS = "ALL_HISTORY_DATA_SUCCESS";
export const ALL_HISTORY_DATA_FAILED = "ALL_HISTORY_DATA_FAILED";

export const IS_LOADING_OVERALL = "IS_LOADING_OVERALL";
export const ALL_VISITOR_OVERALL_DATA_SUCCESS = "ALL_VISITOR_OVERALL_DATA_SUCCESS";
export const ALL_VISITOR_OVERALL_DATA_FAILED = "ALL_VISITOR_OVERALL_DATA_FAILED";

// export const IS_LOADING_MESSAGES = "IS_LOADING_MESSAGES";
// export const ALL_VISITOR_MESSAGES_DATA_SUCCESS = "ALL_VISITOR_MESSAGES_DATA_SUCCESS";
// export const ALL_VISITOR_MESSAGES_DATA_FAILED = "ALL_VISITOR_MESSAGES_DATA_FAILED";