export const IS_LOADING = "IS_LOADING";
export const WIDGET_ADDED_SUCCESS = "WIDGET_ADDED_SUCCESS";
export const WIDGET_ADDED_FAILED = "WIDGET_ADDED_FAILED";

export const IS_LOADING_GET = "IS_LOADING_GET";
export const WIDGET_GET_SUCCESS = "WIDGET_GET_SUCCESS";
export const WIDGET_GET_FAILED = "WIDGET_GET_FAILED";
export const WIDGET_GET_CLEAN = "WIDGET_GET_CLEAN";

export const IS_LOADING_GREETING = "IS_LOADING_GREETING ";
export const GREETING_SUCCESS = "GREETING_SUCCESS";
export const GREETING_FAILED = "GREETING_FAILED";
export const GREETING_CLEAN = "GREETING_CLEAN";


export const IS_LOADING_ADD_GREETING = "IS_LOADING_ADD_GREETING ";
export const ADD_GREETING_SUCCESS = "ADD_GREETING_SUCCESS";
export const ADD_GREETING_FAILED = "ADD_GREETING_FAILED";
