import React, { useEffect, useState } from "react";
import {
  Button,
  Tabs,
  Form,
  Input,
  Col,
  Row,
  message,
  Spin,
  Select
} from "antd";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import SoundComponent from "../../components/SoundComponent/SoundComponent";
import { visitorSounds, incomingMessage } from "../../helpers/sounds";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordProfile, updateGeneralProfile, updateSoundProfile } from "../../redux/Profile/ProfileActions";

const Personal = () => {
  const onChange = (key) => {
  };
  const items = [
    {
      key: "1",
      label: `Profile`,
      children: (
        <TabPaneWrapper>
          <Profile />
        </TabPaneWrapper>
      ),
    },
    {
      key: "2",
      label: `Sounds & notifications`,
      children: (
        <TabPaneWrapper>
          <Sounds_notifications />
        </TabPaneWrapper>
      ),
    },
    {
      key: "3",
      label: `Change Password`,
      children: (
        <TabPaneWrapper>
          <ChangePassword />
        </TabPaneWrapper>
      ),
    },
  ];

  return (
    <div className="personalTabs">
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </div>
  );
};
export function TabPaneWrapper({ children, ...props }) {
  return <div {...props}>{children}</div>;
}

const Profile = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.Auth.userData);
  const usertoken = useSelector((state) => state.Auth.token);
  const profileLoading = useSelector((state) => state.ProfileReducer.profileLoading);
  const [sudo_name, setSudo_name] = useState(userData?.sudoName);
  const [name, setName] = useState(userData?.name);
  const [timers, settimers] = useState(userData?.awayTimer);
  const [form] = Form.useForm();

  const onFinish = () => {
    if (sudo_name !== null) {
      let data = {
        Id: userData?._id,
        sudoName: sudo_name,
        name: name,
        awayTimer: timers
      }
      dispatch(updateGeneralProfile(data, usertoken, onSuccess))
    }
  };

  const onSuccess = (msg) => {
    if (msg) {
      message.success(`${msg}`);
    }
  };

  const handleChange = (value) => {
    settimers(value);
  };

  useEffect(() => {
    form.setFieldsValue({
      name: userData?.name,
      displayname: userData?.sudoName,
      email: userData?.email,
      awayTimer: userData?.awayTimer
    });
  }, [userData]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,
        color: "#ffffff",
        margin: "0px 25px"
      }}
      spin
    />
  );
  return (
    <div className="profileTabs">
      <Row className="addShortcut">
        <Col span={12}>
          <h6>Profile</h6>
          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item label="Email" name="email">
              <Input disabled={true} />
            </Form.Item>

            <Form.Item label="Name" name="name">
              <Input disabled={true} value={name} onChange={(e) => setName(e.target.value)} />
            </Form.Item>
            <Form.Item label="Display Name" name="displayname">
              <Input value={sudo_name} onChange={(e) => setSudo_name(e.target.value)} />
            </Form.Item>

            <Form.Item label="Away Time" name="awayTimer">
              <Select
                onChange={handleChange}
                options={[
                  {
                    value: '1800000',
                    label: '30 Mins',
                  },
                  {
                    value: '3600000',
                    label: '60 Mins',
                  },
                  {
                    value: 'never',
                    label: 'Never',
                  }
                ]}
              />
            </Form.Item>
            {/* <Form.Item label="Profile" name="profile">
              <Upload {...props}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item> */}
            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 16,
              }}
            >
              <Button disabled={profileLoading} style={{ marginTop: 10 }} type="primary" htmlType="submit">
                {profileLoading ? <Spin indicator={antIcon} style={{ padding: "0px 17px 0px 17px" }} /> : "Save Changes"}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};
const Sounds_notifications = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.Auth.userData);
  const usertoken = useSelector((state) => state.Auth.token);
  const soundLoading = useSelector((state) => state.ProfileReducer.soundLoading);


  const [incomingVisitor, setincomingVisitor] = useState(null);
  const [messageSound, setmessageSound] = useState(null);
  const [RequestSound, setRequestSound] = useState(null);

  /////Inncominng Visitor Sound
  const [incomingVisitorKey, setincomingVisitorKey] = useState(userData?.sounds?.incomingVisitor?.key);
  const [incomingMute, setincomingMute] = useState(userData?.sounds?.incomingVisitor?.isMuted);
  const [incomingVolume, setincomingVolume] = useState(userData?.sounds?.incomingVisitor?.volume);
  /////Chat Request Sound
  const [RequestSoundKey, setRequestSoundKey] = useState(userData?.sounds?.chatRequest?.key);
  const [RequestSoundMute, setRequestSoundMute] = useState(userData?.sounds?.chatRequest?.isMuted);
  const [RequestSoundVolume, setRequestSoundVolume] = useState(userData?.sounds?.chatRequest?.volume);
  /////Incoming Messages Sound
  const [messageSoundKey, setmessageSoundKey] = useState(userData?.sounds?.incomingMessages?.key);
  const [messageSoundMute, setmessageSoundMute] = useState(userData?.sounds?.incomingMessages?.isMuted);
  const [messageSoundVolume, setmessageSoundVolume] = useState(userData?.sounds?.incomingMessages?.volume);

  const handleChangeIncoming = (value, e) => {
    setincomingVisitorKey(value);
    setincomingVisitor(e?.sound);
  };
  const handleChangeRequset = (value, e) => {
    setRequestSoundKey(value);
    setRequestSound(e?.sound);

  };
  const handleChangeMessage = (value, e) => {
    setmessageSoundKey(value);
    setmessageSound(e?.sound);
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,
        color: "#ffffff",
        margin: "0px 25px"
      }}
      spin
    />
  );

  const soundSubmit = () => {
    let AllData = {
      Id: userData?._id,
      sounds: {
        incomingVisitor: {
          key: incomingVisitorKey,
          volume: incomingVolume,
          isMuted: incomingMute
        },
        chatRequest: {
          key: RequestSoundKey,
          volume: RequestSoundVolume,
          isMuted: RequestSoundMute
        },
        incomingMessages: {
          key: messageSoundKey,
          volume: messageSoundVolume,
          isMuted: messageSoundMute
        }
      }
    };
    dispatch(updateSoundProfile(AllData, usertoken, onSuccess))
  }
  const onSuccess = (msg) => {
    if (msg) {
      message.success(`${msg}`);
    }
  };
  return (
    <div className="soundTabs addShortcut">
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <h6>Notifications</h6>
          <p>
            Set your notification preferences for when you are in or away from
            the dashboard. You will need to configure your browser settings to
            allow notifications from ghostwritingprofessionals.zendesk.com.
          </p>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <h6>Sounds</h6>
          <div className="soundList">
            <p>Incoming Visitor</p>
            <div className="volume">
              <Select
                placeholder="Select Sound"
                style={{
                  width: 180,
                }}
                // value={incomingVisitorKey}
                onChange={handleChangeIncoming}
                options={visitorSounds}
                defaultValue={userData?.sounds?.incomingVisitor?.key.toString()}
              />
              <div className="tabvol">
                <SoundComponent muted={incomingMute} volume={incomingVolume} setMuted={setincomingMute} setVolume={setincomingVolume} soundUrl={incomingVisitor === null ? visitorSounds?.[userData?.sounds?.incomingVisitor?.key]?.sound : incomingVisitor} />
              </div>
            </div>
          </div>
          <div className="soundList">
            <p>Chat Request</p>
            <div className="volume">
              <Select
                defaultValue={userData?.sounds?.chatRequest?.key.toString()}
                placeholder="Select Sound"
                style={{
                  width: 180,
                }}
                // value={RequestSoundKey}
                onChange={handleChangeRequset}
                options={incomingMessage}
              />
              <div className="tabvol">
                <SoundComponent muted={RequestSoundMute} volume={RequestSoundVolume} setMuted={setRequestSoundMute} setVolume={setRequestSoundVolume} soundUrl={RequestSound === null ? incomingMessage?.[userData?.sounds?.chatRequest?.key]?.sound : RequestSound} />
              </div>
            </div>
          </div>
          <div style={{ marginBottom: 20 }} className="soundList">
            <p>Incoming Messages</p>
            <div className="volume">
              <Select
                defaultValue={userData?.sounds?.incomingMessages?.key.toString()}
                placeholder="Select Sound"
                style={{
                  width: 180,
                }}
                // value={messageSoundKey}
                onChange={handleChangeMessage}
                options={incomingMessage}
              />
              <div className="tabvol">
                <SoundComponent muted={messageSoundMute} volume={messageSoundVolume} setMuted={setmessageSoundMute} setVolume={setmessageSoundVolume} soundUrl={messageSound === null ? incomingMessage?.[userData?.sounds?.incomingMessages?.key]?.sound : messageSound} />
              </div>
            </div>
          </div>
          <div className="bodyFooter">
            <Button type="primary" onClick={() => soundSubmit()}>
              {soundLoading ? <Spin indicator={antIcon} style={{ padding: "0px 17px 0px 17px" }} /> : "Save Changes"}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
const ChangePassword = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.Auth.userData);
  const usertoken = useSelector((state) => state.Auth.token);

  const [form] = Form.useForm();

  const onSuccess = (msg) => {
    if (msg) {
      message.success(`${msg}`);
      form.resetFields();
    }
  };

  const onFinish = (values) => {
    if (values?.newPassword !== values?.confirmPassword) {
      message.error('Password does not matched');
    } else {
      let data = {
        _id: userData?._id,
        old_password: values?.oldPassword,
        new_password: values?.newPassword,
      }
      dispatch(changePasswordProfile(data, usertoken, onSuccess))
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  return (
    <div className="profileTabs">
      <Row className="addShortcut">
        <Col span={12}>
          <h6>Change Password</h6>
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
          >
            <Form.Item
              label="Old Password"
              name="oldPassword"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>


            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};
// const Tab3 = () => {
//   const onChange = (checked) => {
//  
//   };
//   const [value, setValue] = useState(1);
//   const onChangeRadio = (e) => {
//    
//     setValue(e.target.value);
//   };
//   return (
//     <div className="idleTabs addShortcut">
//       <Row>
//         <Col xs={24} sm={24} md={12} lg={12} xl={12}>
//           <div className="header">
//             <h6>Idel timeout</h6>
//             <Switch defaultChecked onChange={onChange} />
//           </div>
//           <p>
//             Set your status to Away or invisible after a period on inactivity
//           </p>
//           <div className="timeList">
//             <p>Ignore if chatting</p>
//             <Checkbox onChange={onChange}>
//               Do not change my status if i have open chat window
//             </Checkbox>
//           </div>
//           <div className="timeList">
//             <p>Inactivity period</p>
//             <div className="timer">
//               <p>Time (in minute) before going Away or invisible.</p>
//               <InputNumber
//                 min={1}
//                 max={10}
//                 defaultValue={3}
//                 onChangex={onChange}
//               />
//             </div>
//           </div>
//           <div className="timeList">
//             <p>Idle status</p>
//             <Radio.Group onChange={onChangeRadio} value={value}>
//               <Space direction="vertical">
//                 <Radio value={1}>Away</Radio>
//                 <Radio value={2}>Invisible</Radio>
//               </Space>
//             </Radio.Group>{" "}
//           </div>
//           <div className="bodyFooter">
//             <Button type="primary" htmlType="submit">
//               Save Changes
//             </Button>
//             <Button type="primary" htmlType="submit" className="revertbtn">
//               Revert Cancle
//             </Button>
//           </div>
//         </Col>
//       </Row>
//     </div>
//   );
// };
export default Personal;
