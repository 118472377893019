import * as actionTypes from "./AgentsTypes";
import { Post } from "../../helpers/apicalls/apicalls";

export const Add_Users = (data, token, afterSuccess, setLoading, setError) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_LOADING });
    Post("/agents/user/register", data, token)
      .then(function (response) {
        if (response.status) {
          dispatch({
            type: actionTypes.AGENT_DATA_SUCCESS,
            payload: response?.data
          });
          afterSuccess();
          setLoading(false);
        } else {
          setError(response?.message);
          dispatch({ type: actionTypes.AGENT_DATA_FAILED });
          setLoading(false);
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.AGENT_DATA_FAILED });
        setLoading(false);
      });
  };
};
