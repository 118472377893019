import React from 'react';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

// Register required components
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const BarChart = ({ AnalyticsData }) => {
  // Extract keys and values from AnalyticsData
  const allKeys = AnalyticsData?.TotalVisits?.length > 0
    ? Object.keys(AnalyticsData.TotalVisits[0])
    : ['There is no Data Currently'];

  const allValues = AnalyticsData?.TotalVisits?.length > 0
    ? Object.values(AnalyticsData.TotalVisits[0])
    : [0];

  const allChatKeys = AnalyticsData?.TotalChats?.length > 0
    ? Object.keys(AnalyticsData.TotalChats[0])
    : ['There is no Data Currently'];

  const allChatValues = AnalyticsData?.TotalChats?.length > 0
    ? Object.values(AnalyticsData.TotalChats[0])
    : [0];

  const data = {
    labels: allKeys,
    datasets: [
      {
        label: 'Visits',
        data: allValues,
        backgroundColor: 'rgba(54, 162, 235, 1)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 2,
        order: 1,
      },
      {
        label: 'Chats',
        type: 'line',
        data: allChatValues,
        borderColor: 'rgba(53, 96, 100, 1)',
        backgroundColor: 'rgba(53, 96, 100, 1)',
        order: 0,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows the canvas to grow with its container
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (

    <div style={{ display: "flex", justifyContent: "center", alignItems: "end", height: 410 }}>
      <Bar data={data} options={options} />
    </div>

  );
};

export default BarChart;
