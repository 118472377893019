import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Checkbox, Col, Row, Spin, message, Select, } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import loginBack from "../../../assets/image/login-back.png";
import logo from "../../../assets/image/main-logo.png";
import { useDispatch, useSelector } from "react-redux";
import { SignUp_Action, get_Plan_Types, get_Packegs, packageSubcription } from '../../../redux/Auth/AuthActions';
import { useLocation } from 'react-router-dom';
import PaymentModal from '../../../components/paymentModal';


export default function Packeges() {
    const packegsData = useSelector((state) => state.Auth.packegsData);
    const PlanData = useSelector((state) => state.Auth.PlanData);
    const isLoadingPackagesSub = useSelector((state) => state.Auth.isLoadingPackagesSub);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [planArr, setplanArr] = useState([]);
    const [CurrentData, setCurrentData] = useState(null);
    const [planID, setplanID] = useState(null);
    const isLoading = useSelector((state) => state.Auth.isLoadingPlans);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(get_Plan_Types(location?.state?.data?.token));
    }, []);

    useEffect(() => {
        if (PlanData?.length > 0) {
            let newArr = PlanData?.map((x) => {
                return {
                    value: x?._id, label: x?.title
                }
            });
            setplanArr(newArr);
        }
    }, [PlanData]);

    useEffect(() => {
        if (planID !== null) {
            let data = {
                planTypeId: planID
            }
            dispatch(get_Packegs(data));
        }
        else {
            let data = {
                planTypeId: PlanData?.[0]?._id
            }
            dispatch(get_Packegs(data));
        }
    }, [PlanData, planID]);

    const onFinish = (item) => {
        if (item?.price == 0) {
            let pkgData = {
                companyId: location?.state?.data?.data?.companyId,
                packageId: item?._id,
                userId: location?.state?.data?.data?._id
            }
            dispatch(packageSubcription(pkgData, location?.state));
        } else {
            setCurrentData(item);
            setIsModalOpen(true)
        }
    };
    const handleChange = (e) => {
        setplanID(e);
    };


    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 20,
                color: "#ffffff",
                margin: "0px 25px",
            }}
            spin
        />
    );
    return (
        <>
            <PaymentModal userData={location?.state} CurrentData={CurrentData} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
            {isLoading ? <p>...loading</p>
                :
                <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    className="loginForm"
                    style={{ minHeight: "100vh", backgroundImage: `url(${loginBack})` }}
                >
                    <Col span={20} className="packegsContiner">
                        <div className='packegsheader'>
                            <img style={{ height: 60, width: 370 }} src={logo} />
                            <h1 style={{ color: '#F26128' }}>
                                Gives You The Best Plan For Your Websites
                            </h1>
                            <p style={{ marginTop: -5 }}>Get started for free and choose the right plan of increasing your revenue</p>
                        </div>
                        <Row style={{ paddingBottom: 20, }}>
                            <Col className='packegsInfo-box' span={4.8}>
                                <div style={{ display: "flex", width: 150, flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                                    <p className='packegsInfo-sec'>Select Plan</p>
                                    <Select
                                        defaultValue={PlanData?.[0]?._id}
                                        style={{ width: 150 }}
                                        onChange={handleChange}
                                        options={planArr}
                                    />
                                    <div>
                                        <p style={{ fontWeight: "700", fontSize: 20 }}>Fast, Secure and Affordable</p>
                                    </div>
                                </div>
                            </Col>
                            {packegsData?.map((x, index) => {
                                return <Col key={index} className='packegsInfo' span={4.8} >
                                    <div className='packegsDetails'>
                                        <h4 className='packegsInfo-h4'>{x?.title}</h4>
                                        <p className='packegsInfo-sec'>{x?.subTitle}</p>
                                        <p className='packegsInfo-trail'>{x?.duration} Days account</p>
                                        <h3 className='packegsInfo-dollors'>${x?.price}</h3>
                                        <h5 className='packegsInfo-trail'>{x?.userCount} Agents</h5>
                                        <div className='btnscribe'><Button disabled={isLoadingPackagesSub} onClick={() => onFinish(x)} type="primary"> {isLoadingPackagesSub ? <Spin indicator={antIcon} /> : "Subscribe"}</Button></div>
                                    </div>
                                </Col>
                            })}
                        </Row>
                    </Col>

                </Row>}

        </>
    )
}
