import * as actionTypes from "./AuthTypes";
import { Post, Get } from "../../helpers/apicalls/apicalls";
import { message } from "antd";

export const Login_Action = (data, error, rememberMe, navigate) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_LOADING });
    Post("/agents/user/auth/login", data, false)
      .then(function (response) {
        if (response?.status) {
          dispatch({
            type: actionTypes.USER_DATA_SUCCESS,
            payload: response?.data
          });

          dispatch({
            type: actionTypes.USER_TOKEN,
            payload: response?.token
          });
          if (rememberMe) {
            localStorage.setItem(
              "reduxPersist:root",
              JSON.stringify({ data: response?.data, token: response?.token })
            );
          } else {
            sessionStorage.setItem(
              "reduxPersist:root",
              JSON.stringify({ data: response?.data, token: response?.token })
            );
          }
        } else {
          dispatch({ type: actionTypes.USER_DATA_FALED });
          if (response?.message === "Please Subscribe a Package!") {
            navigate("/pricing-plan", { state: { data: response, rememberMe: rememberMe } });
          }
          error(response?.message);
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.USER_DATA_FALED });
      });
  };
};

export const get_Plan_Types = (token) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.PLAN_TYPE_LOADING_PASSWORD });
    Get("/agents/user/auth/get-plan-types", token)
      .then(function (response) {
        if (response?.status) {
          dispatch({
            type: actionTypes.PLAN_TYPE_SUCCESS,
            payload: response?.data
          });
        } else {
          dispatch({ type: actionTypes.PLAN_TYPE_FALED });
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.PLAN_TYPE_FALED });
      });
  };
};
// router.get('/get-plan-types', authChecker, getPlanTypes)  

export const get_Packegs = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.PACKEGS_LOADING_PASSWORD });
    Post("/agents/user/get-packages", data, false)
      .then(function (response) {
        if (response?.status) {
          dispatch({
            type: actionTypes.PACKEGS_SUCCESS,
            payload: response?.data
          });
        } else {
          dispatch({ type: actionTypes.PACKEGS_FALED });
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.PACKEGS_FALED });
      });
  };
};

export const ForgetPassword_Action = (data, error, success) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_LOADING_PASSWORD });
    Post("/agents/user/auth/forget-password", data, false)
      .then(function (response) {
        if (response?.status) {
          dispatch({
            type: actionTypes._PASSWORD_SUCCESS,
            payload: response?.data
          });
          success(response?.message);
        } else {
          dispatch({ type: actionTypes._PASSWORD_FALED });
          error(response?.message);
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes._PASSWORD_FALED });
      });
  };
};

export const SignUp_Action = (data, rememberMe, navigate) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_LOADING });
    Post("/agents/user/auth/signup", data, false)
      .then((res) => {
        if (res?.status) {
          navigate("/pricing-plan", { state: { data: res, rememberMe: rememberMe } });
          dispatch({ type: actionTypes.USER_DATA_FALED });
          // dispatch({
          //   type: actionTypes.USER_DATA_SUCCESS,
          //   payload: res?.data
          // });
          // dispatch({
          //   type: actionTypes.USER_TOKEN,
          //   payload: res?.token
          // });
          // if (rememberMe) {
          //   localStorage.setItem(
          //     "reduxPersist:root",
          //     JSON.stringify({ data: res?.data, token: res?.token })
          //   );
          // } else {
          //   sessionStorage.setItem(
          //     "reduxPersist:root",
          //     JSON.stringify({ data: res?.data, token: res?.token })
          //   );
          // }
        } else {
          dispatch({ type: actionTypes.USER_DATA_FALED });
          message.error(res?.message);
        }
      })
      .catch((err) => {
        dispatch({ type: actionTypes.USER_DATA_FALED });
      });
  };
};

export const packageSubcription = (data, state) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.PACKAGES_SUB_LOADING });
    Post("/agents/user/auth/package/subcription", data, false)
      .then(function (response) {
        if (response?.status) {
          message.success(response?.message);
          dispatch({
            type: actionTypes.PACKAGES_SUB_SUCCESS,
            payload: response?.data
          });
          dispatch({
            type: actionTypes.USER_DATA_SUCCESS,
            payload: state?.data?.data
          });
          dispatch({
            type: actionTypes.USER_TOKEN,
            payload: state?.data?.token
          });
          if (state?.rememberMe) {
            localStorage.setItem(
              "reduxPersist:root",
              JSON.stringify({ data: state?.data?.data, token: state?.data?.token })
            );
          } else {
            sessionStorage.setItem(
              "reduxPersist:root",
              JSON.stringify({ data: state?.data?.data, token: state?.data?.token })
            );
          }
        } else {
          dispatch({ type: actionTypes.PACKAGES_SUB_FALED });
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.PACKAGES_SUB_FALED });
      });
  };
};
