import {
  TeamOutlined,
  InboxOutlined,
  AppstoreOutlined,
  ClockCircleOutlined,
  HomeOutlined,
  SettingOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
  AreaChartOutlined,
} from "@ant-design/icons";
import { Badge } from "antd";

export const AdminSideBar = (
  navigate,
  FiltredUers,
  setselectedKey,
  isReadData
) => [
  {
    key: "/",
    icon: <HomeOutlined />,
    label: "Home",
    onClick: () => {
      setselectedKey("/");
      navigate("/");
    },
  },
  {
    key: "/analytics",
    icon: <AreaChartOutlined />,
    label: "Analytics",
    onClick: () => {
      setselectedKey("/analytics");
      navigate("/analytics");
    },
  },
  {
    key: "/visitors",
    icon: <UserSwitchOutlined />,
    label: "Visitors",
    onClick: () => {
      setselectedKey("/visitors");
      navigate("/visitors");
    },
  },
  {
    key: "/history",
    icon: <ClockCircleOutlined />,
    label: "History",
    onClick: () => {
      setselectedKey("/history");
      navigate("/history");
    },
  },
  {
    key: "/brands",
    icon: <AppstoreOutlined />,
    label: "Brands",
    onClick: () => {
      setselectedKey("/brands");
      navigate("/brands");
    },
  },
  {
    key: "/plans-packages",
    icon: <AppstoreOutlined />,
    label: "Plans & Packages",
    onClick: () => {
      setselectedKey("/plans-packages");
      navigate("/plans-packages");
    },
  },
  {
    itemIcon:
      isReadData?.length > 0 ? (
        <Badge
          size={"small"}
          count={isReadData?.length > 0 ? isReadData?.length : 0}
          showZero
          color="#faad14"
        />
      ) : (
        ""
      ),
    key: "/tickets",
    icon: (
      <>
        <InboxOutlined />{" "}
      </>
    ),
    label: `Tickets`,
    onClick: () => {
      navigate("/tickets");
      setselectedKey("/tickets");
    },
  },
  {
    key: "/users",
    icon: <TeamOutlined />,
    label: "Users",
    onClick: () => {
      setselectedKey("/users");
      navigate("/users");
    },
  },
  {
    key: "/settings",
    icon: <SettingOutlined />,
    label: "Settings",
    children: [
      {
        key: "/shortcuts",
        label: "Shortcuts",
        onClick: () => {
          setselectedKey("/shortcuts");
          navigate("/shortcuts");
        },
      },
      {
        key: "/widget",
        label: "Widget",
        onClick: () => {
          setselectedKey("/widget");
          navigate("/widget");
        },
      },
      // {
      //   key: "/offlineStatus",
      //   label: "Offline-Status",
      //   onClick: () => {
      //     navigate("/offlineStatus");
      //   },
      // },
      {
        key: "/banned",
        label: "Banned",
        onClick: () => {
          setselectedKey("/banned");
          navigate("/banned");
        },
      },
      {
        key: "/personal",
        label: "Personal",
        onClick: () => {
          setselectedKey("/personal");
          navigate("/personal");
        },
      },
      // {
      //   key: "/triggers",
      //   label: "Triggers",
      //   onClick: () => {
      //     navigate("/triggers");
      //   },
      // },
      {
        key: "/departments",
        label: "Departments",
        onClick: () => {
          setselectedKey("/departments");
          navigate("/departments");
        },
      },
    ],
  },
  {
    key: "14",
    icon: <UsergroupAddOutlined />,
    label: "My Team",
    children: FiltredUers?.map((i, index) => {
      return (
        i?.displayName != "superadmin" && {
          key: index + 15,
          label: i?.displayName,
          icon:
            i?.status == 22 ? (
              <Badge size={"small"} showZero color="green" />
            ) : (
              <Badge size={"small"} showZero color="orange" />
            ),
          onClick: () => {
            navigate("/users", { state: { user_id: i?.key } });
            setselectedKey("/users");
          },
        }
      );
    }),
  },
];

export const UserSideBar = (
  navigate,
  FiltredUers,
  setselectedKey,
  isReadData
) => [
  {
    key: "/",
    icon: <HomeOutlined />,
    label: "Home",
    onClick: () => {
      navigate("/");
      setselectedKey("/");
    },
  },
  {
    key: "/analytics",
    icon: <AreaChartOutlined />,
    label: "Analytics",
    onClick: () => {
      setselectedKey("/analytics");
      navigate("/analytics");
    },
  },
  {
    key: "/visitors",
    icon: <UserSwitchOutlined />,
    label: "Visitors",
    onClick: () => {
      setselectedKey("/visitors");
      navigate("/visitors");
    },
  },
  {
    // itemIcon: isReadData?.length > 0 ? <Badge size={"small"} count={isReadData?.length > 0 ? isReadData?.length : 0} showZero color='#faad14' /> : "",
    key: "/history",
    icon: <ClockCircleOutlined />,
    label: "History",
    onClick: () => {
      setselectedKey("/history");
      navigate("/history");
    },
  },
  {
    key: "/brands",
    icon: <AppstoreOutlined />,
    label: "Brands",
    onClick: () => {
      setselectedKey("/brands");
      navigate("/brands");
    },
  },
  // {
  //   key: "/packages",
  //   icon: <AppstoreOutlined />,
  //   label: "Packages",
  //   onClick: () => {
  //     navigate("/packages");
  //   },
  // },
  {
    itemIcon:
      isReadData?.length > 0 ? (
        <Badge
          size={"small"}
          count={isReadData?.length > 0 ? isReadData?.length : 0}
          showZero
          color="#faad14"
        />
      ) : (
        ""
      ),
    key: "/tickets",
    icon: (
      <>
        <InboxOutlined />{" "}
      </>
    ),
    label: `Tickets`,
    onClick: () => {
      navigate("/tickets");
      setselectedKey("/tickets");
    },
  },
  {
    key: "/users",
    icon: <TeamOutlined />,
    label: "Users",
    onClick: () => {
      setselectedKey("/users");
      navigate("/users");
    },
  },
  {
    key: "/settings",
    icon: <SettingOutlined />,
    label: "Settings",
    children: [
      {
        key: "/shortcuts",
        label: "Shortcuts",
        onClick: () => {
          setselectedKey("/shortcuts");
          navigate("/shortcuts");
        },
      },
      {
        key: "/widget",
        label: "Widget",
        onClick: () => {
          setselectedKey("/widget");
          navigate("/widget");
        },
      },
      // {
      //   key: "/offlineStatus",
      //   label: "Offline-Status",
      //   onClick: () => {
      //     navigate("/offlineStatus");
      //   },
      // },
      {
        key: "/banned",
        label: "Banned",
        onClick: () => {
          setselectedKey("/banned");
          navigate("/banned");
        },
      },
      {
        key: "/personal",
        label: "Personal",
        onClick: () => {
          setselectedKey("/personal");
          navigate("/personal");
        },
      },
      // {
      //   key: "/triggers",
      //   label: "Triggers",
      //   onClick: () => {
      //     navigate("/triggers");
      //   },
      // },
      {
        key: "/departments",
        label: "Departments",
        onClick: () => {
          setselectedKey("/departments");
          navigate("/departments");
        },
      },
    ],
  },
  {
    key: "14",
    icon: <UsergroupAddOutlined />,
    label: "My Team",
    children: FiltredUers?.map((i, index) => {
      return (
        i?.displayName != "superadmin" && {
          key: index + 15,
          label: i?.displayName,
          icon:
            i?.status == 22 ? (
              <Badge size={"small"} showZero color="green" />
            ) : (
              <Badge size={"small"} showZero color="orange" />
            ),
          onClick: () => {
            setselectedKey("/users");
            navigate("/users", { state: { user_id: i?.key } });
          },
        }
      );
    }),
  },
];
