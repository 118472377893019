import { Breadcrumb, Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveDepartments, getDepartmentUsers } from '../../redux/Departments/DepartmentsActions';
import moment from 'moment';
import dayjs from 'dayjs';

const CreateTicket = () => {
    const dispatch = useDispatch();
    const userToken = useSelector((state) => state.Auth.token);
    const departments = useSelector((state) => state.Departments.activeDepartment);
    const departmentUsers = useSelector((state) => state.Departments.departmentUsers);
    const [startDate, setStartDate] = useState(dayjs());
    const [dueDate, setDueDate] = useState(null);
    const { Option, OptGroup } = Select;
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 2 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 22 },
        },
    }

    useEffect(() => {
        getDepartments();
    }, []);

    const getDepartments = () => {
        dispatch(getActiveDepartments(userToken));
    }

    const changeStartDateHandler = (date) => {
        setStartDate(date);
    }

    const changeDueDateHandler = (date) => {
        setDueDate(date);
    }

    const onDepartmentChangeHandler = (departId) => {
        const data = {
            departId: departId
        };

        dispatch(getDepartmentUsers(data, userToken))
    }

    return (
        <>
            <Breadcrumb className="breadCrumb" items={[{ title: "Tickets" }, { title: "Create Ticket" }]} />
            <Row className="addShortcut">
                <Col span={24}>
                    <div>
                        <Form labelCol={{ span: 2 }} layout="horizontal" className="ticketForm">
                            <Form.Item wrapperCol={{ span: 4 }} label="Tracker" hasFeedback>
                                <Select defaultValue={'Bug'}>
                                    <Option value={'Bug'}>Bug</Option>
                                    <Option value={'Feature'}>Feature</Option>
                                    <Option value={'Support'}>Support</Option>
                                    <Option value={'Incident'}>Incident</Option>
                                    <Option value={'Testing'}>Testing</Option>
                                    <Option value={'Implementation'}>Implementation</Option>
                                    <Option value={'Research Work'}>Research Work</Option>
                                    <Option value={'Reasearch & Testing'}>Reasearch & Testing</Option>
                                    <Option value={'Service Request'}>Service Request</Option>
                                    <Option value={'Access Request'}>Access Request</Option>
                                    <Option value={'Change Request'}>Change Request</Option>
                                    <Option value={'Approval Request'}>Approval Request</Option>
                                    <Option value={'Activity'}>Activity</Option>
                                    <Option value={'Task'}>Task</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item wrapperCol={{ span: 24 }} label="Subject" hasFeedback>
                                <Input name="subject" id="subject" />
                            </Form.Item>
                            <Form.Item wrapperCol={{ span: 24 }} label="Description" hasFeedback>
                                <TextArea name="description" id="description" rows={4} />
                            </Form.Item>
                            <Row>
                                <Col span={12}>
                                    <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} label="Status" hasFeedback>
                                        <Select defaultValue={'New'}>
                                            <Option value={'New'}>New</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} label="Priority" hasFeedback>
                                        <Select defaultValue={'Normal'}>
                                            <Option value={'Low'}>Low</Option>
                                            <Option value={'Normal'}>Normal</Option>
                                            <Option value={'High'}>High</Option>
                                            <Option value={'Urgent'}>Urgent</Option>
                                            <Option value={'Immediete'}>Immediete</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} label="Department" hasFeedback>
                                        <Select defaultValue={''} onChange={onDepartmentChangeHandler}>
                                            {departments.length > 0 ? departments.map((department, index) => {
                                                return (
                                                    <Option value={department._id} key={index}>{department.name}</Option>
                                                )
                                            }) : null}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} label="Assignee" hasFeedback>
                                        <Select defaultValue={''}>
                                            {departmentUsers.length > 0 ? departmentUsers.map((departmentUser, index) => {
                                                return (
                                                    <Option value={departmentUser._id} key={index}>{departmentUser.name}</Option>
                                                )
                                            }) : null}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item labelCol={{ span: 10 }} wrapperCol={{ span: 4 }} label="Start Date" hasFeedback>
                                        <DatePicker
                                            format={'MM-DD-YYYY'}
                                            placeholder='MM-DD-YYYY'
                                            value={startDate}
                                            onChange={changeStartDateHandler}
                                        />
                                    </Form.Item>
                                    <Form.Item labelCol={{ span: 10 }} wrapperCol={{ span: 4 }} label="Due Date" hasFeedback>
                                        <DatePicker
                                            format={'MM-DD-YYYY'}
                                            placeholder='MM-DD-YYYY'
                                            value={dueDate}
                                            onChange={changeDueDateHandler}
                                        />
                                    </Form.Item>
                                    <Form.Item labelCol={{ span: 10 }} wrapperCol={{ span: 4 }} label="Estimated Time" hasFeedback>
                                        <Input name="estimatedTime" id="estimatedTime" />
                                        <span className="hoursSpan">Hours</span>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Button>Save</Button>
                        </Form>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default CreateTicket;
