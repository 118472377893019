import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import bird from "../assets/image/bird.png"

export const fileChanger = (file, filetype) => {
    const binaryString = file !== null && atob(file);
    const binaryData = new Uint8Array(binaryString?.length);
    for (let i = 0; i < binaryString?.length; i++) {
        binaryData[i] = binaryString?.charCodeAt(i);
    }
    const blob = new Blob([binaryData], { type: filetype });
    return blob;
};

export const downloadBufferFile = (bufferObj, filename, filetype) => {
    try {
        // Ensure bufferObj is in the expected format
        if (!bufferObj || bufferObj.type !== 'Buffer' || !Array.isArray(bufferObj.data)) {
            throw new Error("Invalid buffer object format");
        }
        // Convert the data array to a Uint8Array
        const uint8Array = new Uint8Array(bufferObj.data);

        // Create a Blob with the specified filetype
        const blob = new Blob([uint8Array], { type: filetype });
        return blob;

    } catch (error) {
        console.error("Error downloading file: ", error.message);
    }
};

export const downloadBufferFileWithType = (bufferObj, filename, filetype) => {
    try {
        // Convert the data array to a Uint8Array
        const uint8Array = new Uint8Array(bufferObj.data);

        // Create a Blob with the specified filetype
        const blob = new Blob([uint8Array], { type: filetype });
        return blob;

    } catch (error) {
        console.error("Error downloading file: ", error.message);
    }
};


export function arrayBufferToBase64(arrayBuffer) {
    const uint8Array = new Uint8Array(arrayBuffer);
    const binaryString = uint8Array.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
    return btoa(binaryString);
}

export const showNotification = (senderId, message) => {
    var options = {
        body: message,
        icon: bird,
        dir: 'ltr',
    };
    new Notification(senderId, options);
}