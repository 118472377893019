import { Alert, Breadcrumb, Button, Checkbox, Col, Form, Input, Row, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createPlan } from '../../redux/Packages/PackagesActions';

const CreatePlan = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const usertoken = useSelector((state) => state.Auth.token);
    const [form] = Form.useForm();
    const submitHandler = (values) => {
        dispatch(createPlan(values, usertoken, navigateAfterSuccess));
    }

    const navigateAfterSuccess = () => {
        message.success('Successfully Created');
        navigate('/plans-packages');
    }

    return (
        <>
            <Breadcrumb className="breadCrumb" items={[{ title: "Plans & Packages" }, { title: "Create Plan" }]} />
            <Row className="addShortcut">
                <Col span={24}>
                    <div>
                        <Form
                            form={form}
                            name="control-hooks"
                            labelCol={{ span: 3 }}
                            layout="horizontal"
                            className="ticketForm"
                            onFinish={submitHandler}
                        >
                            <Form.Item
                                name="title"
                                wrapperCol={{ span: 8 }}
                                label="Title"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter plan title!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="duration"
                                wrapperCol={{ span: 8 }}
                                label="Duration (In Days)"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter plan duration!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <div className="topbtn">
                                <Button className="back-btn" onClick={() => navigate('/plans-packages')}>Back</Button>
                                <Button type="primary" className="align-right" htmlType="submit">Save</Button>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row >
        </>
    );
}

export default CreatePlan;
