import React, { useEffect, useState, useCallback, useContext } from 'react';
import BarChart from '../../components/charts/BarChart';
import { LineArea } from '../../components/charts/LineArea';
import { Col, DatePicker, Row, Select } from 'antd';
import { PieChart } from '../../components/charts/PieChart';
import LineChart from '../../components/charts/LineChart';
import { Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Get_Analtyics } from '../../redux/Analytics/AnalyticsActions';
import PolarChart from '../../components/charts/PolarChart';
import { AlertOutlined, DisconnectOutlined, RiseOutlined, UserAddOutlined, UserSwitchOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { SocketContext } from '../../context/socketContext';
const { RangePicker } = DatePicker;

const Analytics = () => {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.Auth.userData);
    const usertoken = useSelector((state) => state.Auth.token);
    const { VisitorData, FiltredUers } =
        useContext(SocketContext);
    const ActiveVisitors = VisitorData?.filter(
        (i) => i?.data?.isIdealState == false && i?.servedBy?.length == 0
    );
    const ServedVisitors = VisitorData?.filter(
        (i) => i?.data?.isIdealState == false && i?.servedBy?.length > 0
    );
    const AnalyticsData = useSelector((state) => state.AnalyticsReducer.AnalyticsData);

    const totalCount = AnalyticsData?.TicketsCount?.result?.reduce((acc, obj) => acc + (obj.count || 0), 0);

    const [SelectedValue, setSelectedValue] = useState(userData?.assignBrands[0]?.token || null);
    const [dateRange, setDateRange] = useState([null, null]);

    const BrandName = userData?.assignBrands?.map((i) => ({
        value: i?.token,
        label: i?.brandname,
    }));

    const fetchAnalyticsData = useCallback(() => {
        const [startDate, endDate] = dateRange || [null, null];
        const data = {
            token: SelectedValue,
            startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
            endDate: endDate ? endDate.format('YYYY-MM-DD') : null,
        };
        dispatch(Get_Analtyics(data, usertoken));
    }, [SelectedValue, dateRange, dispatch, usertoken]);


    useEffect(() => {
        if (SelectedValue) {
            fetchAnalyticsData();
        }
    }, [fetchAnalyticsData]);

    const handleChange = (value) => {
        setSelectedValue(value);
    };

    const onDateChange = (dates) => {
        setDateRange(dates);
    };
    const disabledDate = (current) => {
        return current && current > Date.now();
    };

    return (
        <>
            <div className="historytopbtn">
                <div>
                    <Select
                        defaultValue={SelectedValue}
                        style={{ width: 170, marginRight: 20, marginTop: 5 }}
                        onChange={handleChange}
                        options={BrandName}
                    />
                    <RangePicker format="DD-MM-YYYY" onChange={onDateChange} disabledDate={disabledDate} />
                </div>
            </div>

            {AnalyticsData && (
                <div>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 12 }}>
                        <Col span={6}>
                            <Card className='dashboardCard'>
                                <div className='box'>
                                    <div className='boxContent'>
                                        <h3>Active Visitors</h3>
                                        <h2>{ActiveVisitors?.length}</h2>
                                        {/* <div className='boxFooter'>
                                            <div className='arrows'>
                                                <RiseOutlined />
                                            </div>
                                            <p className='arrowpara'>40% </p>
                                            <p>vs last month</p>
                                        </div> */}
                                    </div>
                                    <div className='boxIcon'>
                                        <UserAddOutlined style={{ fontSize: '28px' }} />
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col span={6} style={{ paddingRight: '8px' }}>
                            <Card className='dashboardCard served'>
                                <div className='box'>
                                    <div className='boxContent'>
                                        <h3>Served</h3>
                                        <h2>{ServedVisitors?.length}</h2>
                                    </div>
                                    <div className='boxIcon'>
                                        <UserSwitchOutlined style={{ fontSize: '28px' }} />
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col span={6} style={{ paddingLeft: '0px' }}>
                            <Card className='dashboardCard ticket'>
                                <div className='box'>
                                    <div className='boxContent'>
                                        <h3>Ticket Counts</h3>
                                        <h2>{totalCount}</h2>
                                    </div>
                                    <div className='boxIcon'>
                                        <AlertOutlined style={{ fontSize: '28px' }} />
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card className='dashboardCard agent'>
                                <div className='box'>
                                    <div className='boxContent'>
                                        <h3>Agent Online</h3>
                                        <h2>{FiltredUers?.length}</h2>
                                    </div>
                                    <div className='boxIcon'>
                                        <UsergroupAddOutlined style={{ fontSize: '28px' }} />
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Card
                                title="Total Visits"
                                headStyle={{ textAlign: 'center', fontSize: '17px', fontWeight: 600, letterSpacing: '1px' }}
                                style={{ width: '100%', margin: '5px 0px' }}
                            >
                                <BarChart AnalyticsData={AnalyticsData} />
                            </Card>
                        </Col>

                    </Row>
                    <Row>
                        <Col span={24}>
                            <Card
                                title="Total WebPages Visits"
                                headStyle={{ textAlign: 'center', fontSize: '17px', fontWeight: 600, letterSpacing: '1px' }}
                                style={{ width: '100%', height: '98%', margin: '5px 0px' }}
                            >
                                <LineArea AnalyticsData={AnalyticsData} style={{ width: '100%' }} className="line-area-chart" />
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Card
                                title="Total Referrers"
                                headStyle={{ textAlign: 'center', fontSize: '17px', fontWeight: 600, letterSpacing: '1px' }}
                                style={{ width: '100%', margin: '5px 0px' }}
                            >
                                <PolarChart AnalyticsData={AnalyticsData} />
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card
                                title="Ticket Status"
                                headStyle={{ textAlign: 'center', fontSize: '17px', fontWeight: 600, letterSpacing: '1px' }}
                                style={{ width: '100%', margin: '5px 0px' }}
                            >
                                <PieChart AnalyticsData={AnalyticsData} />
                            </Card>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col span={24}>
                            <Card
                                title="Total Chats"
                                headStyle={{ textAlign: 'center' }}
                                style={{ width: '99%', margin: '5px 0px' }}
                            >
                                <LineChart AnalyticsData={AnalyticsData} />
                            </Card>
                        </Col>
                    </Row> */}
                </div >
            )}
        </>
    );
};

export default Analytics;
