import loud from '../assets/sound/incomingvisitor/loud.mp3'
import bell from '../assets/sound/incomingvisitor/bell.wav'
import notification1 from "../assets/sound/incomingvisitor/notification_tone_01.mp3";
import clock from "../assets/sound/incomingvisitor/clock-alarm.mp3";
import pew_loud from "../assets/sound/incomingvisitor/pew_loud.mp3";
import success from "../assets/sound/incomingvisitor/success-fanfare-trumpets.mp3"

////incomingMessage
import relaxmessagetone from "../assets/sound/message/relaxmessagetone.mp3";
import ididitmessagetone from "../assets/sound/message/ididitmessagetone.mp3";
import noproblem from "../assets/sound/message/noproblem.mp3";
import click from "../assets/sound/message/click.mp3";
import game from "../assets/sound/message/notification-for-game-scenes.mp3";
import short from "../assets/sound/message/short-success-sound-glockenspiel-treasure-video-game.mp3";
import wrong from "../assets/sound/message/wrong-answer.mp3";


export const visitorSounds = [
    {
        value: '0',
        label: 'Loud',
        sound: loud,
    }, {
        value: '1',
        label: 'Bell',
        sound: bell
    }, {
        value: '2',
        label: 'Notification',
        sound: notification1
    }, {
        value: '3',
        label: 'Clock-alarm',
        sound: clock

    }, {
        value: '4',
        label: 'Pew-loud',
        sound: pew_loud
    }, {
        value: '5',
        label: 'Success-fanfare',
        sound: success

    }
];

export const incomingMessage = [
    {
        value: "0",
        sound: relaxmessagetone,
        key: "0",
        label: "Relax-message-tone"
    }, {
        value: "1",
        sound: ididitmessagetone,
        key: "1",
        label: "I-did-it-tone"
    }, {
        value: "2",
        sound: noproblem,
        key: "2",
        label: "No-Problem"
    }, {
        value: "3",
        sound: click,
        key: "3",
        label: "Click"
    }, {
        value: "4",
        sound: game,
        key: "4",
        label: "Game-scenes"
    }, {
        value: "5",
        sound: short,
        key: "5",
        label: "Short-Success"
    }, {
        value: "6",
        sound: wrong,
        key: "6",
        label: "Wrong-answer"
    }
];