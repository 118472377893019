import React, { useContext, useState, useEffect } from "react";
import { Layout, Menu, Badge } from "antd";
import { useSelector } from "react-redux";
import logo1 from "../../assets/image/ConvoBird-logo-final-03.png";
import logo2 from "../../assets/image/bird.png"
import { useLocation, useNavigate, } from "react-router-dom";
import { SocketContext } from "../../context/socketContext";
import { AdminSideBar, UserSideBar } from "../adminSideBar";

const Sidebarr = ({ collapsed, setCollapsed }) => {
  //hooks
  const location = useLocation();
  let navigate = useNavigate();
  const { Socket, setFiltredUsers, FiltredUers } = useContext(SocketContext);
      const ticketsData = useSelector((state) => state.TicketsReducer.data);
  // const visitorData = useSelector((state) => state.ALLHistoryReducer.dataH);
  const userData = useSelector((state) => state.Auth.userData);
  const [AllUsers, setAllUsers] = useState([]);
  const [selectedKey, setselectedKey] = useState(null)
  let isReadData = ticketsData?.filter((i) => i?.isRead === false);
  const currentPath = location.pathname;

  const { Sider } = Layout;

  useEffect(() => {
    if (!Socket) return;
    Socket?.on("MyTeam", async (data) => {
      setAllUsers(data);
    });
  }, [Socket]);

  useEffect(() => {
    if (AllUsers?.length > 0) {
      const findingMySelf = AllUsers?.filter((t) => t?.name !== userData?.name);
      setFiltredUsers(findingMySelf);
    }
  }, [AllUsers]);

  return (
    <Sider
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 1
      }}
      className="sider"
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div onClick={() => navigate("/")} className={collapsed ? "minlogo" : "logo"} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {collapsed ? <img src={logo2} style={{ height: 'auto', width: 35, marginLeft: 2, marginRight: 2 }} />
          :
          <img src={logo1} style={{ height: 'auto', width: "80%", marginLeft: 2, marginRight: 2 }} />}
      </div>
      <Menu
        className="menu"
        theme="dark"
        selectedKeys={selectedKey !== null ? [selectedKey] : [currentPath]}
        defaultOpenKeys={['14']}
        mode="inline"
        items={userData?.role == "superadmin" ?
          AdminSideBar(navigate, FiltredUers, setselectedKey, isReadData)
          :
          UserSideBar(navigate, FiltredUers, setselectedKey, isReadData)}
      />
    </Sider>
  );
};

export default Sidebarr;
