import * as actionTypes from "./VisitorsJoinedTypes";

const INTIAL_STATE = {
    isLoadingVisitor: false,
    data: [],
};
const VisitorReducer = (state = INTIAL_STATE, action) => {
    const { payload } = action;
    switch (action.type) {
        case actionTypes.IS_LOADING_VISITOR:
            return {
                ...state,
                isLoadingVisitor: true
            };

        case actionTypes.VISITOR_SUCCESS:
            return {
                ...state,
                isLoadingVisitor: false,
                data: payload
            };

        case actionTypes.VISITOR_FAILED:
            return {
                ...state,
                isLoadingVisitor: false
            };


        default:
            return state;
    }
};

export default VisitorReducer;
