import * as actionTypes from "./TicketsTypes";
import { Post } from "../../helpers/apicalls/apicalls";

export const getTickets = (data, token) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_LOADING_TICKETS });
    Post("/agents/user/getTickets", data, token)
      .then(function (response) {
        if (response) {
          dispatch({
            type: actionTypes.GET_TICKETS_SUCCESS,
            payload: response?.data
          });
        } else {
          dispatch({ type: actionTypes.GET_TICKETS_FALED });
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.GET_TICKETS_FALED });
      });
  };
};

export const updateTickets = (data, token, afterSuccess) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_LOADING_TICKETS });
    Post("/agents/user/updateTickets", data, token)
      .then(function (response) {

        if (response.status) {
          afterSuccess();
          dispatch({
            type: actionTypes.UPDATE_TICKETS_SUCCESS,
            payload: response?.data
          });
        } else {
          dispatch({ type: actionTypes.UPDATE_TICKETS_FALED });
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.UPDATE_TICKETS_FALED });
      });
  };
};

