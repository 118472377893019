import React, { useState } from 'react';
import { Modal } from 'antd';
import Stripe from '../stripe';

const PaymentModal = ({ userData, CurrentData, setIsModalOpen, isModalOpen }) => {
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
 
    return (
        <>
            <Modal footer={null} title={`${CurrentData?.title} - $${CurrentData?.price}`} maskClosable={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Stripe userData={userData} CurrentData={CurrentData} />
            </Modal>
        </>
    );
};
export default PaymentModal;