import { CloseOutlined, DownloadOutlined, MinusOutlined } from "@ant-design/icons";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";

const DragPastChatHistory = (props) => {
    const allMessages = props.record.convoMessages;
    return (
        <div className="chat-conversation-box">
            <div className="chat-conversation-box-header">
                <div className="chat-conversation-heading">
                    Chat on {moment.utc(props.record.createdAt).format("dddd-MMMM-DD-YYYY-HH:mm")}
                </div>
                <div className="btn-section">
                    <a className="download-btn">
                        <DownloadOutlined />
                    </a>
                    {/* <a className="close-btn">
                        <CloseOutlined onClick={() => props.setExpandedKey([])} />
                    </a> */}
                </div>
            </div>
            <div className="chat-conversation-box-body">
                <div className="chat-rating">
                    <span>Rating</span>
                    <MinusOutlined />
                </div>
                <div className="chat-comment">
                    <span>Comment</span>
                    <MinusOutlined />
                </div>
                <div className="chat-tags">
                    <span>Tags</span>
                    <MinusOutlined />
                </div>
                <div className="chat-conversations">
                    {allMessages?.map((value, index) => {
                        return (
                            <div className="chat" key={index}>
                                <div className="chat-message">
                                    <h4 className={value.senderID == props.record.unique_id ? "visitor-name" : ""}>{value.senderID == props.record.unique_id ? "Visitor" : "Agent"}</h4>
                                    <p>{value.message}</p>
                                </div>
                                <div className="chat-time">
                                    <p>{moment(value.createdAt).format("hh:mm A")}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default DragPastChatHistory;