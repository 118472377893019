import * as actionTypes from "./ProfileTypes";

const INTIAL_STATE = {
  profileLoading: false,
  soundLoading: false
};

const ProfileReducer = (state = INTIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.ISLOADING_PROFILE:
      return {
        ...state,
        profileLoading: true
      };

    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profileLoading: false
      };
    case actionTypes.UPDATE_PROFILE_FAILED:
      return {
        ...state,
        profileLoading: false
      };
    /////////////////
    case actionTypes.ISLOADING_SOUND:
      return {
        ...state,
        soundLoading: true
      };

    case actionTypes.UPDATE_SOUND_SUCCESS:
      return {
        ...state,
        soundLoading: false
      };
    case actionTypes.UPDATE_SOUND_FAILED:
      return {
        ...state,
        soundLoading: false
      };
    default:
      return state;
  }
};

export default ProfileReducer;
