import React, { useState, useEffect } from "react";
import { Modal, Table, theme, Button, Space, Select, Form, Input, Spin, message, Popconfirm } from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import { AddBlockVisitorProperties, deleteBannedPerperties, getBannedVisitor, getBlockedProperties } from "../../redux/Banned/BannedActions";
import moment from "moment/moment";
import UnblockModal from "../../components/unblockModal/UnblockModal";
import { Brand_By_Adminid } from "../../redux/Brands/BrandsActions";

const Banned = () => {
  const userData = useSelector((state) => state.Auth.userData);
  const usertoken = useSelector((state) => state.Auth.token);
  const banneddata = useSelector((state) => state.BannedReducer.banneddata);
  const isLoadingbanned = useSelector((state) => state.BannedReducer.isLoadingbanned);
  const getbannedPropertiesdata = useSelector((state) => state.BannedReducer.getbannedPropertiesdata);
  const isLoadingbannedProperties = useSelector((state) => state.BannedReducer.isLoadingbannedProperties);
  const isLoadinggetbannedProperties = useSelector((state) => state.BannedReducer.isLoadinggetbannedProperties);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const brandsData = useSelector((state) => state.Brands.brandByAdminData);
  const [isModalOpenStatus, setIsModalOpenStatus] = useState(false);
  const [userID, setuserID] = useState(false);
  const [allData, setAllData] = useState([]);
  const [bannedList, setbannedList] = useState("banned-properties");
  const [bannedbylist, setbannedbylist] = useState("ip-address");

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    let newData = {
      companyId: userData?.companyId
    };
    dispatch(Brand_By_Adminid(newData, usertoken));


  }, []);


  useEffect(() => {
    if (bannedList === "banned-properties") {
      let banneddata = {
        user_id: userData?._id
      }
      dispatch(getBlockedProperties(banneddata, usertoken));
    }
  }, []);
  // getBlockedProperties

  const onFinish = (e) => {
    let FindBrand = brandsData?.filter((i) => i?.token == e?.token);
    let data = {
      name: e?.name,
      value: e?.value,
      bannedBy: e?.BannedBy,
      brand: FindBrand?.[0]?._id,
      user_id: userData?._id
    }
    dispatch(AddBlockVisitorProperties(data, usertoken, handleCancel, message, onSuccess))
  };

  const onSuccess = () => {
    let banneddata = {
      user_id: userData?._id
    }
    dispatch(getBlockedProperties(banneddata, usertoken));
  }

  const openModal = (id) => {
    setuserID(id);
    setIsModalOpen(true);
  };

  const showModal = () => {
    setIsModalOpenStatus(true);
  };

  const handleCancel = () => {
    setIsModalOpenStatus(false);
    form.resetFields();
  };

  // const navigate = useNavigate();
  const onSearch = (value) => {
    if (value !== null) {
      const filteredArray = banneddata?.filter(item => item?.unique_id?.includes(value));
      setAllData(filteredArray);
    }
    else {
      setAllData(banneddata);
    }
  };

  const handleChange = (value) => {
    if (value === "banned-properties") {
      let banneddata = {
        user_id: userData?._id
      }
      dispatch(getBlockedProperties(banneddata, usertoken));
    }
    else {
      let data = {
        Brands: userData?.assignBrands
      }
      dispatch(getBannedVisitor(data, usertoken));
    }
    setbannedList(value);
  };

  const handleChangeBannedStatus = (value) => {
    setbannedbylist(value);
  };

  useEffect(() => {
    if (banneddata?.length > 0) {
      setAllData(banneddata);
    }
    else {
      setAllData([]);
    }
  }, [banneddata]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,
        color: "#ffffff",
        margin: "0px 25px",
      }}
      spin
    />
  );

  const validateIpAddress = (_, value) => {
    const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
    if (value && !ipRegex.test(value)) {
      return Promise.reject('Please enter a valid IP address');
    }
    return Promise.resolve();
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const columns = [
    {
      title: "Visitor",
      dataIndex: "unique_id",
      render: (text) => <a>#[{text}]</a>,
    },
    {
      title: "Reason",
      dataIndex: "reason",
    },
    {
      title: "Banned-By",
      dataIndex: "name",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (text) => <a> {moment(text).format("DD-MM-YYYY h:mm A")}</a>,
    },
    {
      title: "Action",
      dataIndex: "unique_id",
      render: (text) => <Button onClick={() => openModal(text)} type="dashed">Unblock</Button>,
    },
  ];

  const columnsProperties = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Ip/Country",
      dataIndex: "value",
    },
    {
      title: "Brand/Wensite",
      dataIndex: "brandInfo",
      render: (text) => <p>{text?.brandname}</p>,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (text) => <a> {moment(text).format("DD-MM-YYYY h:mm A")}</a>,
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (text) => <div>
        <Popconfirm
          placement="topLeft"
          title="Delete the banned property"
          description="Are you sure to delete this banned property?"
          onConfirm={() => ondelete(text)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined />
        </Popconfirm>
      </div>,
    },
  ];
  const ondelete = (id) => {
    let data = {
      id: id
    }
    dispatch(deleteBannedPerperties(data, usertoken, onSucces));
  }

  const onSucces = () => {
    let banneddata = {
      user_id: userData?._id
    }
    dispatch(getBlockedProperties(banneddata, usertoken));
  }

  return (
    <>
      <UnblockModal userID={userID} setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
      <Modal maskClosable={false} title="Banned Properties" open={isModalOpenStatus} onCancel={() => handleCancel()}
        footer={false}>
        <Form
          initialValues={{
            BannedBy: 'ip-address',
          }}
          form={form}
          onFinish={onFinish}
          layout={"vertical"}
          autoComplete="off"
        >
          <Form.Item rules={[
            {
              required: true,
            },
          ]} style={{ marginBottom: 10 }} label="Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item rules={[
            {
              required: true,
            },
          ]} style={{ marginBottom: 10 }} label="Banned-By" name="BannedBy">
            <Select
              defaultValue={bannedbylist}
              style={{
                width: "100%",
              }}
              onChange={handleChangeBannedStatus}
              options={[
                {
                  value: 'ip-address',
                  label: 'Banned By IP Address',
                },
                {
                  value: 'country',
                  label: 'Banned By Country Code',
                },
              ]}
            />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: 10 }}
            name="token"
            rules={[{ required: true }]}
            label="Website/Brand">
            <Select>
              {brandsData?.map((i, index) => (
                <Select.Option key={index} value={i?.token}>{i?.brandname}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item rules={[
            { required: true },
            { validator: bannedbylist === "ip-address" && validateIpAddress },
          ]}

            style={{ marginBottom: 10 }} label="Value" name="value">
            <Input />
          </Form.Item>
          {bannedbylist === "ip-address" && <p style={{ textAlign: "center" }}>Set value which u want to ban the ip. eg:111.111.1.1</p>}
          {bannedbylist === "country" && <p style={{ textAlign: "center" }}>Set value which u want to ban the country. eg:US</p>}
          <Form.Item
            wrapperCol={{
              span: 24,
            }}
          >  <Button style={{ marginRight: 10 }} key="cancel" onClick={() => handleCancel()}>
              Cancel
            </Button>

            <Button disabled={isLoadingbannedProperties} style={{ marginTop: 10 }} type="primary" htmlType="submit">
              {isLoadingbannedProperties ? <Spin indicator={antIcon} style={{ padding: "0px 17px 0px 17px" }} /> : "Save Changes"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <div className="topBtns">
        <div className="shortcut">
          <Space direction="vertical">
            <Search
              placeholder="Search"
              onSearch={onSearch}
              style={{
                width: 200,
              }}
            />
          </Space>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", }}>
          <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginRight: 20 }}> {bannedList === "banned-properties" && <Button
            className="addShort"
            type="primary"
            onClick={() => showModal()}
          >
            Add Properties
          </Button>}</div>
          <div>
            <Select
              defaultValue={bannedList}
              style={{
                width: 180,
              }}
              onChange={handleChange}
              options={[
                {
                  value: 'banned',
                  label: 'Banned Visitor',
                },
                {
                  value: 'banned-properties',
                  label: 'Banned Properties',
                },
              ]}
            />
          </div>



        </div>
      </div>
      {bannedList === "banned-properties" ? <div>
        <Table
          loading={isLoadinggetbannedProperties}
          columns={columnsProperties}
          dataSource={getbannedPropertiesdata?.map((item, index) => ({ ...item, key: index }))}
        />
      </div>
        :
        <div>
          <Table
            loading={isLoadingbanned}
            columns={columns}
            dataSource={allData?.map((item, index) => ({ ...item, key: index }))}
          />
        </div>
      }

    </>
  );
};

export default Banned;
