import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { getBannedVisitor, unBlockVisitor } from '../../redux/Banned/BannedActions';
const UnblockModal = ({ isModalOpen, setIsModalOpen, userID }) => {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.Auth.userData);
    const usertoken = useSelector((state) => state.Auth.token);
    const isLoadingunblock = useSelector((state) => state.Auth.isLoadingunblock);

    const handleOk = () => {
        let data = {
            id: userID
        }
        dispatch(unBlockVisitor(data, usertoken, onSuccess));
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onSuccess = () => {
        setIsModalOpen(false);
        let data = {
            Brands: userData?.assignBrands
        }
        dispatch(getBannedVisitor(data, usertoken));
    };

    return (
        <>
            <Modal confirmLoading={isLoadingunblock} title={`Are you sure u want to un-block Visitor #[${userID}]`} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            </Modal>
        </>
    );
}

export default UnblockModal;
