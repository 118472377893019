import React, { useState } from 'react';
import { Button, Modal, Input, message } from 'antd';
import { exportChatToMail } from '../../redux/Chats/PastChatsActions';
import { useDispatch } from 'react-redux';

const EmailModal = ({ setIsEmailModalOpen, isEmailModalOpen, vistiorData, userData, usertoken }) => {
    const dispatch = useDispatch();
    const [emailValue, setEmailValue] = useState("");

    const handleOk = () => {
        const validate = validation();
        if (validate) {
            let data = {
                visitorId: vistiorData?.unique_id,
                email: emailValue
            };
            dispatch(exportChatToMail(data, usertoken, afterSuccess, afterError));
        } else {
            message.error('Invalid Email.');
        }
    };

    const handleCancel = () => {
        setIsEmailModalOpen(false);
    };

    const validation = () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const validate = emailRegex.test(emailValue);
        if (validate) {
            return true;
        } else {
            return false;
        }
    }

    const emailChangeHandler = (e) => {
        setEmailValue(e.target.value)
    }

    const afterSuccess = (msg) => {
        message.success(msg);
        setIsEmailModalOpen(false);
        setEmailValue("");
    }

    const afterError = (msg) => {
        message.error(msg);
    }

    return (
        <>
            <Modal title="Email :" open={isEmailModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Input value={emailValue} onChange={emailChangeHandler} placeholder='Enter email' />
            </Modal>
        </>
    );
};

export default EmailModal;
