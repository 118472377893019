import React, { useContext, useState, useEffect } from 'react';
import { Button, Modal, Checkbox, List } from 'antd';
import { SocketContext } from '../../context/socketContext';
import { useDispatch, useSelector } from "react-redux";

const TransferModal = ({ SelectedVisitorData, transferModalOpen, setTransferModalOpen }) => {
    const { Socket, FiltredUers, setTranserStatus, TranserStatus } = useContext(SocketContext);
    const userData = useSelector((state) => state.Auth.userData);

    const [SelectedRow, setSelectedRow] = useState(null);

    const [statusCurrennt, setstatusCurrennt] = useState(null);


    const handleOk = () => {
        if (SelectedRow !== null) {
            Socket.emit("transferToagent", {
                visitorData: SelectedVisitorData,
                agentData: SelectedRow,
                senderData: {
                    name: userData?.sudoName,
                    id: userData?._id,
                }
            });
        }
        // setTransferModalOpen(false);
    };

    useEffect(() => {
        if (!Socket) return;
        Socket.on("acceptStatus", (data) => {
            setstatusCurrennt(data);
        });
    }, [Socket]);

    useEffect(() => {
        if (statusCurrennt === null) return;
        if (TranserStatus?.length > 0) {
            let isInArrr = TranserStatus?.some((i) => i?.senderID == userData?._id && i?.unique_id == SelectedVisitorData?.unique_id);
            if (isInArrr) {
                let NewisInArrr = TranserStatus?.findIndex((i) => i?.senderID == userData?._id && i?.unique_id == SelectedVisitorData?.unique_id);
                const updatedData = [...TranserStatus]
                // Delete item at the desired index
                updatedData.splice(NewisInArrr, 1);
                // Add new data at the same index
                updatedData.splice(NewisInArrr, 0, statusCurrennt);
                setTranserStatus(updatedData);
            }
            else {
                setTranserStatus((pre) => [statusCurrennt, ...pre]);
            }
        }
        else {
            setTranserStatus([statusCurrennt]);
        }
    }, [statusCurrennt]);

    const handleCancel = () => {
        setTransferModalOpen(false);
    };

    const onRowClick = (item) => {
        if (SelectedRow === null) {
            setSelectedRow(item);
        }
        else {
            setSelectedRow(null);
        }
    }

    let findStatus = TranserStatus?.find((i) => i?.visitorData?.unique_id == SelectedVisitorData?.unique_id);

    return (
        <>
            <Modal title="Select Agent to transfer" open={transferModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <List
                    itemLayout="horizontal"
                    dataSource={FiltredUers}
                    renderItem={(item, index) => (
                        <List.Item onClick={(() => onRowClick(item))}>
                            <div style={{ marginRight: 10 }}>
                                <Checkbox checked={SelectedRow?.name === item?.name} />
                            </div>
                            <List.Item.Meta
                                key={index}
                                title={<p>{item?.displayName}</p>
                                }
                            />
                            {findStatus !== undefined && <p>{findStatus?.status}</p>}
                        </List.Item>
                    )}
                />
            </Modal>
        </>
    );
}

export default TransferModal;
