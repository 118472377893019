import { Button, Table, Modal, Badge, Form, Input, Switch, message } from "antd";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { addDepartment, getDepartments, updateDepartment, updateStatus } from "../../redux/Departments/DepartmentsActions";
import { EditFilled } from "@ant-design/icons";

const Departments = () => {
    const userData = useSelector((state) => state.Auth.userData);
    const usertoken = useSelector((state) => state.Auth.token);
    const departments = useSelector((state) => state.Departments.data)
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [departmentId, setDepartmentId] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const data = {
            companyId: userData?.companyId
        };
        dispatch(getDepartments(data, usertoken));
    }, []);

    const showModal = () => {
        setOpen(true);
    };

    const afterSuccess = (msg) => {
        message.success(`${msg}`);
        const data = {
            companyId: userData?.companyId
        };
        dispatch(getDepartments(data, usertoken));
        form.resetFields();
        setOpen(false);
        setDepartmentId(null);
    };

    const afterFailed = (msg) => {
        message.error(`${msg}`);
    }

    const onFinish = (values) => {
        // const brandIds = [];
        // userData?.assignBrands.map((brand) => {
        //     brandIds.push(brand.id);
        // });
        const data = {
            departmentName: values.departmentName,
            companyId: userData?.companyId
        };
        dispatch(addDepartment(data, usertoken, afterSuccess, afterFailed));
    };

    const onUpdate = (values) => {
        const data = {
            id: departmentId,
            departmentName: values.departmentName
        };
        dispatch(updateDepartment(data, usertoken, afterSuccess, afterFailed));
    }

    const handleCancel = () => {
        setDepartmentId(null);
        setOpen(false);
        form.setFieldsValue({
            departmentName: null
        })
    };

    const showEditModal = (id) => {
        setDepartmentId(id);
        form.setFieldsValue({
            departmentName: departments.find(x => x._id === id).name
        });
        setOpen(true);
    }

    const afterStatusUpdate = (msg) => {
        message.success(`${msg}`);
        const data = {
            companyId: userData?.companyId
        };
        dispatch(getDepartments(data, usertoken));
    };

    const onStatusChange = (value, id) => {
        const data = {
            id: id,
            status: value
        }
        dispatch(updateStatus(data, usertoken, afterStatusUpdate));
    }

    const columns = [
        {
            title: "Sr #",
            dataIndex: "serial",
        },
        {
            title: "Department",
            dataIndex: "departmentName",
        },
        {
            title: "Status",
            dataIndex: "isActive"
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text) => (
                <span>
                    <a onClick={() => showEditModal(text)}><EditFilled /></a>
                </span>
            ),
        }
    ];

    const onChange = (pagination, filters, sorter, extra) => { };

    const data = [];

    for (let i = 0; i < departments?.length; i++) {
        data.push({
            key: i,
            serial: i + 1,
            departmentName: departments[i]?.name,
            isActive: <Switch checked={departments[i]?.isActive} onChange={(e) => onStatusChange(e, departments[i]?._id)} />,
            action: departments[i]?._id
        });
    }

    return (
        <div>
            <div className="topBtns">
                <div>

                </div>
                <div>
                    {userData?.role === "superadmin" || userData?.role === "admin" ?
                        <Button type="primary" onClick={showModal}>
                            Add Department
                        </Button>
                        : null
                    }
                </div>

                {/* Modal Start */}
                <Modal
                    title={departmentId ? "Update Department" : "Add Department"}
                    open={open}
                    onCancel={handleCancel}
                    footer={null}>
                    <Form
                        form={form}
                        name="basic"
                        onFinish={departmentId ? onUpdate : onFinish}
                        autoComplete="off">
                        <Form.Item
                            name="departmentName"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input Department name!"
                                }
                            ]}
                        >
                            <Input placeholder="Department Name" />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                {departmentId ? "Update" : "Submit"}
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
                {/* Modal End */}
            </div>
            <Table
                columns={columns}
                dataSource={data}
            />
        </div>
    );
};

export default Departments;
