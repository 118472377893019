import * as actionTypes from "./UsersTypes";
import { Get, Post } from "../../helpers/apicalls/apicalls";
import * as userActionTypes from "../Auth/AuthTypes";

export const Add_User = (data, token) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_LOADING });
    Post("/agents/user/register", data, token)
      .then(function (response) {
        if (response) {
          dispatch({
            type: actionTypes.Users_DATA_SUCCESS,
            payload: response?.data
          });
        } else {
          dispatch({ type: actionTypes.Users_DATA_FALED });
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.Users_DATA_FALED });
      });
  };
};

export const Get_agents_by_brand = (data, token) => {
  return (dispatch) => {
    Post("/agents/user/agents-by-brand", data, token)
      .then(function (response) {
        if (response?.status) {
          dispatch({
            type: actionTypes.AGENT_BY_BRAND_SUCCESS,
            payload: response?.data
          });
        } else {

          // dispatch({ type: actionTypes.AGENT_BY_BRAND_FALED });
        }
      })
      .catch(function (error) {

      });
  };
};

export const getAllUser = (data, token) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_LOADING });
    Post("/agents/user/get-all-user", data, token)
      .then((res) => {
        if (res) {
          dispatch({
            type: actionTypes.GET_USER_DATA_SUCCESS,
            payload: res?.data
          })
        } else {
          dispatch({ type: actionTypes.GET_USER_DATA_FAILED });
        }
      })
      .catch((err) => {
        dispatch({ type: actionTypes.GET_USER_DATA_FAILED });
      })
  }
}

export const getUserByAdminId = (data, token) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_LOADING });
    Post("/agents/user/get-user-by-admin-id", data, token)
      .then((res) => {
        if (res) {
          dispatch({
            type: actionTypes.GET_USER_DATA_SUCCESS,
            payload: res?.data
          })
        } else {
          dispatch({ type: actionTypes.GET_USER_DATA_FAILED });
        }
      })
      .catch((err) => {
        dispatch({ type: actionTypes.GET_USER_DATA_FAILED });
      })
  }
}

export const updateUser = (data, token, modalClose) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_LOADING });
    Post("/agents/user/update-user", data, token)
      .then((res) => {
        if (res) {
          dispatch({
            type: actionTypes.UPDATE_USER_SUCCESS,
            payload: res.data
          });
          modalClose();
        } else {
          dispatch({ type: actionTypes.UPDATE_USER_FAILED });
        }
      })
      .catch((err) => {
        dispatch({ type: actionTypes.UPDATE_USER_FAILED });

      });
  }
}

export const GetOverAllUsers = (token) => {
  return (dispatch) => {
    Get("/agents/user/allUsers", token)
      .then(function (response) {
        if (response?.status) {
          dispatch({
            type: actionTypes.GET_USER_ALL_SUCCESS,
            payload: response?.data
          });
        } else {
          dispatch({ type: actionTypes.GET_USER_ALL_FAILED });
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.GET_USER_ALL_FAILED });
      });
  };
};

export const UiChange = (data, token) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_LOADING_UI });
    Post("/agents/user/ui-customize", data, token)
      .then(function (response) {
        if (response?.status) {
          const localStorageData = localStorage.getItem("reduxPersist:root");
          if (localStorageData) {
            localStorage.removeItem("reduxPersist:root");
            localStorage.setItem(
              "reduxPersist:root",
              JSON.stringify({ data: response?.data, token: token })
            );
          }
          else {
            sessionStorage.removeItem("reduxPersist:root");
            sessionStorage.setItem(
              "reduxPersist:root",
              JSON.stringify({ data: response?.data, token: token })
            );
          }
          dispatch({
            type: userActionTypes.USER_DATA_SUCCESS,
            payload: response?.data
          });
          dispatch({
            type: actionTypes.UI_SUCCESS,
            payload: response?.data
          });
        } else {
          dispatch({ type: actionTypes.UI_FALED });
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.UI_FALED });
      });
  };
};