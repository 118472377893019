import { EditOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Breadcrumb, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getPackages } from '../../redux/Packages/PackagesActions';
import { useLocation } from 'react-router-dom';


const Packages = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.Auth.userData);
    const usertoken = useSelector((state) => state.Auth.token);
    const packages = useSelector((state) => state.PackagesReducer.packages);
    const packagesLoading = useSelector((state) => state.PackagesReducer.packagesLoading);

    useEffect(() => {
        let data = {
            planTypeId: location?.state?.data
        }
        dispatch(getPackages(data, usertoken));
    }, []);

    const columns = [
        {
            title: "Title",
            dataIndex: "title",
        },
        {
            title: "Price",
            dataIndex: "price",
            render: (text) => `$ ${text}`,
        },
        {
            title: "Duration (Days)",
            dataIndex: "duration",
        },
        {
            title: "Brands Allowed",
            dataIndex: "brandCount",
        },
        {
            title: "Users Allowed",
            dataIndex: "userCount",
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (text) => `${text ? "Active" : "DeActive"}`,
        },

        {
            title: "Action",
            key: "action",
            render: (text) => (
                < Space size="middle" >
                    <a onClick={() => navigate('/edit-package', { state: { data: text } })}><EditOutlined /></a>
                </Space >
            ),
        },
    ];

    return (
        <>
            <Breadcrumb className="breadCrumb" items={[{ title: "Plans & Packages" }, { title: "Packages" }]} />
            <div className="topbtn">
                {userData?.role != "agent" && <Button type="primary" onClick={() => navigate('/create-package', { state: { data: location?.state?.data } })}>
                    Create Package
                </Button>}
            </div>
            <Table
                pagination={false}
                loading={packagesLoading}
                columns={columns}
                dataSource={packages}
            />
            <div className="topbtn">
                <Button className="back-btn" onClick={() => navigate('/plans-packages')}>Back</Button>
            </div>
        </>
    );
}

export default Packages;
