import * as actionTypes from "./OfflineStatusTypes";
import { Post, Get } from "../../helpers/apicalls/apicalls";

export const AddOfflineStatus = (data, token, onSuccess, form, onFailed) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_LOADING });
    Post("/agents/user/addOfflineStatus", data, token)
      .then(function (response) {
        if (response?.status) {
          dispatch({
            type: actionTypes.ADD_STATUS_SUCCESS,
            payload: response?.data
          });
          onSuccess();
          form.resetFields();
        } else {
          dispatch({ type: actionTypes.ADD_STATUS_FALED });
          onFailed(response.message)
          // alert(response.message);
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.ADD_STATUS_FALED });
      });
  };
};

export const GetOfflineStatus = (data, token, onFailed) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_LOADING });
    Post("/agents/user/getOfflineStatus", data, token)
      .then(function (response) {
        if (response?.status) {
          dispatch({
            type: actionTypes.GET_STATUS_SUCCESS,
            payload: response?.data
          });
        } else {
          dispatch({ type: actionTypes.GET_STATUS_FALED });
          onFailed(response.message)
          // alert(response.message);
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.GET_STATUS_FALED });
      });
  };
};

export const EditOfflineStatus = (data, token, onSuccess, form, onFailed) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_LOADING });
    Post("/agents/user/editOfflineStatus", data, token)
      .then(function (response) {
        if (response?.status) {
          dispatch({
            type: actionTypes.EDIT_STATUS_SUCCESS,
            payload: response?.data
          });
          onSuccess();
          form.resetFields();
        } else {
          dispatch({ type: actionTypes.EDIT_STATUS_FALED });
          onFailed(response.message)
          // alert(response.message);
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.EDIT_STATUS_FALED });
      });
  };
};

