import * as actionTypes from "./TicketsTypes";

const INTIAL_STATE = {
  data: [],
  isLoadingT: false,
  updatedData: []
};
const TicketsReducer = (state = INTIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.IS_LOADING_TICKETS:
      return {
        ...state,
        isLoadingT: true
      };
    case actionTypes.GET_TICKETS_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoadingT: false
      };
    case actionTypes.GET_TICKETS_FALED:
      return {
        ...state,
        isLoadingT: false
      };
    //////////////////////////////////////////

    case actionTypes.UPDATE_TICKETS_SUCCESS:
      return {
        ...state,
        updatedData: payload,
        isLoadingT: false
      };
    case actionTypes.UPDATE_TICKETS_FALED:
      return {
        ...state,
        isLoadingT: false
      };
    default:
      return state;
  }
};

export default TicketsReducer;
