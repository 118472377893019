import React, { useState, useEffect } from "react";
import { Divider, Radio, Table, theme, Button, Space, } from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";

const Agents = () => {
  const {
    token: { colorBgContainer }
  } = theme.useToken();
  const columns = [
    {
      title: "Display Name",
      dataIndex: "displayname",
      render: (text) => <a>{text}</a>
    },
    {
      title: "Name",
      dataIndex: "name"
    },
    {
      title: "Email",
      dataIndex: "email"
    },
    {
      title: "Role",
      dataIndex: "role"
    },
    {
      title: "Enable",
      dataIndex: "enable"
    }
  ];
  const data = [
    {
      key: "1",
      displayname: "John Brown",
      name: "John Doe",
      email: "hello@gmail.com",
      role: "Admin",
      Enable: "New York No. 1 Lake Park"
    }
  ];
  const onSearch = (value) => { };
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => { }
  };
  const [selectionType, setSelectionType] = useState("checkbox");
  return (
    <>
      <div className="topBtns">
        <div className="shortcut">
          <Space direction="vertical">
            <Search
              placeholder="Search"
              onSearch={onSearch}
              style={{
                width: 200
              }}
            />
          </Space>
          <Button type="primary" className="addShort" loading={loading}>
            Add Agent
          </Button>
        </div>

      </div>
      <div>
        <Table
          rowSelection={{
            type: selectionType,
            ...rowSelection
          }}
          columns={columns}
          dataSource={data}
          scroll={{
            y: 120
          }}
          onRow={(i) => ({
            onClick: (e) =>
              navigate('/shortcuts/addshortcut')
          })}
        />
      </div>
    </>
  );
};

export default Agents;
