import React, { useState } from 'react';
import { Button, Modal, Input } from 'antd';

const { TextArea } = Input;

const BlockModal = ({ setIsModalOpen, isModalOpen, Socket, vistiorData, userData }) => {

    const [Message, setMessage] = useState("");
    const handleOk = () => {
        Socket.emit("agentBlockedVisitor", {
            vistiorData: vistiorData?.unique_id,
            Message: Message,
            agentDataId: userData?._id
        });
        setIsModalOpen(false);
        setMessage("");
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Modal title="Reason :" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <TextArea value={Message} onChange={(e) => setMessage(e.target.value)} placeholder='Write your reason here...' rows={3} />
            </Modal>
        </>
    );
};

export default BlockModal;
