import * as actionTypes from "./WidgetTypes.js";

const INTIAL_STATE = {
  data: [],
  isLoadingwidget: false,
  widgetData: null,
  widgetLoading: false,
  isLoadingGreeting: false,
  greetingData: null,
  isLoadingAddGreeting: false,
  addGreetingsData: null
};

const WidgetReducer = (state = INTIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.IS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.WIDGET_ADDED_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false
      };
    case actionTypes.WIDGET_ADDED_FAILED:
      return {
        ...state,
        isLoading: false
      };
    /////////////////////////////////////////
    case actionTypes.IS_LOADING_GET:
      return {
        ...state,
        widgetLoading: true
      };
    case actionTypes.WIDGET_GET_SUCCESS:
      return {
        ...state,
        widgetData: payload,
        widgetLoading: false
      };
    case actionTypes.WIDGET_GET_FAILED:
      return {
        ...state,
        widgetLoading: false
      };
    case actionTypes.WIDGET_GET_CLEAN:
      return {
        ...state,
        widgetData: null,
        widgetLoading: false
      };
    //////////////////////

    case actionTypes.IS_LOADING_GREETING:
      return {
        ...state,
        isLoadingGreeting: true
      };
    case actionTypes.GREETING_SUCCESS:
      return {
        ...state,
        greetingData: payload,
        isLoadingGreeting: false
      };
    case actionTypes.GREETING_FAILED:
      return {
        ...state,
        isLoadingGreeting: false,
        greetingData: null,
      };

    case actionTypes.GREETING_CLEAN:
      return {
        ...state,
        greetingData: null,
        isLoadingGreeting: false
      };
    /////////////////////////////////////////
    //////////////////////

    case actionTypes.IS_LOADING_ADD_GREETING:
      return {
        ...state,
        isLoadingAddGreeting: true
      };
    case actionTypes.ADD_GREETING_SUCCESS:
      return {
        ...state,
        addGreetingsData: payload,
        isLoadingAddGreeting: false
      };
    case actionTypes.ADD_GREETING_FAILED:
      return {
        ...state,
        isLoadingAddGreeting: false,
        addGreetingsData: null,
      };
    /////////////////////////////////////////

    default:
      return state;
  }
};

export default WidgetReducer;
