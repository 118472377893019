import React, { useState, useEffect } from "react";
import {
    Input,
    Button,
    message,
    Row,
    Col,
    Form,
    Breadcrumb,
    Select,
    Switch,
    Spin
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";

import { AddOfflineStatus, EditOfflineStatus } from "../../redux/OfflineStatus/OfflineStatusActions";
import { useLocation } from "react-router-dom";

const AddStatus = () => {
    //hooks
    const newlocation = useLocation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    //Reducers
    const userData = useSelector((state) => state.Auth.userData);
    const usertoken = useSelector((state) => state.Auth.token);
    const brandsData = useSelector((state) => state.Brands.data);
    // const brandsData = useSelector((state) => state.Brands.brandByAdminData);
    const addedLoading = useSelector(
        (state) => state.OfflineStatusReducer.isLoading
    );
    const onFinish = (e) => {
        let FindBrand = brandsData?.filter((i) => i?.token == e?.available_for);
        if (newlocation?.state === null) {
            let newdata = {
                addedBy: userData?._id,
                brand: {
                    name: FindBrand?.[0]?.brandname,
                    token: FindBrand?.[0]?.token
                },
                isActive: e?.active === undefined ? true : e?.active,
                name: e?.name,
                message: e?.message,
            };
            dispatch(AddOfflineStatus(newdata, usertoken, onSuccess, form, onFailed));
        }
        else {
            let newdata = {
                addedBy: userData?._id,
                brand: {
                    name: FindBrand?.[0]?.brandname,
                    token: FindBrand?.[0]?.token
                },
                isActive: e?.active === undefined ? true : e?.active,
                name: e?.name,
                message: e?.message,
            };
            dispatch(EditOfflineStatus(newdata, usertoken, onSuccess, form, onFailed));
        }
    };
    const onSuccess = () => {
        message.success("Submit success!");
    };
    const onFailed = (err) => {
        message.error(err);
    };
    return (
        <>
            <Breadcrumb
                className="breadCrumb"
                items={[
                    {
                        title: "Offline-Status"
                    },
                    {
                        title: "Status"
                    }
                ]}
            />
            <Row className="addShortcut">
                <Col xs={24} sm={24} md={14}>
                    <div>
                        {newlocation?.state !== null ? <div className="quickTips">
                            <h6>Edit Status</h6></div> : <div className="quickTips">
                            <h6>Add Status</h6></div>}
                        <Form
                            form={form}
                            labelCol={{
                                span: 5
                            }}
                            wrapperCol={{
                                span: 14
                            }}
                            layout="horizontal"
                            initialValues={{
                                name: newlocation?.state !== null ? newlocation?.state?.name : "",
                                available_for: newlocation?.state !== null ? newlocation?.state?.brand?.token : "",
                                message: newlocation?.state !== null ? newlocation?.state?.message : "",
                                active: newlocation?.state !== null ? newlocation?.state?.active : true,
                            }}
                            onFinish={onFinish}
                            autoComplete="off"
                            style={{
                                maxWidth: 600
                            }}>
                            <Form.Item
                                name="name"
                                rules={[{ required: true }]}
                                label="Name">
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="available_for"
                                rules={[{ required: true }]}
                                label="Website/Brand">
                                <Select>
                                    {brandsData?.map((i, index) => (
                                        <Select.Option key={index} value={i?.token}>{i?.brandname}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="message"
                                rules={[{ required: true }]}
                                label="Message">
                                <TextArea rows={4} />
                            </Form.Item>
                            <Form.Item
                                name="active"
                                label="Active">
                                <Switch defaultChecked />
                            </Form.Item>
                            <Button
                                disabled={addedLoading}
                                type="primary"
                                htmlType="submit">
                                {addedLoading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><Spin size="small" /></div> : "Save"}
                            </Button>
                        </Form>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={10} >
                    <div className="quickTips">
                        <h6>Quick tips</h6>
                        <h3>Offline Status</h3>
                        <p>
                            Offline-Status trigger when no agent is available for visitor when visitor message i will show the text before
                            offline form is visible.
                        </p>
                        <h3>Default Status</h3>
                        <p>
                            If any Website/Brand dosenot have set offline-status it will show default-status ,(Hello, we applogize currenty no agent is available if u have an query please submit a form.)
                        </p>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default AddStatus;
