import { message } from "antd";
import { Get, Post } from "../../helpers/apicalls/apicalls";
import * as actionTypes from "./PackagesTypes";

export const createPackage = (data, token, afterSuccess) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_PACKAGE_CREATE_LOADING });
        Post('/agents/user/create-package', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({ type: actionTypes.PACKAGE_CREATE_SUCCESS });
                    afterSuccess();
                } else {
                    dispatch({
                        type: actionTypes.PACKAGE_CREATE_FALED,
                    });
                    message.error(res?.message, 10);
                }
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.PACKAGE_CREATE_FALED,
                });
            })
    }
}

export const createPlan = (data, token, afterSuccess) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CREATE_PLAN_LOADING });
        Post('/agents/user/create-plan-types', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({ type: actionTypes.CREATE_PLAN_SUCCESS });
                    afterSuccess();
                } else {
                    dispatch({
                        type: actionTypes.CREATE_PLAN_FALED,
                    });
                    message.error(res?.message, 10);
                }
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.CREATE_PLAN_FALED,
                });
            })
    }
}

export const getPackages = (data, token) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_LOADING });
        Post('/agents/user/get-packages', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({
                        type: actionTypes.PACKAGE_DATA_SUCCESS,
                        payload: res?.data
                    });
                } else {
                    dispatch({ type: actionTypes.PACKAGE_DATA_FALED });
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.PACKAGE_DATA_FALED });
            })
    }
}

export const getPlans = (token) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.PLAN_LOADING });
        Get('/agents/user/get-plan-types', token)
            .then((res) => {
                if (res?.status) {
                    dispatch({
                        type: actionTypes.PLAN_SUCCESS,
                        payload: res?.data
                    });
                } else {
                    dispatch({ type: actionTypes.PLAN_FALED });
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.PLAN_FALED });
            })
    }
}
export const getPackageById = (data, token) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_PACKAGE_BY_ID_LOADING });
        Post('/agents/user/get-package-by-id', { packageId: data }, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({
                        type: actionTypes.PACKAGE_BY_ID_SUCCESS,
                        payload: res?.data
                    });
                } else {
                    dispatch({ type: actionTypes.PACKAGE_BY_ID_FALED });

                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.PACKAGE_BY_ID_FALED });

            })
    }
}

export const updatePackage = (data, token, afterSuccess) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_PACKAGE_UPDATE_LOADING });
        Post('/agents/user/update-package', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({ type: actionTypes.PACKAGE_UPDATE_SUCCESS });
                    afterSuccess();
                } else {
                    dispatch({
                        type: actionTypes.PACKAGE_UPDATE_FALED,
                    });
                    message.error(res?.message, 10);
                }
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.PACKAGE_UPDATE_FALED,
                });
            })
    }
}

export const updatePlan = (data, token, afterSuccess) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.UPDATE_PLAN_LOADING });
        Post('/agents/user/update-plan-types', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({ type: actionTypes.UPDATE_PLAN_SUCCESS });
                    afterSuccess();
                } else {
                    dispatch({
                        type: actionTypes.UPDATE_PLAN_FALED,
                    });
                    message.error(res?.message, 10);
                }
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.UPDATE_PLAN_FALED,
                });
            })
    }
}