import * as actionTypes from "./PastChatsTypes.js";

const INTIAL_STATE = {
  data: null,
  isLoading: false,
  currentChatData: null,
  exportChatLoading: false
};

const PastChatReducer = (state = INTIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.IS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.PAST_CHATS_DATA_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false
      };
    case actionTypes.PAST_CHATS_DATA_FAILED:
      return {
        ...state,
        isLoading: false
      };

    case actionTypes.CURRENT_CHAT_DATA_SUCCESS:
      return {
        ...state,
        currentChatData: payload
      };
    case actionTypes.PAST_CONVERSATION_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: payload,
        isLoading: false
      };
    case actionTypes.PAST_CONVERSATION_MESSAGES_FAILED:
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.EXPORT_CHAT_TO_MAIL_LOADING:
      return {
        ...state,
        exportChatLoading: true
      };
    case actionTypes.EXPORT_CHAT_TO_MAIL_SUCCESS:
      return {
        ...state,
        exportChatLoading: false
      };
    case actionTypes.EXPORT_CHAT_TO_MAIL_FAILED:
      return {
        ...state,
        exportChatLoading: false
      };
    default:
      return state;
  }
};

export default PastChatReducer;
