import * as actionTypes from "./DepartmentsTypes.js";

const INITIAL_STATE = {
    data: [],
    activeDepartment: [],
    departmentUsers: [],
    isLoading: false,
    isAddLoading: false,
    isDepartmentUsersLoading: false
};

const DepartmentReducer = (state = INITIAL_STATE, action) => {
    const { payload } = action;
    switch (action.type) {
        case actionTypes.IS_LOADING:
            return {
                ...state,
                isLoading: true
            }


        //Department Data
        case actionTypes.DEPARTMENT_DATA_SUCCESS:
            return {
                ...state,
                data: payload,
                isLoading: false
            }
        case actionTypes.DEPARTMENT_DATA_FALED:
            return {
                ...state,
                isLoading: false
            }

        //Department Add
        case actionTypes.IS_DEPARTMENT_REGISTER_LOADING:
            return {
                ...state,
                isAddLoading: true
            }
        case actionTypes.DEPARTMENT_REGISTER_SUCCESS:
            return {
                ...state,
                data: payload,
                isAddLoading: false
            }
        case actionTypes.DEPARTMENT_REGISTER_FALED:
            return {
                ...state,
                isAddLoading: false
            }

        //Department Update
        case actionTypes.IS_DEPARTMENT_UPDATE_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case actionTypes.DEPARTMENT_UPDATE_SUCCESS:
            return {
                ...state,
                data: payload,
                isLoading: false
            }
        case actionTypes.DEPARTMENT_UPDATE_FALED:
            return {
                ...state,
                isLoading: false
            }

        //Active Department
        case actionTypes.IS_ACTIVE_DEPARTMENT_LOADING:
            return {
                ...state,
                isActivedepartmentLoading: true
            }
        case actionTypes.ACTIVE_DEPARTMENT_SUCCESS:
            return {
                ...state,
                activeDepartment: payload,
                isActivedepartmentLoading: false
            }
        case actionTypes.ACTIVE_DEPARTMENT_FAILED:
            return {
                ...state,
                isActivedepartmentLoading: false
            }

        //Department Users
        case actionTypes.IS_DEPARTMENT_USERS_LOADING:
            return {
                ...state,
                isDepartmentUsersLoading: true
            }
        case actionTypes.DEPARTMENT_USERS_SUCCESS:
            return {
                ...state,
                departmentUsers: payload,
                isDepartmentUsersLoading: false
            }
        case actionTypes.DEPARTMENT_USERS_FAILED:
            return {
                ...state,
                isDepartmentUsersLoading: false
            }

        default:
            return state;
    }
}

export default DepartmentReducer;