import * as actionTypes from "./AuthTypes";

const INTIAL_STATE = {
  userData: null,
  isLoading: false,
  token: null,
  isLoadingPassword: false,
  passWordData: [],
  packegsData: [],
  isLoadingPackegs: false,
  isLoadingPlans: false,
  PlanData: [],
  packagesSubData: [],
  isLoadingPackagesSub: false
};

const AuthReducer = (state = INTIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.IS_LOADING:
      return {
        ...state,
        isLoading: true
      };

    // Login Reducer
    case actionTypes.USER_DATA_SUCCESS:
      return {
        ...state,
        userData: payload,
        isLoading: false
      };
    case actionTypes.USER_DATA_FALED:
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.USER_TOKEN:
      return {
        ...state,
        token: payload
      };

    case actionTypes.SIGNUP_USER_SUCCESS:
      return {
        ...state,
        userData: payload,
        isLoading: false
      };
    case actionTypes.SIGNUP_USER_FALED:
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.USER_LOGOUT:
      return {
        ...state,
        userData: null,
        isLoading: false,
        token: null
      };
    case actionTypes.IS_LOADING_PASSWORD:
      return {
        ...state,
        isLoadingPassword: true
      };

    // Login Reducer
    case actionTypes._PASSWORD_SUCCESS:
      return {
        ...state,
        passWordData: payload,
        isLoadingPassword: false
      };
    case actionTypes._PASSWORD_FALED:
      return {
        ...state,
        isLoadingPassword: false
      };
    /// Packegs
    case actionTypes.PACKEGS_LOADING_PASSWORD:
      return {
        ...state,
        isLoadingPackegs: true
      };

    case actionTypes.PACKEGS_SUCCESS:
      return {
        ...state,
        packegsData: payload,
        isLoadingPackegs: false
      };
    case actionTypes.PACKEGS_FALED:
      return {
        ...state,
        isLoadingPackegs: false
      };

    /// Plane Types
    case actionTypes.PLAN_TYPE_LOADING_PASSWORD:
      return {
        ...state,
        isLoadingPlans: true
      };
    case actionTypes.PLAN_TYPE_SUCCESS:
      return {
        ...state,
        PlanData: payload,
        isLoadingPlans: false
      };
    case actionTypes.PLAN_TYPE_FALED:
      return {
        ...state,
        isLoadingPlans: false
      };

    /// Packages Sub
    case actionTypes.PACKAGES_SUB_LOADING:
      return {
        ...state,
        isLoadingPackagesSub: true
      };
    case actionTypes.PACKAGES_SUB_SUCCESS:
      return {
        ...state,
        packagesSubData: payload,
        isLoadingPackagesSub: false
      };
    case actionTypes.PACKAGES_SUB_FALED:
      return {
        ...state,
        isLoadingPackagesSub: false
      };

    default:
      return state;
  }
};

export default AuthReducer;
