export const IS_LOADING = "IS_LOADING";
export const VISITOR_DETAILS_DATA_SUCCESS = "VISITOR_DETAILS_DATA_SUCCESS";
export const VISITOR_DETAILS_DATA_FAILED = "VISITOR_DETAILS_DATA_FAILED";

export const IS_LOADING_DETAILS = "IS_LOADING_DETAILS";
export const ALL_VISITOR_DETAILS_DATA_SUCCESS = "ALL_VISITOR_DETAILS_DATA_SUCCESS";
export const ALL_VISITOR_DETAILS_DATA_FAILED = "ALL_VISITOR_DETAILS_DATA_FAILED";

export const IS_LOADING_MESSAGES = "IS_LOADING_MESSAGES";
export const ALL_VISITOR_MESSAGES_DATA_SUCCESS = "ALL_VISITOR_MESSAGES_DATA_SUCCESS";
export const ALL_VISITOR_MESSAGES_DATA_FAILED = "ALL_VISITOR_MESSAGES_DATA_FAILED";