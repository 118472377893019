import React, { useRef, useState } from "react";
import { Button, Modal, Select, Input, Form } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../redux/Users/UsersActions";

const UserEditModal = (props) => {
    const userData = useSelector((state) => state.Auth.userData);
    const usertoken = useSelector((state) => state.Auth.token);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleCancel = () => {
        form.resetFields();
        setLoading(false);
        props.close(false);
    }


    const handleOk = (values) => {
        setLoading(true);
        const data = {
            authId: userData?._id,
            authRole: userData?.role,
            id: props.user._id,
            name: values.name,
            role: values.Role,
            sudoName: values.sudo,
            email: values.email,
            assignBrands: values.Brand,
            department: values.Department
        };

        dispatch(updateUser(data, usertoken, handleCancel));
    }

    useEffect(() => {
        setOpen(props.open ? true : false)
    }, [props.open]);

    useEffect(() => {
        if (props?.user) {
            const brands = [];
            props?.user?.assignBrands?.map((brand, index) => {
                brands.push(brand);
            });
            form.setFieldsValue({
                name: props?.user?.name,
                sudo: props?.user?.sudoName,
                email: props?.user?.email,
                Role: props?.user?.role,
                Brand: brands,
                Department: props?.user?.department
            });
        }
    }, [props?.user]);


    return (
        <Modal
            open={open}
            title="Edit User"
            footer={null}
            onCancel={handleCancel}
        >
            <Form
                form={form}
                name="control-hooks"
                onFinish={handleOk}
                style={{
                    maxWidth: 600,
                }}
            >
                <Form.Item
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Please enter name!",
                        },
                    ]}
                >
                    <Input placeholder="Name" />
                </Form.Item>
                <Form.Item
                    name="sudo"
                    rules={[
                        {
                            required: true,
                            message: "Please enter sudo name!",
                        },
                    ]}
                >
                    <Input placeholder="Sudo Name" />
                </Form.Item>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: "Please enter email!",
                        },
                        {
                            type: 'email',
                            message: 'This email is not valid!',
                        }
                    ]}
                >
                    <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                    name="Role"
                    rules={[
                        {
                            required: true,
                            message: "Please select role!",
                        },
                    ]}
                >
                    <Select
                        placeholder="Select Role"
                        style={{
                            width: "100%",
                        }}
                        options={props.role === "superadmin" ? props.options2 : props.options}
                    />
                </Form.Item>
                <Form.Item
                    name="Brand"
                    rules={[
                        {
                            required: true,
                            message: "Please select brand(s)!",
                        },
                    ]}
                >
                    <Select
                        mode="multiple"
                        allowClear
                        style={{
                            width: "100%",
                        }}
                        placeholder="Select Brand(s)"
                        options={props.allBrands}
                    />
                </Form.Item>
                <Form.Item
                    name="Department"
                    rules={[
                        {
                            required: true,
                            message: "Please select department!",
                        },
                    ]}
                >
                    <Select
                        allowClear
                        style={{
                            width: "100%",
                        }}
                        placeholder="Select Department"
                        options={props.allDepartments}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                        style={{
                            float: "right",
                        }}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default UserEditModal;