import { Breadcrumb, Layout, theme } from "antd";
import React, { useState, useEffect } from "react";
import Headers from "../../components/headers/headers";
import Sidebarr from "../../components/sidebar/sidebar";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row } from "antd";
import {
  AlertOutlined,
  FundOutlined,
  FundViewOutlined,
  HourglassOutlined,
  SearchOutlined,
  SettingOutlined,
  SolutionOutlined,
  ThunderboltOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
const { Content, Footer } = Layout;
const HomePage = () => {
  const userData = useSelector((state) => state.Auth.userData);
  // const usertoken = useSelector((state) => state.Auth.token);

  // const {
  //   token: { colorBgContainer }
  // } = theme.useToken();

  const items = [
    {
      key: "0",
      title: (
        <>
          <SettingOutlined /> Widget
        </>
      ),
      content: <div>Embed and customize the widget on your website.</div>,
      href: "widget"
    },
    {
      key: "1",
      title: (
        <>
          <UserSwitchOutlined /> Visitors
        </>
      ),
      content: <div>See a list of visitors to your website and start a conversation.</div>,
      href: "visitors"
    },
    {
      key: "2",
      title: (
        <>
          <FundOutlined /> History
        </>
      ),
      content: <div>Track the conversations you have with customers.</div>,
      href: "history"
    },
    {
      key: "3",
      title: (
        <>
          <ThunderboltOutlined /> Tickets
        </>
      ),
      content: <div>See customers request ticket while you offline.</div>,
      href: "tickets"
    }
  ];
  return (
    <>
      <div className="home">
        <h6>Hello, {userData?.sudoName}!</h6>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 12 }}>
          <Col span={6}>
            <Card className='dashboardCard'>
              <div className='box'>
                <div className='boxContent'>
                  <h3>Widget</h3>
                  <div className='boxFooter'>
                    <p>Embed and customize the widget on your website</p>
                  </div>
                </div>
                <div className='boxIcon'>
                  <FundViewOutlined style={{ fontSize: '26px' }} />
                </div>
              </div>
            </Card>
          </Col>
          <Col span={6} style={{ paddingRight: '8px' }}>
            <Card className='dashboardCard served'>
              <div className='box'>
                <div className='boxContent'>
                  <h3>Visitors</h3>
                  <div className="boxFooter">
                    <p>See a list of visitors to your website and start a conversation.</p>
                  </div>
                </div>
                <div className='boxIcon'>
                  <UserSwitchOutlined style={{ fontSize: '26px' }} />
                </div>
              </div>
            </Card>
          </Col>
          <Col span={6} style={{ paddingLeft: '0px' }}>
            <Card className='dashboardCard ticket'>
              <div className='box'>
                <div className='boxContent'>
                  <h3>History</h3>
                  <div className="boxFooter">
                    <p>Track the conversations you have with customers.</p>
                  </div>
                </div>
                <div className='boxIcon'>
                  <SolutionOutlined style={{ fontSize: '26px' }} />
                </div>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card className='dashboardCard agent'>
              <div className='box'>
                <div className='boxContent'>
                  <h3>Tickets</h3>
                  <div className="boxFooter">
                    <p>See customers request ticket while you offline.</p>
                  </div>
                </div>
                <div className='boxIcon'>
                  <UsergroupAddOutlined style={{ fontSize: '26px' }} />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default HomePage;
