import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Downloader } from "../../components/fileDownloader/Downloader";
import { fileChanger } from "../../helpers/fileChanger";

const TransTab = ({ selectedData, colorCode }) => {
  const overAllUsers = useSelector((state) => state.Users.overAllUsers);
  const fetchMessageData = () => {
    return selectedData?.messages
      .sort((x, y) => {
        return new Date(x?.time).toUTCString() < new Date(y?.time).toUTCString()
          ? 1
          : -1;
      });
  };

  const FileView = (u, findUser) => {
    // const blob = u?.file !== "" && fileChanger(u?.file?.body, u?.file?.filetype);
    return <div className="containerx">
      <div className="avatar-sm me-3 ms-0">
        <div className="avatar-down">
          {selectedData?.unique_id == u?.senderID ?
            <p style={{ color: `#${colorCode}` }} className="userName">{selectedData?.name ? selectedData?.name : `#[${u?.senderID}]`}</p> :
            <p className="userName">{findUser?.[0]?.sudoName}</p>}
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Downloader file={u?.file} filename={u?.file?.filename} />
            <p style={{ paddingLeft: 10 }}>{u?.file?.filename}</p>
            <span className="time-right">{moment(u?.time).format("hh:mm A  DD/MM/YYYY")}</span>
          </div>
        </div>
      </div>
    </div>
  };

  return (
    <>
      <div className="head">
        #[{selectedData?.unique_id}]
        <div className="headerEmail">
          <p className="heading">Email:</p>
          <p className="email">{selectedData?.email}</p>
        </div>
        <div className="headerEmail">
          <p className="heading">Name:</p>
          <p className="email">{selectedData?.name}</p>
        </div>
        <div className="headerEmail">
          <p className="heading">Tags:</p>
          {selectedData?.tags?.map((i, index) => { return <p key={index} className="email">{i}</p> })}
        </div>
      </div>
      <div className="transcript">
        {fetchMessageData()?.map((u, index) => {

          let findUser = overAllUsers?.filter((i) => i?._id == u?.senderID);
          return u?.type === "joiner" ?
            <div className="containerx"
              key={index}
              style={{
                width: "100%",
                fontStyle: "italic",
                textAlign: "center",
                lineHeight: "0px",
                marginBottom: "20px"
              }}
            >
              <p style={{ textAlign: "center" }}>{u?.message}</p>
              <span style={{ fontSize: 10 }}>
                {moment(u?.time).format("hh:mm A  DD/MM/YYYY")}
              </span>
            </div>
            : u?.type === "leave" ?
              <div className="containerx"
                key={index}
                style={{
                  width: "100%",
                  fontStyle: "italic",
                  textAlign: "center",
                  lineHeight: "0px",
                  marginBottom: "20px"
                }}
              >
                <p style={{ textAlign: "center" }}>{u?.message}</p>
                <span style={{ fontSize: 10, textAlign: "center" }}>
                  {moment(u?.time).format("hh:mm A  DD/MM/YYYY")}
                </span>
              </div>
              : u?.type === "widget" ?
                <div className="containerx"
                  key={index}
                  style={{
                    width: "100%",
                    fontStyle: "italic",
                    textAlign: "center",
                    lineHeight: "0px",
                    marginBottom: "20px"
                  }}
                >
                  <p style={{ textAlign: "center" }}>{u?.message}</p>
                  <span style={{ fontSize: 10, textAlign: "center" }}>
                    {moment(u?.time).format("hh:mm A  DD/MM/YYYY")}
                  </span>
                </div>
                : u?.type === "disconnect" ?
                  <div className="containerx"
                    key={index}
                    style={{
                      width: "100%",
                      fontStyle: "italic",
                      textAlign: "center",
                      lineHeight: "0px",
                      marginBottom: "20px"
                    }}
                  >
                    <p style={{ textAlign: "center" }}>{u?.message}</p>
                    <span style={{ fontSize: 10, textAlign: "center" }}>
                      {moment(u?.time).format("hh:mm A  DD/MM/YYYY")}
                    </span>
                  </div>

                  : u?.file !== null ?
                    FileView(u, findUser)
                    : u?.type == "text" &&
                    <div className="containerx" key={index}>
                      <div className="userInfo">
                        {selectedData?.unique_id == u?.senderID ?
                          <p style={{ color: `#fcae29` }} className="userName">{selectedData?.name ? selectedData?.name : `#[${u?.senderID}]`}</p> :
                          <p className="userName">{findUser?.[0]?.sudoName}</p>
                        }
                        <span className="time-right">{moment(u?.time).format("hh:mm A  DD/MM/YYYY")}</span>
                      </div>
                      <p>{u?.message}</p>
                    </div>

        })}
      </div>
    </>
  );
};

export default TransTab;
