import React, { useState, useEffect } from "react";
import {
  Divider,
  Radio,
  Table,
  theme,
  Button,
  Tabs,
  Row,
  Col,
  Checkbox,
  Form,
  Input,
  Tag,
  Switch,
  Space,
} from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import {
  DeleteOutlined,
  PlusOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
const Account = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {

    },
  };
  const navigate = useNavigate();
  const onChange = (key) => {

  };
  const items = [
    {
      key: "1",
      label: `Subscription`,
      children: (
        <TabPaneWrapper>
          <Tab1 />
        </TabPaneWrapper>
      ),
    },
    {
      key: "2",
      label: `Chat tags`,
      children: (
        <TabPaneWrapper>
          <Tab2 />
        </TabPaneWrapper>
      ),
    },
    {
      key: "3",
      label: `File sending`,
      children: (
        <TabPaneWrapper>
          <Tab3 />
        </TabPaneWrapper>
      ),
    },
  ];
  return (
    <div className="accountTabs">
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </div>
  );
};
export function TabPaneWrapper({ children, ...props }) {
  return <div {...props}>{children}</div>;
}
const Tab1 = () => {
  return (
    <div className="subsTabs">
      <Row className="addShortcut">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <h6>Team Plan</h6>
          <p>
            The features of your current plan are shown below. Compare Chat's
            pricing plans.
          </p>
          <div className="teamList">
            <h4>Agents</h4>
            <p>1 agent allowed</p>
          </div>
          <div className="teamList">
            <h4>Concurrent chats</h4>
            <p>Unlimited</p>
          </div>
          <div className="teamList">
            <h4>Triggers</h4>
            <p>2</p>
          </div>
          <div className="teamList">
            <h4>Departments</h4>
            <p>2</p>
          </div>
          <div className="teamList">
            <h4>Web Widget (Classic) customization</h4>
            <p>Yes</p>
          </div>
          <p>
            Visit Account management to upgrade your plan or add more agents at
            any time.
          </p>
          <div className="bodyFooter">
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
            <Button type="primary" htmlType="submit" className="revertbtn">
              Revert Cancle
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
const Tab2 = () => {
  const onChange = (e) => {

  };
  const [form] = Form.useForm();
  const [data, setData] = useState([]);

  const handleClicks = () => {
    setData([...data, { option: "" }]);
  };
  const handleChanges = (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...data];
    onchangeVal[i][name] = value;
    setData(onchangeVal);
  };
  const handleDelete = (i) => {
    const deleteVal = [...data];
    deleteVal.splice(i, 1);
    setData(deleteVal);
  };

  return (
    <div className="chatTabs">
      <Row className="addShortcut">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <h6>General preferences</h6>
          <div className="preList">
            <p>Allow tg creation during chats</p>
            <Checkbox onChange={onChange}>
              Allow admins to create new tags during chats. If not enabled,
              admins can only create tags in the predefined tags list below and
              in shortcuts.
            </Checkbox>
          </div>
          <h5>
            Admins can create new tags under Setting - Shortcuts and edit tags
            in History.
          </h5>
          <h6>Predefined tags list</h6>
          <p>
            Add or delete tags from your account’s list of available tags.
            Deleting a tag here does not remove it from existing chats. Spaces
            and special characters other than underscore (_) and hyphen (-) are
            not allowed. Learn more
          </p>
          <Form
            form={form}
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 14,
            }}
            layout="horizontal"
            initialValues={{
              size: "default",
            }}
            style={{
              maxWidth: 440,
            }}
          >
            <Form.Item name="options" label="Options">
              <div className="optionInput">
                {data.map((val, i) => (
                  <div key={i} className="addInput">
                    <Input
                      name="option"
                      value={val.option}
                      onChange={(e) => handleChanges(e, i)}
                    />
                    {i === 0 ? (
                      ""
                    ) : (
                      <DeleteOutlined
                        className="deleteBtn"
                        onClick={() => handleDelete(i)}
                      />
                    )}
                  </div>
                ))}
                <PlusOutlined className="addBtn" onClick={handleClicks} />
              </div>
            </Form.Item>
          </Form>
          <Tag bordered={false}>Tag 4</Tag>
          <Tag bordered={false} closable>
            Tag 4
          </Tag>
        </Col>
      </Row>
    </div>
  );
};
const Tab3 = () => {
  const onChange = (checked) => {
  };
  const [value, setValue] = useState(1);
  const onChangeRadio = (e) => {
    setValue(e.target.value);
  };
  const { TextArea } = Input;
  return (
    <div className="fileSending addShortcut">
      <Row>
        <Col xs={24} sm={24} md={10} lg={10} xl={10}>
          <div className="header">
            <h6>File Sending</h6>
            <Switch defaultChecked onChange={onChange} />
          </div>
          <p>
            Set your preferences for receiving and sending files during a chat.
            Specify allowed safe file extensions below. Learn about file sending
            preferences
          </p>
          <div className="timeList">
            <p>Idle status</p>
            <Radio.Group onChange={onChangeRadio} value={value}>
              <Space direction="vertical">
                <Radio value={1}>
                  Sharing is limited to PDF (.pdf), PNG (.png), JPEG (.jpg), GIF
                  (.gif) or text (.txt) file types.
                </Radio>
                <Radio value={2}>
                  Allow PDF (.pdf), PNG (.png), JPEG (.jpg), GIF (.gif), text
                  (.txt), and the additional file types specified below. Use
                  commas to separate file types. Include a period (.) before
                  each extension. Example: .svg, .html
                </Radio>
              </Space>
            </Radio.Group>
          </div>
          <Col className="textArea" span={18} offset={6}>
            <TextArea cols={2} rows={4} placeholder="maxLength is 6" maxLength={6} />
            <p>I understand there may be potential risks associated with allowing these extensions and agree to assume these risks when enabling extensions.
              <br />Take care to only enable safe extensions that you need for your business operations. If you accept compressed files, keep in mind that they could contain any attachment type when extracted.</p>
          </Col>
          <div className="bodyFooter">
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
            <Button type="primary" htmlType="submit" className="revertbtn">
              Revert Cancle
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Account;
