
import { Post } from "../../helpers/apicalls/apicalls";
import * as actionTypes from "./ProfileTypes";
import * as AuthActionTypes from "../Auth/AuthTypes";
import * as authActionTypes from "../Auth/AuthTypes";
import { message } from "antd";


export const getProfile = (data, token) => {
  return (dispatch) => {
    Post("/agents/user/getProfile", data, token)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: authActionTypes.USER_DATA_SUCCESS,
            payload: res.data
          });
        }
      })
      .catch((err) => { });
  };
};

export const updateGeneralProfile = (data, token, onSuccess) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ISLOADING_PROFILE });
    Post("/agents/user/updateGeneralProfile", data, token)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: actionTypes.UPDATE_PROFILE_SUCCESS,
          });
          dispatch({
            type: authActionTypes.USER_DATA_SUCCESS,
            payload: res.data
          });
          onSuccess(res.message);
        }
      })
      .catch((err) => {
        dispatch({ type: actionTypes.UPDATE_PROFILE_FAILED });
      });
  };
};

export const updateSoundProfile = (data, token, onSuccess) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ISLOADING_SOUND });
    Post("/agents/user/updateSoundProfile", data, token)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: actionTypes.UPDATE_SOUND_SUCCESS,
          });
          dispatch({
            type: authActionTypes.USER_DATA_SUCCESS,
            payload: res.data
          });
          onSuccess(res.message);
        }
      })
      .catch((err) => {
        dispatch({ type: actionTypes.UPDATE_SOUND_FAILED });
      });
  };
};

export const changePasswordProfile = (data, token, onSuccess) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_LOADING_CHANGE_PASSWORD });
    Post("/agents/user/auth/change-password", data, token)
      .then((res) => {
        if (res.status) {
          dispatch({ type: actionTypes.IS_LOADING_CHANGE_PASSWORD_SUCCESS });
          onSuccess(res.message);
        } else {
          message.error(`${res.message}`);
        }
      })
      .catch((err) => {
        dispatch({ type: actionTypes.IS_LOADING_CHANGE_PASSWORD_FAILED });
      });
  };
};

