import * as actionTypes from "./TriggersTypes";

const INTIAL_STATE = {
    conditions: [],
    actions: [],
    allTriggers: [],
    editTrigger: {},
    isLoading: false
};
const TriggersReducer = (state = INTIAL_STATE, action) => {
    const { payload } = action;
    switch (action.type) {
        case actionTypes.IS_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.GET_TRIGGERS_CONDITIONS_SUCCESS:
            return {
                ...state,
                conditions: payload,
                isLoading: false
            };
        case actionTypes.GET_TRIGGERS_CONDITIONS_FAILED:
            return {
                ...state,
                isLoading: false
            };
        case actionTypes.GET_TRIGGERS_ACTIONS_SUCCESS:
            return {
                ...state,
                actions: payload,
                isLoading: false
            };
        case actionTypes.GET_TRIGGERS_ACTIONS_FAILED:
            return {
                ...state,
                isLoading: false
            };
        case actionTypes.ADD_TRIGGERS_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case actionTypes.ADD_TRIGGERS_FAILED:
            return {
                ...state,
                isLoading: false
            };
        case actionTypes.GET_TRIGGERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                allTriggers: payload,
            };
        case actionTypes.GET_TRIGGERS_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case actionTypes.DELETE_TRIGGER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                allTriggers: payload,
            };
        case actionTypes.DELETE_TRIGGER_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case actionTypes.GET_TRIGGER_BY_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                editTrigger: payload,
            };
        case actionTypes.GET_TRIGGER_BY_ID_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case actionTypes.UPDATE_TRIGGERS_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case actionTypes.UPDATE_TRIGGERS_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
};

export default TriggersReducer;
