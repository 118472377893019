import React, { useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./pages/auth/login/login";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "./redux/Auth/AuthTypes";

import "./App.scss";

import MainRoutes from "./allroutes/mainRoutes";
import ForgetPassword from "./pages/auth/forgetpassword/ForgetPassword";
import SignupPage from "./pages/auth/signup/signup";
import Packeges from "./pages/auth/packeges/packeges";


// import Chats from "./pages/chat/chat";

const App = () => {
  const userData = useSelector((state) => state.Auth.userData);
  const dispatch = useDispatch();
  useEffect(() => {
    const storedUserData = sessionStorage.getItem("reduxPersist:root");
    const localStorageData = localStorage.getItem("reduxPersist:root");
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      dispatch({
        type: actionTypes.USER_DATA_SUCCESS,
        payload: parsedUserData?.data,
      });
      dispatch({
        type: actionTypes.USER_TOKEN,
        payload: parsedUserData?.token,
      });
    }
    if (localStorageData) {
      const parsedUserData = JSON.parse(localStorageData);
      dispatch({
        type: actionTypes.USER_DATA_SUCCESS,
        payload: parsedUserData?.data,
      });
      dispatch({
        type: actionTypes.USER_TOKEN,
        payload: parsedUserData?.token,
      });
    }
  }, [dispatch]);
  return (
    <>
      {userData !== null ? (
        <MainRoutes />
      ) : (
        <Routes>
          <Route index path="/login" element={<LoginPage />} />
          <Route index path="/forget-password" element={<ForgetPassword />} />
          <Route index path="/signup" element={<SignupPage />} />
          <Route index path="/pricing-plan" element={<Packeges />} />
          <Route path="*" element={<LoginPage />} />
        </Routes>
      )}
    </>
  );
};
export default App;
