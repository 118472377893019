import * as actionTypes from "./AnalyticsTypes";

const INTIAL_STATE = {
  AnalyticsData: null,
  isLoadingAnalytics: false
};
const AnalyticsReducer = (state = INTIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.IS_LOADING_ANALTYICS:
      return {
        ...state,
        isLoadingAnalytics: true
      };
    case actionTypes.ANALTYICS_DATA_SUCCESS:
      return {
        ...state,
        AnalyticsData: payload,
        isLoadingAnalytics: false
      };
    case actionTypes.ANALTYICS_DATA_FAILED:
      return {
        ...state,
        isLoadingAnalytics: false
      };

    default:
      return state;
  }
};

export default AnalyticsReducer;
