import React from "react";
import { Post } from "../../helpers/apicalls/apicalls";
import * as actionTypes from "./PastChatsTypes.js";

export const getPastMessages = (data, token) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.IS_LOADING
    })
    Post("/agents/user/client-past-messages", data, token)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: actionTypes.PAST_CHATS_DATA_SUCCESS,
            payload: res.data
          });
        }
      })
      .catch((err) => {

        dispatch({ type: actionTypes.PAST_CHATS_DATA_FAILED });
      });
  };
};

export const getCurrentMessages = (data, token) => {
  return (dispatch) => {
    Post("/agents/user/messages/current-messages", data, token)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: actionTypes.CURRENT_CHAT_DATA_SUCCESS,
            payload: res.data
          });
        }
      })
      .catch((err) => {

      });
  };
};

// export const getConversationMessages = (data, token) => {
//   return (dispatch) => {
//     Post('/agents/user/get-conversation-messages', data, token)
//       .then((res) => {
//         if (res.status) {
//           dispatch({
//             type: actionTypes.PAST_CONVERSATION_MESSAGES_SUCCESS,
//             payload: res.data
//           });
//         }
//       })
//       .catch((err) => {

//         dispatch({ type: actionTypes.PAST_CONVERSATION_MESSAGES_FAILED });
//       })
//   }
// }

export const exportChatToMail = (data, token, afterSuccess, afterError) => {
  return (dispatch) => {
    Post('/agents/user/export-chat-to-mail', data, token)
      .then((res) => {
        if (res.status) {
          dispatch({ type: actionTypes.EXPORT_CHAT_TO_MAIL_SUCCESS });
          afterSuccess(res.message);
        } else {
          dispatch({ type: actionTypes.EXPORT_CHAT_TO_MAIL_FAILED });
          afterError(res.message);
        }
      })
      .catch((err) => {
        dispatch({ type: actionTypes.EXPORT_CHAT_TO_MAIL_FAILED });
      });
  }
}