import React, { useState, useEffect } from "react";
import {
  Button,
  Tabs,
  Form,
  Input,
  Col,
  Row,
  ColorPicker,
  message,
  Select,
  Image
} from "antd";

import { useDispatch, useSelector } from "react-redux";
import { addWidget, getWidgetdata } from "../../redux/Widget/WidgetActions";
import * as actionTypes from "../../redux/Widget/WidgetTypes.js";
import GetStartedTab from "./GetStarted";
import { Get_All_Brands, Get_Brands_By_Id } from "../../redux/Brands/BrandsActions";
import Appearance from "./Appearance";
import Greetings from "./Greetings";

const Widget = () => {
  const userData = useSelector((state) => state.Auth.userData);
  const allDataBrand = useSelector((state) => state.Brands.data);
  const [BrandToken, setBrandToken] = useState(null);
  const usertoken = useSelector((state) => state.Auth.token);
  let filtredBrands = allDataBrand?.map((i) => {
    return {
      label: i?.brandname,
      value: i?.token
    }
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      companyId: userData?.companyId
    };
    if (userData?.role === "superadmin") {
      dispatch(Get_All_Brands(usertoken));
    } else {
      dispatch(Get_Brands_By_Id(data, usertoken));
    }
  }, []);

  useEffect(() => {
    const data = {
      brand_token: BrandToken
    };
    dispatch(getWidgetdata(data, usertoken));
    return () => {
      dispatch({
        type: actionTypes.WIDGET_GET_CLEAN
      });
    }
  }, [BrandToken]);

  const onChange = (key) => { };
  const handleChangeDrop = (value) => {
    setBrandToken(value)
  };
  const items = [
    {
      key: "1",
      label: "Get Started",
      children: (
        <TabPaneWrapper>
          <GetStartedTab />
        </TabPaneWrapper>
      ),
    },
    {
      key: "2",
      label: "Appearance",
      children: (
        <TabPaneWrapper>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#56777a" }}>
            <h6 style={{ paddingLeft: 10, color: "#fff" }}>Website</h6>
            <Select
              placeholder="Select Webiste"
              value={BrandToken}
              // defaultValue="Chat"
              style={{ width: 150, marginBottom: 15, marginRight: 20 }}
              onChange={handleChangeDrop}
              options={filtredBrands}
            />
          </div>
          <Appearance BrandToken={BrandToken} />
          {/* <Tab2 BrandToken={BrandToken} /> */}
        </TabPaneWrapper>
      ),
    },
    {
      key: "3",
      label: "Greeting",
      children: (
        <TabPaneWrapper>
          <Greetings />
        </TabPaneWrapper>
      ),
    },
  ];
  return (
    <section>
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </section>
  );
};

export function TabPaneWrapper({ children, ...props }) {
  return <div {...props}>{children}</div>;
}


export default Widget;
