import * as actionTypes from "./PackagesTypes";

const INTIAL_STATE = {
    packages: [],
    packagesLoading: false,
    isLoading: false,
    packageById: {},
    plans: [],
    planLoading: false,
    plansUpdate: [],
    plansUpdateLoading: false,
    plansCreate: [],
    plansCreateLoading: false
};

const PackagesReducer = (state = INTIAL_STATE, action) => {
    const { payload } = action;

    switch (action.type) {
        // Get All Packages
        case actionTypes.IS_LOADING:
            return {
                ...state,
                packagesLoading: true
            };

        case actionTypes.PACKAGE_DATA_SUCCESS:
            return {
                ...state,
                packages: payload,
                packagesLoading: false
            };

        case actionTypes.PACKAGE_DATA_FALED:
            return {
                ...state,
                packagesLoading: false
            };

        // Create Package
        case actionTypes.IS_PACKAGE_CREATE_LOADING:
            return {
                ...state,
                isLoading: true
            };

        case actionTypes.PACKAGE_CREATE_SUCCESS:
            return {
                ...state,
                isLoading: false
            };

        case actionTypes.PACKAGE_CREATE_FALED:
            return {
                ...state,
                isLoading: false
            };

        // Package By Id
        case actionTypes.IS_PACKAGE_BY_ID_LOADING:
            return {
                ...state,
                isLoading: true
            };

        case actionTypes.PACKAGE_BY_ID_SUCCESS:
            return {
                ...state,
                packageById: payload,
                isLoading: false
            };

        case actionTypes.PACKAGE_BY_ID_FALED:
            return {
                ...state,
                isLoading: false
            };

        // Update Package
        case actionTypes.IS_PACKAGE_UPDATE_LOADING:
            return {
                ...state,
                isLoading: true
            };

        case actionTypes.PACKAGE_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false
            };

        case actionTypes.PACKAGE_UPDATE_FALED:
            return {
                ...state,
                isLoading: false
            };

        // plan
        case actionTypes.PLAN_LOADING:
            return {
                ...state,
                planLoading: true
            };

        case actionTypes.PLAN_SUCCESS:
            return {
                ...state,
                plans: payload,
                planLoading: false,

            };

        case actionTypes.PLAN_FALED:
            return {
                ...state,
                planLoading: false
            };

        // plan update
        case actionTypes.UPDATE_PLAN_LOADING:
            return {
                ...state,
                plansUpdateLoading: true
            };

        case actionTypes.UPDATE_PLAN_SUCCESS:
            return {
                ...state,
                plansUpdate: payload,
                plansUpdateLoading: false,

            };

        case actionTypes.UPDATE_PLAN_FALED:
            return {
                ...state,
                plansUpdateLoading: false
            };


        // plan create
        case actionTypes.CREATE_PLAN_LOADING:
            return {
                ...state,
                plansCreateLoading: true
            };

        case actionTypes.CREATE_PLAN_SUCCESS:
            return {
                ...state,
                plansCreate: payload,
                plansCreateLoading: false,

            };

        case actionTypes.CREATE_PLAN_FALED:
            return {
                ...state,
                plansCreateLoading: false
            };
        default:
            return state;
    }
}

export default PackagesReducer;