import { Breadcrumb, Button, Checkbox, Col, Form, Input, Row, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updatePlan } from '../../redux/Packages/PackagesActions';
import { useLocation } from 'react-router-dom';

const EditPlan = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const usertoken = useSelector((state) => state.Auth.token);
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            planTitle: location?.state?.data?.title,
            planDuration: location?.state?.data?.duration,
        });
    }, []);

    const submitHandler = (values) => {
        let data = {
            planTypeId: location?.state?.data?.action,
            title: values?.planTitle,
            duration: values?.planDuration
        }
        dispatch(updatePlan(data, usertoken, navigateAfterSuccess));
    }

    const navigateAfterSuccess = () => {
        message.success('Successfully Updated');
        navigate('/plans-packages');
    }

    return (
        <>
            <Breadcrumb className="breadCrumb" items={[{ title: "Plans & Packages" }, { title: "Edit Plan" }]} />
            <Row className="addShortcut">
                <Col span={24}>
                    <div>
                        <Form
                            form={form}
                            name="control-hooks"
                            labelCol={{ span: 3 }}
                            layout="horizontal"
                            className="ticketForm"
                            onFinish={submitHandler}
                        >
                            <Form.Item
                                name="planTitle"
                                wrapperCol={{ span: 8 }}
                                label="Title"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter plan title!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="planDuration"
                                wrapperCol={{ span: 8 }}
                                label="Duration (In Days)"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter plan duration!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <div className="topbtn">
                                <Button className="back-btn" onClick={() => navigate('/plans-packages')}>Back</Button>
                                <Button type="primary" className="align-right" htmlType="submit">Update</Button>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row >
        </>
    );
}

export default EditPlan;
