import React, { useState, useEffect, useContext } from 'react';
import ReactModal from 'react-modal-resizable-draggable';
import {
    Space,
    Button,
    Dropdown,
    Tooltip
} from "antd";
import {
    CloseOutlined,
    MinusOutlined,
    LogoutOutlined,
    DownOutlined
} from "@ant-design/icons";
import { HiMiniArrowLeftStartOnRectangle } from "react-icons/hi2";

import DrageableBody from '../drageableBody';
import { useDispatch, useSelector } from "react-redux";
import { SocketContext } from '../../context/socketContext';
import * as actionTypes from "../../redux/Clients/ClientsTypes";
import { onLeaveServed } from '../../redux/VisitorsJoined/VisitorsJoinedActions';


export default function DragableModal({ AllActiveVisitors, setAllActiveVisitors }) {
    const dispatch = useDispatch();
    const servedData = useSelector((state) => state.VisitorReducer.data);
    const { Socket } = useContext(SocketContext);
    const userData = useSelector((state) => state.Auth.userData);
    const [visitorIndex, setvisitorIndex] = useState([]);
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

    const toggleModal = (currentVisitor) => {
        if (currentVisitor?.servedBy?.find((i) => i?.id == userData?._id)) {
            LeaveRoom(currentVisitor);
        };
        dispatch(onLeaveServed(currentVisitor, servedData));
        setAllActiveVisitors(visitors => visitors?.map(visitor =>
            visitor.unique_id == currentVisitor?.unique_id ? { ...visitor, isOpen: !visitor.isOpen } : visitor
        ));
        if (AllActiveVisitors.some(visitor => visitor.unique_id === currentVisitor?.unique_id)) {
            setAllActiveVisitors(visitors => visitors.filter(visitor => visitor.unique_id !== currentVisitor?.unique_id));
        };
    };

    const toggleFocus = (id) => {
        setAllActiveVisitors(visitors => visitors.map(visitor =>
            visitor.unique_id == id ? { ...visitor, zIndex: 1, isBlink: false } : { ...visitor, zIndex: 0 }
        ));
    };
    const toggleMinimized = (id) => {
        setAllActiveVisitors(visitors => visitors.map(visitor =>
            visitor.unique_id === id ? { ...visitor, minimized: true, active: false } : visitor
        ));
    };

    const LeaveRoom = (visitor) => {
        let data = {
            agentId: userData?._id,
            visitorId: visitor?.unique_id,
            websiteToken: visitor?.websiteToken,
            role: userData?.role
        }
        // dispatch({
        //     type: actionTypes.CLIENT_TOOGLER_LEAVE_SUCCESS,
        //     payload: true
        // });
        Socket.emit("agentLeaveRoom", data);
    };

    useEffect(() => {
        const handleResize = () => {
            const windowHeight = window.innerHeight;
            const windowWidth = window.innerWidth;
            const modalHeight = 600; // same as initHeight
            const modalWidth = 450;  // same as initWidth

            // Calculate the position for the bottom-right corner
            const top = windowHeight - modalHeight - 20; // 20px margin from the bottom
            const left = windowWidth - modalWidth - 20;  // 20px margin from the right

            setModalPosition({ top, left });
        };

        handleResize(); // Set initial position
        window.addEventListener('resize', handleResize); // Update position on resize

        return () => {
            window.removeEventListener('resize', handleResize); // Cleanup on component unmount
        };
    }, []);


    return (
        <>
            {AllActiveVisitors.map((visitor, index) => {
                const topOffset = modalPosition.top - 10 * index;
                const leftOffset = modalPosition.left - 10 * index;
                return <div key={index}>
                    <ReactModal
                        initWidth={450}
                        initHeight={600}
                        minWidth={450}
                        minHeight={600}
                        disableKeystroke={false}
                        className={visitor?.zIndex == 1 ? `multiModalFocus ${visitor.unique_id} ${visitorIndex?.includes(visitor?.unique_id) ? "increaseSize" : "decreaseSize"}` : `multiModalAway ${visitor.unique_id} ${visitorIndex?.includes(visitor?.unique_id) ? "increaseSize" : "decreaseSize"}`}
                        isMinimised={visitor.minimized}
                        onRequestClose={() => toggleModal(visitor)}
                        isOpen={visitor.isOpen}
                        onFocus={() => toggleFocus(visitor.unique_id)}
                        top={`${topOffset}px`}
                        left={`${leftOffset}px`}
                    >
                        <div style={{ background: visitor?.isBlink ? "rgb(151, 176, 180)" : "rgba(199, 193, 193, 0.2)" }} className='dragHead'>
                            <h4 style={{ marginBottom: 10, marginTop: 0, width: "100%", }}>VISITOR ID #[{visitor?.unique_id + `${visitor?.name !== "" ? `-${visitor?.name}` : ""}`}]</h4>
                            {/* {visitor?.servedBy?.find((i) => i?.id == userData?._id)
                                && (
                                    <Tooltip
                                        placement="bottom"
                                        title={"Leave Room"}
                                    >
                                        <Space onClick={() => LeaveRoom(visitor)} style={{ fontSize: 16, marginRight: 10, zIndex: 999, marginBottom: 10 }}>
                                            <HiMiniArrowLeftStartOnRectangle />
                                        </Space>
                                    </Tooltip>

                                )} */}
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", zIndex: 999, marginBottom: 10 }}>
                                <Tooltip
                                    placement="bottom"
                                    title={"Minimize"}
                                >
                                    <div style={{ paddingRight: 10, cursor: "pointer" }} onClick={() => toggleMinimized(visitor.unique_id)}>
                                        <MinusOutlined />
                                    </div>
                                </Tooltip>
                                <Tooltip
                                    placement="bottom"
                                    title={"Close"}
                                >
                                    <div style={{ cursor: "pointer" }} onClick={() => toggleModal(visitor)}>
                                        <CloseOutlined />
                                    </div>
                                </Tooltip>
                            </div>

                        </div>
                        <div className="body">
                            <DrageableBody visitorIndex={visitorIndex} setvisitorIndex={setvisitorIndex} currentVisitor={visitor} />
                        </div>

                    </ReactModal>
                </div >
            }
            )}
        </>
    )
}
