import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);



export function PieChart({ AnalyticsData }) {
  // let allKeys = AnalyticsData?.TicketsCount?.result?.length > 0 ? AnalyticsData?.TicketsCount?.result?.map((i) => i?.isRead) : ["There is no Data Currently"];
  let allValues = AnalyticsData?.TicketsCount?.result?.length > 0 ? AnalyticsData?.TicketsCount?.result?.map((i) => i?.count) : [0, 0];
  const data = {
    labels: ['Pending', 'Done'],
    datasets: [
      {
        label: '# of Tickets',
        data: [...allValues],
        backgroundColor: [

          'rgba(255, 125, 125, 1)',
          'rgba(151, 219, 174, 1)',
        ],
        borderColor: [
          'rgba(255, 125, 125, 1)',
          'rgba(151, 219, 174, 1)',

        ],
        borderWidth: 1,
      },
    ],
  };
  return <div style={{ display: "flex", justifyContent: "center", alignItems: "end", height: 410 }}><Doughnut data={data} /> </div>;
}