import * as actionTypes from "./DepartmentsTypes.js";
import { Get, Post } from "../../helpers/apicalls/apicalls";

export const getDepartments = (data, token) => {
    return (dispatch) => {
        Post('/agents/user/get-departments', data, token)
            .then((res) => {
                if (res.status) {
                    dispatch({
                        type: actionTypes.DEPARTMENT_DATA_SUCCESS,
                        payload: res?.data
                    });
                } else {
                    dispatch({ type: actionTypes.DEPARTMENT_DATA_FALED });
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.DEPARTMENT_DATA_FALED });
            })
    }
}

export const getActiveDepartments = (data, token) => {
    return (dispatch) => {
        Post('/agents/user/get-active-departments', data, token)
            .then((res) => {
                if (res.status) {
                    dispatch({
                        type: actionTypes.ACTIVE_DEPARTMENT_SUCCESS,
                        payload: res?.data
                    });
                } else {
                    dispatch({ type: actionTypes.ACTIVE_DEPARTMENT_FAILED });
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.ACTIVE_DEPARTMENT_FAILED });
            })
    }
}

export const addDepartment = (data, token, afterSuccess, afterFailed) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_DEPARTMENT_REGISTER_LOADING });
        Post('/agents/user/departments-register', data, token)
            .then((res) => {
                if (res.status) {
                    dispatch({
                        type: actionTypes.DEPARTMENT_REGISTER_SUCCESS,
                        payload: res?.data
                    });
                    afterSuccess(res.message);
                } else {
                    dispatch({ type: actionTypes.DEPARTMENT_REGISTER_FALED });
                    afterFailed(res.message);
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.DEPARTMENT_REGISTER_FALED });
                afterFailed(err.response.message);
            })
    }
}

export const updateDepartment = (data, token, afterSuccess, afterFailed) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_DEPARTMENT_UPDATE_LOADING });
        Post('/agents/user/update', data, token)
            .then((res) => {
                if (res.status) {
                    dispatch({
                        type: actionTypes.DEPARTMENT_UPDATE_SUCCESS,
                        payload: res?.data
                    });
                    afterSuccess(res.message);
                } else {
                    dispatch({ type: actionTypes.DEPARTMENT_UPDATE_FALED });
                    afterFailed(res.message);
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.DEPARTMENT_UPDATE_FALED });
                afterFailed(err.response.message);
            })
    }
}

export const updateStatus = (data, token, afterStatusUpdate) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_DEPARTMENT_STATUS_UPDATE_LOADING });
        Post('/agents/user/status-update', data, token)
            .then((res) => {
                if (res.status) {
                    dispatch({
                        type: actionTypes.DEPARTMENT_STATUS_UPDATE_SUCCESS
                    });
                    afterStatusUpdate(res.message);
                } else {
                    dispatch({ type: actionTypes.DEPARTMENT_STATUS_UPDATE_FAILED })
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.DEPARTMENT_STATUS_UPDATE_FAILED })
            })
    }
}

export const getDepartmentUsers = (data, token) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_DEPARTMENT_USERS_LOADING });
        Post('/agents/user/get-users', data, token)
            .then((res) => {
                if (res.status) {
                    dispatch({
                        type: actionTypes.DEPARTMENT_USERS_SUCCESS,
                        payload: res?.data
                    });
                } else {
                    dispatch({ type: actionTypes.DEPARTMENT_USERS_FAILED })
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.DEPARTMENT_USERS_FAILED })
            })
    }
}