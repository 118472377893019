import React, { useState, useEffect } from "react";
import { Input, Space, Table, theme, Button, Dropdown, Divider, Popconfirm, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteTriggerById, getAllTriggers } from "../../redux/Triggers/TriggersActions";
import { SafetyDivider } from "@mui/icons-material";
import { DeleteFilled, EditFilled } from "@ant-design/icons";

const { Search } = Input;

const Triggers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token: { colorBgContainer } } = theme.useToken();
  const usertoken = useSelector((state) => state.Auth.token);
  const triggers = useSelector((state) => state.Triggers.allTriggers);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    getTriggers();
  }, []);

  const [data, setData] = useState([]);

  useEffect(() => {
    const triggerData = [];
    triggers.map((trigger, index) => {
      triggerData.push({
        key: index + 1,
        id: trigger._id,
        name: trigger.triggerName,
        description: trigger.triggerMessage,
        status: trigger.status ? 'Active' : 'Deactive',
        action: trigger._id
      })
    });
    setData(triggerData);
  }, [triggers]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: {
        compare: (a, b) => a.english - b.english,
        multiple: 1,
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      sorter: {
        compare: (a, b) => a.english - b.english,
        multiple: 1,
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text) => (
        <span>
          <Link to={'/triggers/edit/' + text}><EditFilled /></Link>
          <Divider type="vertical" />
          <Popconfirm placement="bottom" title={"Are you sure delete this trigger?"} onConfirm={() => deleteTrigger(text)} okText="Yes" cancelText="No">
            <DeleteFilled />
          </Popconfirm>
        </span>
      ),
    },
  ];

  const getTriggers = () => {
    dispatch(getAllTriggers(usertoken));
  }

  // rowSelection object indicates the need for row selection
  // const [action, setaction] = useState(false);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRows.length > 0) {
        setToggle(true);
      } else {
        setToggle(false);
      }
    },
  };

  function handleClick() {
    navigate("/triggers/addtriggers");
  }

  const onSearch = (value) => {

  };

  const deleteTrigger = (triggerId) => {
    dispatch(deleteTriggerById(triggerId, usertoken, onSuccess));
  }

  const onSuccess = (msg) => {
    if (msg) {
      message.success(`${msg}`);
    }
    else {
      message.success("Submit success!");
    }
  };

  return (
    <>
      <div className="topBtns">
        <div className="shortcut">
          <Space direction="vertical">
            <Search placeholder="input search text" onSearch={onSearch} style={{ width: 200 }} />
          </Space>
          <Button className="addShort" type="primary" loading={loading} onClick={handleClick}>Add Triggers</Button>
          {/* <button onClick={() => setToggle(!toggle)}>Toggle State</button> */}
          {toggle && <Button type="primary">Delete Selected</Button>}
        </div>
      </div>
      <div>
        <Table
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data}
        />
      </div>
    </>
  );
};

export default Triggers;
