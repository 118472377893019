import React, { useState, useEffect } from "react";
import { Col, Row, Breadcrumb } from "antd";
import {
  Button,
  Cascader,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  message,
  TreeSelect
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Add_Shortcut, Edit_Shortcut } from "../../redux/Shortcuts/ShortcutsActions";
import { useLocation, useNavigate } from "react-router-dom";

const AddShortcut = () => {
  const userData = useSelector((state) => state.Auth.userData);
  const BrandAgents = useSelector((state) => state.Users.BrandAgents);
  const usertoken = useSelector((state) => state.Auth.token);
  const navigate = useNavigate();
  const ShortcutsLoading = useSelector(
    (state) => state.Shortcuts.shortcutLoading
  );
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [form] = Form.useForm();



  const [size, setSize] = useState([]);

  const handleChange = (value) => {
    setSize(value);

  };

  const [data, setData] = useState([]);

  const handleClicks = () => {
    setData([...data, { option: "" }]);
  };
  const handleChanges = (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...data];
    onchangeVal[i][name] = value;
    setData(onchangeVal);
  };
  const handleDelete = (i) => {
    const deleteVal = [...data];
    deleteVal.splice(i, 1);
    setData(deleteVal);
  };

  const onFinish = (e) => {
    let filterBrands = userData?.assignBrands?.map((i) => i?.token);
    let newdata = {
      user_id: userData?._id,
      available_for: e?.available_for,
      brandToken: filterBrands,
      shortcut: e?.shortcut,
      message: e?.message,
      options: data,
      tags: size,
      _id: state !== null ? state?._id : null
    };
    if (state === null) {
      dispatch(Add_Shortcut(newdata, usertoken, onSuccess));
    }
    else {
      dispatch(Edit_Shortcut(newdata, usertoken, onSuccess));
    }

  };
  const onSuccess = (msg) => {
    if (msg) {
      message.success(`${msg}`);
    }
    else {
      message.success("Submit success!");
    }
    navigate(-1);

  };

  useEffect(() => {
    if (state !== null) {
      setSize(state?.tags);
      setData(state?.options);
    }
  }, [state]);
  const initialValues = {
    message: state !== null ? state?.message : "",
    shortcut: state !== null ? state?.shortcut : "",
    size: "",
    available_for: state !== null ? state?.available_for?.map((i) => i?._id) : undefined
  };
  return (
    <>
      <Breadcrumb
        className="breadCrumb"
        items={[
          {
            title: "Shortcuts"
          },
          {
            title: state !== null ? "Edit Shortcut" : "Add Shortcut"
          }
        ]}
      />
      <Row className="addShortcut">
        <Col xs={24} sm={24} md={24} lg={14} xl={14}>
          <div>
            <Form
              initialValues={initialValues}
              form={form}
              wrapperCol={{
                span: 14
              }}
              layout="horizontal"

              onFinish={onFinish}
              autoComplete="off"
              style={{
                maxWidth: 600
              }}>
              <Form.Item
                name="shortcut"
                rules={[{ required: true }]}
                label="Shortcut">
                <Input />
              </Form.Item>
              <Form.Item
                name="available_for"
                rules={[{ required: true }]}
                label="Available for">
                <Select mode="tags">
                  <Select.Option value="All-Agents">All-Agents</Select.Option>
                  {BrandAgents?.map((i, index) => (
                    <Select.Option key={index} value={i?._id}>{i?.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="message"
                rules={[{ required: true }]}
                label="Message">
                <TextArea rows={4} />
              </Form.Item>
              <Form.Item name="options" label="Options">
                <div className="optionInput">
                  {data.map((val, i) => (
                    <div key={i} className="addInput">
                      <Input
                        name="option"
                        value={val.option}
                        onChange={(e) => handleChanges(e, i)}
                      />
                      {i === 0 ? (
                        ""
                      ) : (
                        <DeleteOutlined
                          className="deleteBtn"
                          onClick={() => handleDelete(i)}
                        />
                      )}
                    </div>
                  ))}
                  <PlusOutlined className="addBtn" onClick={handleClicks} />
                </div>
              </Form.Item>
              <Form.Item label="Add Tags">
                <Select
                  value={size}
                  mode="tags"
                  size={size}
                  placeholder="Add chat tags"
                  onChange={handleChange}
                  style={{
                    width: "100%"
                  }}
                />
                <p>
                  Automatically add the above tags to a chat when you use this
                  shortcut
                </p>
              </Form.Item>
              <Button style={{ marginRight: 10 }} onClick={() => navigate('/shortcuts')}>back</Button>
              <Button
                disabled={ShortcutsLoading}
                type="primary"
                htmlType="submit">
                Save
              </Button>
            </Form>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <div className="quickTips">
            <h6>Quick tips</h6>
            {/* <video width="250" controls>
              <source src="" />
            </video> */}
            <h3>Create Shortcut</h3>
            <p>
              Set up a shortcut by entering a shortcut name (example, “hi”) and
              the message (example, “hello, how can I help you today?”). You can
              also add multiple choice options to let your visitors choose a
              reply from a list.
            </p>
            <h3>Create Shortcut</h3>
            <p>
              Set up a shortcut by entering a shortcut name (example, “hi”) and
              the message (example, “hello, how can I help you today?”). You can
              also add multiple choice options to let your visitors choose a
              reply from a list.
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AddShortcut;
