

export const SHORTCUT_IS_LOADING = "SHORTCUT_IS_LOADING";
export const SHORTCUT_DATA_SUCCESS = "SHORTCUT_DATA_SUCCESS";
export const SHORTCUT_DATA_FALED = "SHORTCUT_DATA_FALED";

export const IS_LOADING_GET_SHORTCUT = "IS_LOADING_GET_SHORTCUT";
export const GET_SHORTCUT_DATA_SUCCESS = "GET_SHORTCUT_DATA_SUCCESS";
export const GET_SHORTCUT_DATA_FALED = "GET_SHORTCUT_DATA_FALED";


export const IS_LOADING_EDIT_SHORTCUT = "IS_LOADING_EDIT_SHORTCUT";
export const EDIT_SHORTCUT_DATA_SUCCESS = "EDIT_SHORTCUT_DATA_SUCCESS";
export const EDIT_SHORTCUT_DATA_FALED = "EDIT_SHORTCUT_DATA_FALED";

export const IS_LOADING_DELETE_SHORTCUT = "IS_LOADING_DELETE_SHORTCUT";
export const DELETE_SHORTCUT_DATA_SUCCESS = "DELETE_SHORTCUT_DATA_SUCCESS";
export const DELETE_SHORTCUT_DATA_FALED = "DELETE_SHORTCUT_DATA_FALED";

